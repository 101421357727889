import { IncomingActions } from 'src/components/common/persistentIframeRenderer/OpsDashboardIFrame/utils';

export const setShowAddPickup = (data: boolean) => {
  return {
    data,
    type: IncomingActions.ADD_PICKUP,
  };
};

export const setUuid = (data: string) => {
  return {
    data,
    type: 'SET_UUID',
  };
};
