import * as React from 'react';
import withStyles from 'react-jss';
import { CssType,
  ThemeType} from '../../../theming/jssTypes';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { Input, Select, DatePicker, Menu, Dropdown, Button, message } from 'antd';
import {
  applyFilterToAllBuckets,
  applyGlobalFilters,
  setActiveBucket,
  applyGlobalSort,
} from '../../../actions/genericConsignmentActions';
import {
  searchHubData,
} from '../../../api/dashboard';
import HubSearch from '../../common/HubSearch';
import MultiSelectFilter from '../../common/MultiselectFilter';
import * as moment from 'moment';
import {
  getTextInputStyleForSelectWithInput,
  getSelectStyleForSelectWithInput,
} from '../../../theming/cssSnippets/commonSnippets';
import { fetchOne, getAllConsignmentsData, getAllConsignmentsDataForSearch } from '../../../api/genericConsignmentView';
import { withRouter } from 'react-router';
import { getDetailsRoute, getDomesticTrackDetailsRoute, getPickupRequestDetailsRoute } from '../../../routing/utils';
import { fetchLTLDetails } from '../../../api/ltlTracking';
import { getViewControl } from '../../../utils/genericConfig/utils';
import  CustomerReferenceListModal  from './CustomerReferenceListModal';
import classNames from 'classnames';
import { fetchPickupDetails } from '../../../../src/api/trips';
import {getSupplyRequestDetails} from '@api/supplyRequest';
import {SupplyRequestDetailsColumns} from '@components/pages/supplyRequest/utils';
import SupplyRequestDetailsDrawer from '@components/pages/supplyRequest/SupplyRequestDetailsDrawer';
import { HUB_TYPE_MAPPING } from 'src/utils/hub-utils';
import { trackGaEvents } from 'src/utils/googleAnalyticsHelper';
import { DATE_SEARCH, gaType } from 'src/utils/gaConstants';
import { withTranslation } from 'react-i18next';
const Search = Input.Search;
const Option = Select.Option;

const bucketNameForSearch = {
  ondemand_all_orders: 'ondemand_all_orders_search',
};

const getSearchAPIObject = (searchType, searchValue, bucket) => {
  const searchVal = searchValue.length > 0 ? [searchValue] :[];
  const bucketNameToSend =  searchValue && bucketNameForSearch.hasOwnProperty(bucket) ? bucketNameForSearch[bucket] : bucket;
  switch (searchType){
    case 'referenceNumber':
      return {
        referenceNumberList:searchVal,
        senderPhoneNumberList:[],
        destinationPhoneNumberList:[],
        customerReferenceNumberListInclusive:[],
        ...(bucketNameToSend && { bucket: bucketNameToSend }),
      };
    case 'senderPhone':
      return {
        referenceNumberList:[],
        senderPhoneNumberList:searchVal,
        destinationPhoneNumberList:[],
        customerReferenceNumberListInclusive:[],
        ...(bucketNameToSend && { bucket: bucketNameToSend }),
      };
    case 'destinationPhone':
      return {
        referenceNumberList:[],
        senderPhoneNumberList:[],
        destinationPhoneNumberList:searchVal,
        customerReferenceNumberListInclusive:[],
        ...(bucketNameToSend && { bucket: bucketNameToSend }),
      };
    case 'customerReferenceNumber':
      return {
        referenceNumberList:[],
        senderPhoneNumberList:[],
        destinationPhoneNumberList:[],
        bucket:'crm_default',
        ...(bucketNameToSend && { bucket: bucketNameToSend }),
        customerReferenceNumberListInclusive:searchVal,
      };
    case 'phone':
      return {
        referenceNumberList:[],
        senderPhoneNumberList:[],
        destinationPhoneNumberList:[],
        bucket:'crm_default',
        ...(bucketNameToSend && { bucket: bucketNameToSend }),
        phoneNumberList:searchVal,
      };
    case 'orderNumber':
      return {
          referenceNumberList:[],
          senderPhoneNumberList:[],
          destinationPhoneNumberList:[],
          customerReferenceNumberListInclusive:[],
          ondemandOrderNumberList: searchVal,
          ...(bucketNameToSend && { bucket: bucketNameToSend }),
      };
  }
  return {
    referenceNumberList:[],
    senderPhoneNumberList:[],
    destinationPhoneNumberList:[],
    customerReferenceNumberListInclusive:[],
  };
};

const styles = (theme: ThemeType): CssType => ({
  selectStyle: getSelectStyleForSelectWithInput({ height: '28px', theme }),
  hubTypeStyle: {
    '& .ant-select-selection': {
      backgroundColor: `${theme.colors.surfaceBg}1A !important`,
      color: theme.colors.textOnDarkBg,
      width: 120,
      display: 'flex',
      alignItems: 'center',
      height: '28px',
    },
  },
  dateFilterSelectStyle: {
    ...getSelectStyleForSelectWithInput({ height: '28px', theme }),
    backgroundColor: `${theme.colors.surfaceBg}1A !important`,
    color: theme.colors.textOnDarkBg,
    maxWidth: '100px'
  },
  multiSelectFilter: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    '& button': {
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      fontSize: theme.sizes.bodyText,
      fontFamily: theme.fonts.fontFamily,
      background: `${theme.colors.surfaceBg}1A`,
      color: theme.colors.textOnDarkBg,
      border: 'none',
      height: '28px !important',
    },
  },
  searchStyle: {
    ...(getTextInputStyleForSelectWithInput()),
    minWidth: '160px',
    maxWidth: '200px',
    '& .ant-input-affix-wrapper': {
      background: `${theme.colors.globalHeader} !important`,
      color: `${theme.colors.textOnDarkBg} !important`,
      height: '28px',
      borderTopLeftRadius: '0 !important',
      borderBottomLeftRadius: '0 !important',
    },
    '& .ant-input-search-button': {
      background: `${theme.colors.searchBarBackground} !important`,
      color: `${theme.colors.textOnDarkBg} !important`,
      height: '28px',
    },
    '& .ant-input': {
      background: theme.colors.globalHeader,
      color: theme.colors.textOnDarkBg,
    },
    background: theme.colors.globalHeader,
    color: theme.colors.textOnDarkBg,
  },
  datePicker: {
    fontSize: '12px',
    background: `${theme.colors.globalHeader} !important`,
    color: `${theme.colors.textOnDarkBg} !important`,
    height: 28,
    width: 240,
    border: 'none',
    backgroundColor: `${theme.colors.surfaceBg}0F !important`,
    '& .ant-calendar-range-picker-separator': {
      background: `${theme.colors.globalHeader} !important`,
      color: `${theme.colors.textOnDarkBg} !important`,
      verticalAlign: 'baseline',
      fontSize: '12px',
    },
    '& .ant-calendar-range-picker-input': {
      background: `${theme.colors.globalHeader} !important`,
      color: `${theme.colors.textOnDarkBg} !important`,
      width: '40%',
      fontSize: '12px',
    },
    '& .ant-picker-input > input': {
      color: `${theme.colors.textOnDarkBg} !important`,
      fontSize: '12px',
    },
    '& .ant-picker-separator': {
      color: `${theme.colors.textOnDarkBg} !important`,
      fontSize: '12px',
    },
    '& .ant-picker-suffix': {
      color: `${theme.colors.textOnDarkBg} !important`,
      fontSize: '12px',
    },
    borderTopLeftRadius: '0 !important',
    borderBottomLeftRadius: '0 !important',
  },
  noWidthInput: {
    background: theme.colors.globalHeader,
    color: theme.colors.textOnDarkBg,
    width: 'auto',
    margin: '0 !important',
    '& .ant-input': {
      width: '0px',
      padding: '0',
      borderRight: 'none',
      height: 28,
      color: `${theme.colors.surfaceBg}1A`,
      backgroundColor: `${theme.colors.surfaceBg}1A`,
      border: 'none',
    },
    '& .ant-input-group-addon': {
      backgroundColor: `${theme.colors.surfaceBg}1A`,
      border: 'none',
      color: theme.colors.textOnDarkBg,
      fontSize: theme.sizes.bodyText,
    }
  },
  searchDropdown: {
    padding: '0px 2px',
    background: theme.colors.globalHeader,
    color: theme.colors.textOnDarkBg,
    borderRadius: '4px 0 0 4px',
  },
  hubSearch: {
    background: theme.colors.globalHeader,
    color: theme.colors.textOnDarkBg,
    height: 28,
    fontSize: theme.sizes.bodyText,
    fontFamily: theme.fonts.fontFamily,
    '& .ant-select-single .ant-select-selector': {
      border: 'none',
      background: theme.colors.globalHeader,
      color: theme.colors.textOnDarkBg,
      height: 28,
      fontSize: theme.sizes.bodyText,
      fontFamily: theme.fonts.fontFamily,
      borderRadius: '0',
    },
  },
  compactSelect: {
    background: theme.colors.globalHeader,
    color: theme.colors.textOnDarkBg,
    minWidth: '100px',
    maxWidth: '180px',
  },
  compactDatePicker: {
    background: theme.colors.globalHeader,
    color: theme.colors.textOnDarkBg,
    width: '225px',
  },
  dropdownStyles: {
    backgroundColor: theme.colors.globalHeader,
    fontSize: theme.sizes.bodyText,
    fontFamily: theme.fonts.fontFamily,
    fontWeight: 'normal',
    '& .ant-select-item-option': {
      backgroundColor: theme.colors.globalHeader,
      color: theme.colors.textOnDarkBg,
    },
    '& .ant-select-item-option:hover': {
      backgroundColor: `${theme.colors.hyperlink}4D !important`,
    },
    '& .ant-select-item-option-content': {
      fontWeight: 'normal',
      fontSize: theme.sizes.bodyText,
      fontFamily: theme.fonts.fontFamily,
    }
  },
  datePickerDropDown: {
    backgroundColor: theme.colors.globalHeader,
    color: theme.colors.textOnDarkBg,
    fontSize: theme.sizes.bodyText,
    fontFamily: theme.fonts.fontFamily,
    '& .ant-picker-ranges .ant-picker-preset > .ant-tag-blue': {
      background: `${theme.colors.primaryColor} !important`,
      color: `${theme.colors.textOnDarkBg} !important`,
    },
    '& .ant-picker-panel-container': {
      background: `${theme.colors.globalHeader} !important`,
      color: `${theme.colors.textOnDarkBg} !important`,
    },
    '& .ant-picker-header, & .ant-picker-date-panel .ant-picker-content th': {
      color: `${theme.colors.textOnDarkBg} !important`,
    },
    '& .ant-picker-header > button': {
      color: `${theme.colors.textOnDarkBg} !important`,
    },
    '& .ant-picker-cell .ant-picker-cell-inner': {
      color: `${theme.colors.textOnDarkBg} !important`,
    },
    '& .ant-picker-cell-in-view.ant-picker-cell-in-range::before': {
      background: `${theme.colors.primaryColor} !important`,
    },
    '& .ant-picker-cell-inner:hover': {
      backgroundColor: `${theme.colors.HoverOnWhiteBg} !important`,
      color: `${theme.colors.textOnLightBg} !important`,
    },
  }
});


export function getFilters(key, viewType, isCompact?: Boolean) {
  const viewConfig = getViewControl(viewType);
  switch (key) {
    case 'multi_hub_search' :
      return <MultiHubSearchStyled viewType={viewType}  />;
    case 'single_hub_search' :
      return <SingleHubSearchStyled viewType={viewType} isCompact={isCompact}/>;
    case 'date_filter':
      return <DateFilterStyled viewType={viewType} isCompact={isCompact}/>;
    case 'hub_type':
      return <HubTypeFilterStyled viewType={viewType}/>;
    case 'logistics_search':
      return <LogisticsSearchStyled viewType={viewType} />;
    case 'domestic_search':
      return <DomesticSearchStyled viewType={viewType} />;
    case 'search_redirect':
      return <SearchWithRouter viewType = {viewType} />;
    case 'logistics_redirect_search':
      return <LogisticsSearchRedirectWithRouter viewType = {viewType}/>;
    case 'domestic_redirect_search':
      return <DomesticSearchRedirectWithRouter/>;
    case 'hub_search_with_type':
      return <SingleHubSearchWithTypeStyled viewType = {viewType}/>;
    default: return null;
  }
}

export function getNavbarFilters(list, viewType, isCompact?: Boolean) {
  return list && list.length ?
    list.map(key => getFilters(key, viewType, isCompact)) : [];
}
class DomesticSearch extends React.Component<any, any> {

  state = {
    searchType:'awbNumber',
  };
  componentDidMount() {
    this.setState({
      searchType: this.props.searchType,
    });
  }
  handleTopSearchChange = (e) => {
    if (e.target.value === '') {
      this.handleTopSearch(e.target.value);
    }
  };
  generateOptionsFromList = (optionsList: any) => {
    const { t } = this.props;
    const x:any = [];
    if (optionsList) {
      optionsList.forEach((ele:any) => {
        x.push(<Option value={ele.key}>{t(ele.key + '_domestic_search')}</Option>);
      });
    }
    return x;
  };
  handleTopSearch = (value) => {
    this.setState({ selectedRowKeys: [] });
    this.props.applyFilters({
      searchValue: value,
      searchType: this.state.searchType,
    });
  };
  render() {
    const { classes, viewType, searchType, searchValue, t } = this.props;
    return (
      <div style={{ display: 'flex' }}>
      <Select
        onChange={ value => this.setState({  searchType: value }) }
        className={classes.selectStyle}
        dropdownClassName={classes.dropdownStyles}
        defaultValue={searchType}>
        {
          this.generateOptionsFromList(getViewControl(viewType).searchFilterOptions)
        }
      </Select>
      <Search
        allowClear
        defaultValue={searchValue}
        onChange={this.handleTopSearchChange}
        onSearch={this.handleTopSearch}
        className={classes.searchStyle}
        placeholder={t("Search")}
      />
    </div>
    );
  }
}

const LogisticsSearchRedirect:React.FC = (props:any) => {
  const [searchType, setSearchType] = React.useState('referenceNumber');
  const { classes, viewType, t } = props;
  const handleSearch = async(value) => {
    const response = await fetchOne(value);
    if (response.isSuccess && response.data) {
      props.history.push({
        pathname: getDetailsRoute(value),
        state: { viewType, data:response.data },
      });
    }else {
      message.error(t('Please enter a valid number'));
    }
  };
  return(
    <div style={{ display: 'flex' }}>
      <Select
        dropdownClassName={classes.dropdownStyles}
        onChange={setSearchType as any}
        className={classes.selectStyle}
        value={searchType}>
        <Option value="referenceNumber">{t('Reference #')}</Option>
      </Select>
      <Search
        allowClear
        onSearch={handleSearch}
        className={classes.searchStyle}
        placeholder={t("Search Reference Number")}
      />
    </div>
  );
};
const LogisticsSearchRedirectStyled: any =
withStyles(styles, { injectTheme: true })(LogisticsSearchRedirect);
const LogisticsSearchRedirectWithRouter = withRouter(
  LogisticsSearchRedirectStyled) as  React.ComponentType<any>;

const DomesticSearchRedirect:React.FC = (props:any) => {
  const [searchType, setSearchType] = React.useState('courier_partner_reference_number');
  const { classes, t } = props;
  const handleSearch = async(value) => {
    const response = await fetchLTLDetails(value);
    if (response.isSuccess && response.data) {
      props.history.push(getDomesticTrackDetailsRoute(value), { data:response.data });
    }else {
      message.error(t('Please enter a valid number'));
    }
  };
  return(
    <div style={{ display: 'flex' }}>
      <Select
        dropdownClassName={classes.dropdownStyles}
        onChange={setSearchType as any}
        className={classes.selectStyle}
        value={searchType}>
        <Option value="courier_partner_reference_number">{t('AWB#')}</Option>
      </Select>
      <Search
        allowClear
        onSearch={handleSearch}
        className={classes.searchStyle}
        placeholder={t("Search")}
      />
    </div>
  );
};
const DomesticSearchRedirectStyled: any =
withStyles(styles, { injectTheme: true })(DomesticSearchRedirect);
const DomesticSearchRedirectWithRouter = withRouter(
  DomesticSearchRedirectStyled) as  React.ComponentType<any>;
class SingleHubSearch extends React.Component<any, any> {

  onHubChange = (hub) => {
    this.setState({ selectedRowKeys: [] });
    this.props.applyFilters({
      hubId: hub.id,
    });
  };

  constructor(props) {
    super(props);
    this.state = {
      viewConfig: getViewControl(this.props.viewType),
    };
  }

  render() {
    const { classes, isCompact, t } = this.props;
    const { viewConfig } = this.state;
    return (
        <div style={{ display: 'flex' }} className={classes.hubSearch}>
          <Input addonBefore={viewConfig.singleHubSearchText ?
                              viewConfig.singleHubSearchText : t('current_hub') }
                              className={classes.noWidthInput} />
          <HubSearch
            value={{
              key: this.props.currHub.id,
              label: `${this.props.currHub.name}, ${this.props.currHub.code}`,
            }}
            onHubChange={hub => this.onHubChange(hub)}
            searchHubData = {viewConfig.searchHubData}
            searchText = {viewConfig.searchText}
            placeholder = {t(viewConfig.hubSearchPlaceholder)}
            searchStyle={isCompact ? classes.compactSelect : undefined}
          />
      </div>);
  }
}

class MultiHubSearch extends React.Component<any, any> {
  state = {
    searchedHubs : [],
  };
  minimumHubSearchLength = this.props.minSearchLength || 2;
  handleHubSearch = async (queryString: string) => {
    if (!queryString.trim()) {
      this.setState({ searchedhubs: [] });
      return [];
    }
    if (queryString.length >= this.minimumHubSearchLength) {
      const response = await searchHubData(queryString);
      let searchedHubs = response.data;
      searchedHubs = searchedHubs ? searchedHubs.map((hub: any) => ({
        key: hub.id, label: `${hub.name}, ${hub.code}`,
      })) : [],
          this.setState({ searchedHubs });
      return searchedHubs;
    }
    return [];
  };
  render() {
    const { classes,t } = this.props;
    const { searchedHubs } = this.state;
    return (
        <MultiSelectFilter
          className={classes.multiSelectFilter}
          onDropdownClose={() => { }}
          onBackendSearch={this.handleHubSearch}
          onFilterChange={(filterVals) => {
            const filteredObj = {};
            filteredObj['hubId'] = filterVals;
            this.setState({ selectedRowKeys: [] });
            this.props.applyFilters({
              ...filteredObj,
            });
          }}
          placeholder={t('select_hub')} title={t('Hub')}
          options={searchedHubs} />
    );
  }
}

class HubTypeFilter extends React.Component<any, any> {

  handleHubTypeChange = (value) => {
    this.setState({ selectedRowKeys: [] });
    this.props.applyFilters({
      hubType: value,
    });
  };
  render() {
    const { classes, hubType, t } = this.props;
    return (
      <div style={{ display: 'flex' }}>
        <Select
          dropdownClassName={classes.dropdownStyles}
          onChange={this.handleHubTypeChange}
          className={classes.hubTypeStyle}
          value={hubType}>
          <Option value="self">{t('Self')}</Option>
          <Option value="descendants">{t('Descendants')}</Option>
        </Select>
      </div>);
  }
}
class LogisticsSearch extends React.PureComponent<any, any> {
  state = {
    searchType:'referenceNumber',
  };
  handleTopSearch = (value) => {
    const searchVal = value
    .split(',')
    .filter(val => val.trim() !== '')
    .map(val => val.trim());

    this.setState({ selectedRowKeys: [] });
    this.props.applyFilters({
      searchValue: searchVal,
      searchType: this.state.searchType,
    });
  };
  generateOptionsFromList = (optionsList: any) => {
    const x:any = [];
    if (optionsList) {
      optionsList.forEach((ele:any) => {
        x.push(<Option value = {ele.key}>{ele.val}</Option>);
      });
    }
    return x;
  };
  handleTopSearchChange = (e) => {
    if (e.target.value === '') {
      this.handleTopSearch(e.target.value);
    }
  };
  render() {
    const { classes, viewType, allowManifestAndCivilSearch, t } = this.props;
    const getSearchOptions = () => {
      const { searchFilterOptions,  extraSearchOptions } = getViewControl(viewType);
      return allowManifestAndCivilSearch && extraSearchOptions
        ? [...searchFilterOptions, ...extraSearchOptions]
        : searchFilterOptions;
    };
    const options = getSearchOptions();
    options.forEach((i) => {
      i.val = t(i.val);
    });
    return (
        <div style={{ display: 'flex', alignItems: 'center' }}>
          <Select
            dropdownClassName={classes.dropdownStyles}
            onChange={ value => this.setState({ searchType: value })}
            className={classes.selectStyle}
            defaultValue={getViewControl(viewType).searchFilterOptions[0].key}>
            { this.generateOptionsFromList(options) }
          </Select>
          <Search
            allowClear
            onSearch={this.handleTopSearch}
            onChange={this.handleTopSearchChange}
            className={classes.searchStyle}
            placeholder={t('filter_search')}
          />
        </div>
    );
  }
}

class SearchRedirect extends React.PureComponent<any, any> {
  state = {
    searchType:getViewControl(this.props.viewType).searchFilterOptions[0].key,
    isCustomerReferenceModalVisible : false,
    data : [],
    isSupplyRequestDetailsVisible: false,
    supplyRequestDetailsData: {},
  };

  handleSupplyRequestSearch = async(value) => {
    const res = await getSupplyRequestDetails({supplyRequestId:value});
    if(res.isSuccess){
      if(res?.data?.error){
        message.error(res.data.error.message);
      } else{
        this.setState({
          isSupplyRequestDetailsVisible: true,
          supplyRequestDetailsData: res.data,
        });
      }
    }
    else{
      message.error(res.errorMessage);
    }
  };

  handleConsignmentSearch = async(value) => {
    const { viewType, t, bucket } = this.props;
    if(!value) {
        message.error(t('Please enter a value to be searched'));
        return;
    }
    const response:any  = await
        getAllConsignmentsDataForSearch(getSearchAPIObject(this.state.searchType, value, bucket));

    const cnIsPickupType = viewType === 'consolidated_planning'? await fetchPickupDetails(value) : undefined;
    if (response?.isSuccess && response?.data?.page_data?.length > 0) {
      if(this.state.searchType!=='referenceNumber')
      {
        this.setState({
          isCustomerReferenceModalVisible : true,
          data : response.data.page_data,
        });
      }
      else
      {
        this.props.history.push({
        pathname:  getViewControl(viewType).detailsRoute(encodeURIComponent(value)),
        state: {viewType},
        });
      }
    }
    else if(cnIsPickupType?.isSuccess && cnIsPickupType?.data?.reference_number > 0)
    {
      this.props.history.push({
        pathname: getPickupRequestDetailsRoute(value),
        state: { viewType },
      });
    }
    else {
      message.error(t('Please enter a valid number'));
    }
  };

  handleSearch = async(value) => {
    const { viewType } = this.props;
    if(viewType === 'supply_request'){
      this.handleSupplyRequestSearch(value);
    }
    else{
      this.handleConsignmentSearch(value);
    }
  };

  //refernce number, customer reference number div
  generateOptionsFromList = (optionsList: any) => {
    const x:any = [];
    if (optionsList) {
      optionsList.forEach((ele:any) => {
        x.push(<Option value = {ele.key}>{ele.val}</Option>);
      });
    }
    return x;
  };
  getPlaceHolderValue = () => {
    const { viewType, t } =  this.props;
    const { searchType } = this.state;
    const x = getViewControl(viewType).searchFilterOptions.find(x =>
        x.key === searchType,
      );
    if (x) {
      return `${t('Search')} ${t(x.val)}`;
    }
    return t('search');
  };
  render() {
    const {
      classes, date, viewType, showSearchOndemand, t,
      customerReferenceListModalColumns,
    } = this.props;
    const { isCustomerReferenceModalVisible,data, isSupplyRequestDetailsVisible,
      supplyRequestDetailsData } = this.state;
    if(getViewControl(viewType).searchFilterOptions &&
     getViewControl(viewType).searchFilterOptions.length > 0 ) {
      getViewControl(viewType).searchFilterOptions.forEach(i => {
        i.val = t(i.val);
      });
    }
    if(viewType === 'ondemand' && !showSearchOndemand )
    {
      return null;
    }
    return (
        <div style={{ display: 'flex' }}>
          {
          getViewControl(viewType).searchFilterOptions.length > 1 && <Select
            onChange={ value => this.setState({ searchType: value })}
            dropdownClassName={classes.dropdownStyles}
            className={classes.selectStyle}
            defaultValue={getViewControl(viewType).searchFilterOptions[0].key}>
            {
              this.generateOptionsFromList(getViewControl(viewType).searchFilterOptions)
            }
          </Select>
        }
          <Search
            allowClear
            onSearch={this.handleSearch}
            className={getViewControl(viewType).searchFilterOptions.length > 1
              && classes.searchStyle}
            placeholder={this.getPlaceHolderValue()}
          />
          {
            isCustomerReferenceModalVisible &&
            <CustomerReferenceListModal
              isVisible={isCustomerReferenceModalVisible}
              data={data}
              viewType={viewType}
              columns={customerReferenceListModalColumns}
              onModalClose={() =>
                this.setState({isCustomerReferenceModalVisible: false})}
            />
          }
          {
            isSupplyRequestDetailsVisible &&
            <SupplyRequestDetailsDrawer
              onClose={() => this.setState({isSupplyRequestDetailsVisible: false})}
              isVisible={isSupplyRequestDetailsVisible}
              data={supplyRequestDetailsData}
              columns={SupplyRequestDetailsColumns}
            />
          }
        </div>
    );
  }
}
class DateFilter extends React.Component<any, any> {

  state:any = {
    scheduledDate: moment(),
  };
  handleDateFilterTypeChange = (value) => {
    const { viewType, maxDateRangeInCnFilter } = this.props;
    const { scheduledDate } = this.state;
    this.setState({ selectedRowKeys: [],
      dateFilterType: value,
    });
    getViewControl(viewType).applyDateFilterSort && this.props.applyGlobalSort({
      sortBy: value,
      descendingOrder : true,
    });
    if (value === 'scheduled_at') {
      this.props.applyFilters({
        startDate: scheduledDate.format('YYYY-MM-DD'),
        endDate: scheduledDate.format('YYYY-MM-DD'),
        dateFilterType: value,
        maxDateRangeAllowed: maxDateRangeInCnFilter,
      });
    } else {
      this.props.applyFilters({
        dateFilterType: value,
        maxDateRangeAllowed: maxDateRangeInCnFilter,
      });
    }
  };

  //created-at, lastevent and scheduled at div
  generateOptionsFromList = (optionsList: any) => {
    const { t } = this.props;
    const x:any = [];
    if (optionsList) {
      optionsList.forEach((ele:any) => {
        x.push(<Option key={ele.key} value={ele.key}>{t(ele.key)}</Option>);
      });
    }
    return x;
  };
  handleDateChange = (dates) => {
    const date: any = { start: moment(), end: moment() };
    const {maxDateRangeInCnFilter, t} = this.props;
    if (dates?.length === 2) {
      date.start = dates[0];
      date.end = dates[1];
    }
    if (this.state.firstDate) {
      if (this.state.firstDate.isSame(date.start)) {
        const x = date.start.clone();
        x.add(maxDateRangeInCnFilter, 'd');
        if (date.end.isAfter(x)) {
          date.end = x;
          message.info(`${t('Date range cannot be more than')} ${maxDateRangeInCnFilter} ${t('days')}. ${t('Showing results for')} ${maxDateRangeInCnFilter} ${t('days')}.`);
        }
      }
      if (this.state.firstDate.isSame(date.end)) {
        const x = date.end.clone();
        x.subtract(maxDateRangeInCnFilter, 'd');
        if (date.start.isBefore(x)) {
          date.start = x;
          message.info(`${t('Date range cannot be more than')} ${maxDateRangeInCnFilter} ${t('days')}. ${t('Showing results for')} ${maxDateRangeInCnFilter} ${t('days')}.`);
        }
      }
    }
    this.setState({ date, selectedRowKeys: [] });
    this.props.applyFilters({
      startDate: date.start.format('YYYY-MM-DD'),
      endDate: date.end.format('YYYY-MM-DD'),
      maxDateRangeAllowed: maxDateRangeInCnFilter,
    });
    trackGaEvents({actionType: DATE_SEARCH}, {dates});
  };
  ondateChange = (date, dateString) => {
    const currentDate = moment().format('YYYY-MM-DD');
    this.setState({
      scheduledDate: date ? date : moment(),
    });
    this.props.applyFilters({
      startDate: date ? date.format('YYYY-MM-DD') : currentDate,
      endDate: date ? date.format('YYYY-MM-DD') : currentDate,
    });
    trackGaEvents({actionType: DATE_SEARCH}, {dates: date});
  };
  render() {
    const { classes, date, viewType, isCompact, maxDateRangeInCnFilter, t } = this.props;
    if(getViewControl(viewType).dateFilterOptions &&
     getViewControl(viewType).dateFilterOptions.length > 0 ) {
      getViewControl(viewType).dateFilterOptions.forEach(i => {
        i.val = t(i.val);
      });
    }
    const { dateFilterType } = date;
    return (
        <div>
          <Select
            dropdownClassName={classes.dropdownStyles}
            onChange={this.handleDateFilterTypeChange}
            className={classes.dateFilterSelectStyle}
            value={
             date.dateFilterType
            }>
            {
              this.generateOptionsFromList(getViewControl(viewType).dateFilterOptions)
            }
          </Select>
      {
       dateFilterType === 'scheduled_at' ?
       <DatePicker onChange={this.ondateChange}
        className={classes.datePicker}
        value = {moment(date.start)} />
        :
        <DatePicker.RangePicker
            dropdownClassName={classes.datePickerDropDown}
            className={classNames(classes.datePicker, { [classes.compactDatePicker] : isCompact })}
            onCalendarChange = {(dates) => {
              // if (dates?.length === 1) {
              //   this.setState({ firstDate : dates[0] });
              // }
            }}
            value={[moment(date.start), moment(date.end)]}
            format={'DD/MM/YYYY'}
            onChange={dates => {
              this.setState({dates});
            }} 
            onOpenChange = {open => {
              if(!open){
                this.handleDateChange(this.state.dates);
              }
            }}
            // onChange={this.handleDateChange}
            ranges={{
              [t('Today')]: [moment(), moment()],
              [t('Last 7 Days')]: [moment().subtract(6, 'day'), moment()],
              [t('Last 30 Days')]: [moment().subtract(29, 'day'), moment()],
              ...((maxDateRangeInCnFilter) && (maxDateRangeInCnFilter>31)) && {[`Last ${maxDateRangeInCnFilter} days`] :[moment().subtract(maxDateRangeInCnFilter-1, 'day'), moment()]},
              [t('This Month')]: [moment().startOf('month'), moment().endOf('month')],
            }
          }
          >
          </DatePicker.RangePicker>
      }
    </div>
    );
  }
}

const getHubTypeOptions = (list) => {
  return list.map(elem => {
    return { key: elem, val: HUB_TYPE_MAPPING[elem] || elem };
  });
};

const SingleHubSearchWithType = (props) => {
    const { classes, viewType, hubTypeList, t} = props;
    const viewConfig = getViewControl(viewType);
    if(viewConfig.hubSearchOptions && Array.isArray(viewConfig.hubSearchOptions)) {
      viewConfig.hubSearchOptions.forEach(i => i.val = t(i.val));
    }
    const hubTypeOptions = hubTypeList.length ? getHubTypeOptions(hubTypeList) :
      viewConfig.hubSearchOptions;
    return (
        <div style={{ display: 'flex' }} className={classes.hubSearch}>
          <HubSearch
            hubTypeOptions= {hubTypeOptions}
            defaultHubType= {viewConfig.defaultHubType}
            withType = {true}
          />
      </div>
    );
};

const mapStateToProps = ({ genericConsignmentReducer, masterData, cachedData }, ownProps) => {
  return {
    date: {
      start: genericConsignmentReducer[ownProps.viewType].globalAppliedFilters.startDate,
      end: genericConsignmentReducer[ownProps.viewType].globalAppliedFilters.endDate,
      dateFilterType:
        genericConsignmentReducer[ownProps.viewType].globalAppliedFilters.dateFilterType,
    },
    searchType: genericConsignmentReducer[ownProps.viewType].globalAppliedFilters.searchType,
    searchValue: genericConsignmentReducer[ownProps.viewType].globalAppliedFilters.searchValue,
    hubType: genericConsignmentReducer[ownProps.viewType].globalAppliedFilters.hubType,
    searchAwbNumber:
      genericConsignmentReducer[ownProps.viewType].globalAppliedFilters.searchAwbNumber,
    permissions: masterData.crm_permission,
    currHub: cachedData.currHub,
    scheduledDeliveries: masterData.retail_features_config &&
    masterData.retail_features_config.scheduled_deliveries,
    allowManifestAndCivilSearch:
    masterData.ops_dashboard_config?.parts_to_show?.use_civil_id_in_customer_portal,
    hubTypeList: masterData.planning_hub_type_list || [],
    maxDateRangeInCnFilter: masterData.max_date_range_in_cn_filter || 31,
    showSearchOndemand: masterData.show_ondemand_order_number_search,
    bucket: genericConsignmentReducer[ownProps.viewType].activeBucket,
    customerReferenceListModalColumns:
      masterData?.customer_reference_list_modal_config?.columns || [],
  };
};

const mapDispatchToProps = (dispatch, ownProps) => {
  return bindActionCreators({
    applyFilters: applyGlobalFilters(ownProps.viewType),
    applyGlobalSort: applyGlobalSort(ownProps.viewType),
    setActiveBucket: setActiveBucket(ownProps.viewType),
  }, dispatch);
};
const DateFilterStyled =
  withTranslation('translation')(connect(mapStateToProps, mapDispatchToProps)(withStyles(styles,
     { injectTheme: true })(DateFilter)));
const LogisticsSearchStyled =
  withTranslation('translation')(connect(mapStateToProps, mapDispatchToProps)(withStyles(styles,
     { injectTheme: true })(LogisticsSearch)));
const DomesticSearchStyled =
  withTranslation('translation')(connect(mapStateToProps, mapDispatchToProps)(withStyles(styles,
     { injectTheme: true })(DomesticSearch)));
const HubTypeFilterStyled =
  withTranslation('translation')(connect(mapStateToProps, mapDispatchToProps)(withStyles(styles,
     { injectTheme: true })(HubTypeFilter)));
const MultiHubSearchStyled =
  withTranslation('translation')(connect(mapStateToProps, mapDispatchToProps)(withStyles(styles,
     { injectTheme: true })(MultiHubSearch)));
const SingleHubSearchStyled =
  withTranslation('translation')(connect(mapStateToProps, mapDispatchToProps)(withStyles(styles,
     { injectTheme: true })(SingleHubSearch)));
const SingleHubSearchWithTypeStyled =
  withTranslation('translation')(connect(mapStateToProps, mapDispatchToProps)(withStyles(styles,
     { injectTheme: true })(SingleHubSearchWithType)));
const SearchRedirectStyled =
  withTranslation('translation')(connect(mapStateToProps, mapDispatchToProps)(withStyles(styles,
  { injectTheme: true })(SearchRedirect)));
const SearchWithRouter =
  withRouter(SearchRedirectStyled as React.ComponentType<any>);
