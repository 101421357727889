import * as React from 'react';
import withStyles, { WithStylesProps } from 'react-jss';
import { connect } from 'react-redux';
import styles from './TripManagerDrivers.styles';
import {
    MAP_EDIT_VIEW_TYPE,
} from '../TripManagerMapEditUtils';
import { StartTripIcon } from './TripManagerIcons/StartTrip';
import { LoadingIcon } from './TripManagerIcons/LoadingIcon';
import { UnloadingIcon } from './TripManagerIcons/Unloading';
import Loader from 'src/components/common/Loader';
import { Popover, message } from 'antd';
import moment from 'moment';
import { bindActionCreators } from 'redux';
import {
    applyBucketSort,
    fetchConsignments,
    setSelectedRowKeys,
} from 'src/actions/genericConsignmentActions';
import { getRiderShiftData, getRiderTripDataAsync } from 'src/api/trips';
import { LocalStorageKeys, LocalStorageService } from 'src/services/localStorage';
import { InspectionIcon } from './TripManagerIcons/InspectionIcon';
import { DeliveredCheckIcon } from './TripManagerIcons/CheckMark';
import { DelayedDeliveredIcon } from './TripManagerIcons/DelayedDelivered';
import { TaskFailedIcon } from './TripManagerIcons/TaskFailed';
import { PlannedInspectionIcon } from './TripManagerIcons/PlannedInspection';
import { PlannedLoadingIcon } from './TripManagerIcons/PlannedLoading';
import { PlannedUnloadingIcon } from './TripManagerIcons/UnloadingPlanned';
import { sortBy, uniqueId } from 'lodash';
import { useTranslation } from 'react-i18next';

interface IProps extends WithStylesProps<typeof styles> {
    isLoading: boolean;
    riderTripData: any;
    globalAppliedFilters: any;
}

const TripManagerDrivers = (props: IProps) => {
    const {
        classes,
        globalAppliedFilters,
    } = props;
    const { t } = useTranslation();

    const renderRiderTitle = () => {
        return (
            <div className={classes.riderTitle}>
                Riders
            </div>
        );
    };

    const [shiftDetails, setShiftDetails] = React.useState<any>([]);
    const [riderTripData, setRiderTripData] = React.useState<any>([]);
    const [isLoading, setIsLoading] = React.useState(false);


    const fetchData = async (defaultHub) => {
        setIsLoading(true);

        const tripResult = await getRiderTripDataAsync({
            hubId: defaultHub.id
        });
        if (tripResult.isSuccess) {
            setRiderTripData(tripResult.data);
        } else {
            message.error(tripResult.errorMessage);
        }

        const shiftResult: any = await getRiderShiftData({
            hub_id: defaultHub.id,
            start_date: globalAppliedFilters?.endDate,
            end_date: globalAppliedFilters?.endDate,
        });
        if (shiftResult.isSuccess) {
            setShiftDetails(shiftResult.data);
        } else {
            setShiftDetails([]);
            message.error(shiftResult.errorMessage);
        }

        setIsLoading(false);
    };

    React.useEffect(() => {
        let defaultHub = {};
        try {
            const defaultHubStr = LocalStorageService.getRaw(LocalStorageKeys.__CURRENT_HUB__) || '';
            defaultHub = JSON.parse(defaultHubStr);
        } catch (err) {
            message.error('Failed to retrieve hub');
        }
        fetchData(defaultHub);
    }, [globalAppliedFilters]);

    const availableSlotList = [5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15, 16, 17, 18, 19, 20];

    const renderAvailableSlotList = () => {
        return (
            <div className={classes.slotList}>
                {availableSlotList.map((slot) => {
                    return (
                        <div
                            key={slot}
                            className={classes.slot}
                        >
                            {`${slot}:00`}
                        </div>
                    );
                })}
            </div>
        );
    }

    const renderSlotsHeader = () => {
        return (
            <div className={classes.slotsHeader}>
                {renderRiderTitle()}
                {renderAvailableSlotList()}
            </div>
        );
    };

    const countEventTypes = (riderTrips, key) => {
        let total = 0;

        riderTrips?.forEach(item => {
            const current = Number(item[key]);
            if (!isNaN(current)) {
                total += current;
            }
        });

        return total;
    }

    const renderRiderPopover = (riderTrips) => {
        const completedOnTime = countEventTypes(riderTrips, 'completion_ontime_task_count');
        const completedDelayed = countEventTypes(riderTrips, 'completion_delayed_task_count');
        const pendingOnTime = countEventTypes(riderTrips, 'pending_ontime_task_count');
        const pendingDelayed = countEventTypes(riderTrips, 'pending_delayed_task_count');

        const total = completedOnTime + completedDelayed + pendingOnTime + pendingDelayed;
        return (
            <div className={classes.riderPopover}>
                <div className={classes.popoverData}>
                    <div className={classes.popoverTitle}>{t('TOTAL')} ({total})</div>
                    <div className={classes.popoverRow}>
                        <div className={classes.popoverRowTitle}>{t('Completed')}: </div>
                        <div className={classes.popoverRowDataTitle}>{t('On-Time')}</div>
                        <div className={classes.popoverRowDataValue}>{completedOnTime || 0}</div>
                        <div className={classes.verticalLine} />
                        <div className={classes.popoverRowDataTitle}>{t('Delayed')}</div>
                        <div className={classes.popoverRowDataValue}>{completedDelayed || 0}</div>
                    </div>
                    <div className={classes.popoverRow}>
                        <div className={classes.popoverRowTitle}>{t('Pending')}: </div>
                        <div className={classes.popoverRowDataTitle}>{t('On-Time')}</div>
                        <div className={classes.popoverRowDataValue}>{pendingOnTime || 0}</div>
                        <div className={classes.verticalLine} />
                        <div className={classes.popoverRowDataTitle}>{t('Delayed')}</div>
                        <div className={classes.popoverRowDataValue}>{pendingDelayed || 0}</div>
                    </div>
                </div>
            </div>
        );
    };

    const renderRiderName = (riderTrips) => {
        let workerName = riderTrips.worker_name;

        if (!workerName) {
            workerName = riderTrips?.data[0].worker_name
        }
        return (
            <Popover
                content={renderRiderPopover(riderTrips?.data || [])}
                title={null}
                placement="top"
            >
                <div className={classes.riderName}>
                    {`${workerName} (${riderTrips.worker_code})`}
                </div>
            </Popover>
        );
    };

    const renderRiderSlotRange = (slot: string) => {
        return (
            <div className={classes.riderSlotRange}>
                {slot}
            </div>
        );
    };

    const covertToMoment = (input) => {
        return moment(input, ['HH:mmZ']).format('HH:mm');
    };

    const renderRiderNameAndSlot = (riderTrips, shifts) => {
        let timeSlotStart = `${availableSlotList[0]}:00`;
        let timeSlotEnd = `${availableSlotList[availableSlotList.length - 1]}:00`;

        if (shifts.length) {
            timeSlotStart = covertToMoment(shifts[0].shift_start_time);
            timeSlotEnd = covertToMoment(shifts[0].shift_end_time);
        }

        const defaultTripRange = `${timeSlotStart} - ${timeSlotEnd}`;

        return (
            <div className={classes.riderNameAndSlot}>
                {renderRiderName(riderTrips)}
                {renderRiderSlotRange(defaultTripRange)}
            </div>
        );
    };

    const getTopPosition = (event) => {
        const bottomIconsStatus = ['UNLOADING', 'PLANNED UNLOADING'];

        if (bottomIconsStatus.includes(event.status?.toUpperCase())) {
            return 30;
        }

        return 4;
    };

    const IconWrapper = (Component: any, event) => {
        const eta = event.timestamp ? event.timestamp : event.time_slot_start;
        return (
            <div
                key={uniqueId()}
                className={classes.icon}
                style={{
                    left: getLeftPosition({ eta }),
                    position: eta ? 'sticky' : 'unset',
                    marginTop: getTopPosition(event),
                }}
            >
                <Component />
            </div>
        );
    }

    const iconMapping = {
        start: StartTripIcon,
        loading: LoadingIcon,
        unloading: UnloadingIcon,
        inspection: InspectionIcon,
        planned_inspection: PlannedInspectionIcon,
        planned_loading: PlannedLoadingIcon,
        planned_unloading: PlannedUnloadingIcon
    };

    const getEventWidth = (serviceTime) => {
        const totalSlots = availableSlotList.length;
        const hourWidth = 90 / totalSlots;

        const totalHrs = serviceTime / 60;

        const width = totalHrs * hourWidth;

        return `${width}%`;
    };

    const colorStatusMap = {
        DELIVERED: {
            backgroundColor: '#6B8BFB',
            color: '#FFFFFF',
            border: '1px solid #6B8BFB',
            name: 'D',
        },
        ATTEMPTED: {
            backgroundColor: '#6B8BFB',
            color: '#FFFFFF',
            border: '1px solid #6B8BFB',
            name: 'D',
        },
        'PENDING DELIVERY': {
            backgroundColor: '#FFF',
            color: '#2D2D2D',
            border: '1px solid #6B8BFB',
            name: 'D'
        },
        'PICKED UP': {
            backgroundColor: '#FB6BA8',
            color: '#FFFFFF',
            border: '1px solid #FB6BA8',
            name: 'P',
        },
        'NOT PICKED UP': {
            backgroundColor: '#FB6BA8',
            color: '#FFFFFF',
            border: '1px solid #FB6BA8',
            name: 'P',
        },
        'PENDING PICK UP': {
            backgroundColor: '#FFF',
            color: '#2D2D2D',
            border: '1px solid #FB6BA8',
            name: 'P'
        },
    };

    const getLeftPosition = (params) => {
        const { time, eta } = params
        if (!eta && !time) {
            return '5%';
        }

        let start = '';
        if (time) {
            start = time;
        } else {
            start = moment(eta).format('HH:mm');
        }
        const startHour = start.split(':')[0];
        const startMinute = start.split(':')[1];

        const totalSlots = availableSlotList.length - 1;

        let widthPercentage = 89;
        let toAdd = 9;

        if (params.skipAdd) {
            widthPercentage = 98;
            toAdd = 0.3;
        }
        const hourWidth = widthPercentage / totalSlots;

        const totalHrs = Number(startHour) - availableSlotList[0];
        const totalMinutes = Number(startMinute) / 60;

        const totalWidth = totalHrs + totalMinutes;

        const leftPosition = toAdd + (totalWidth * hourWidth);

        if (params.returnNumber) {
            return Number(leftPosition?.toFixed(4));
        }

        return `${leftPosition?.toFixed(4)}%`;
    };

    const isOperationEvent = (status) => {
        if (colorStatusMap[status?.toUpperCase()]) {
            return true;
        }
        return false;
    };

    const renderReferenceNumber = (event) => {
        const isOperation = isOperationEvent(event.status);

        if (!isOperation) {
            return null;
        }
        return <div className={classes.popoverRow}>
            <div className={classes.popoverEventRowTitle}>Delivery </div>
            <div className={classes.verticalLine} />
            <div className={classes.popoverEventRowTitle}>CN#:</div>
            <div className={classes.popoverEventRowTitle}>{event.reference_number}</div>
        </div>
    };

    const renderCustomerName = (event) => {
        const isOperation = isOperationEvent(event.status);

        if (!isOperation) {
            return null;
        }
        return <div className={classes.popoverRow}>
            <div className={classes.popoverEventRowValue}>Customer Name: </div>
            <div className={classes.popoverRowValueEvent}>{event.customer_name || 'NA'}</div>
        </div>;
    };

    const renderCustomerAddress = (event) => {
        const isOperation = isOperationEvent(event.status);

        if (!isOperation) {
            return null;
        }
        return <div className={classes.popoverRow}>
            <div className={classes.popoverEventRowValue}>Customer Address: </div>
            <div className={classes.popoverRowValueEvent}>{event.receiver_address || 'NA'}</div>
        </div>;
    };

    const renderNumPieces = (event) => {
        const isOperation = isOperationEvent(event.status);

        if (!isOperation) {
            return null;
        }
        return <div className={classes.popoverRow}>
            <div className={classes.popoverEventRowValue}>No. of Pieces:</div>
            <div className={classes.popoverRowValueEvent}>{event.num_pieces}</div>
        </div>;
    };

    const renderPlannedEta = (event) => {
        const isOperation = isOperationEvent(event.status);

        if (!isOperation) {
            return null;
        }
        return <div className={classes.popoverRow}>
            <div className={classes.popoverEventRowValue}>Planned ETA:</div>
            <div className={classes.popoverRowValueEvent}>
                {event.eta ? moment(event.eta).format('YYYY-MM-DD HH:mm') : 'NA'}
            </div>
        </div>;
    };

    const renderStatus = (event) => {
        return <div className={classes.popoverRow}>
            <div className={classes.popoverEventRowValue}>Status:</div>
            <div className={classes.popoverRowValueEvent}>{event.status}</div>
        </div>;
    };

    const renderTimeslot = (event) => {
        const deliveryTimeSlot = getDeliveryTimeSlot(event);

        return <div className={classes.popoverRow}>
            <div className={classes.popoverEventRowValue}>Timeslot:</div>
            <div className={classes.popoverRowValueEvent}>{deliveryTimeSlot}</div>
        </div>;
    };

    const renderEventPopover = (event) => {
        return <div className={classes.riderPopover}>
            {renderReferenceNumber(event)}
            {renderCustomerName(event)}
            {renderCustomerAddress(event)}
            {renderStatus(event)}
            {renderNumPieces(event)}
            {renderPlannedEta(event)}
            {renderTimeslot(event)}
        </div>;
    };

    const getEventIcon = (event) => {
        const config = colorStatusMap[event.status?.toUpperCase()];

        if (['DELIVERED', 'PICKED UP'].includes(event?.status?.toUpperCase())) {
            if (event?.delay > 0) {
                return <DelayedDeliveredIcon />;
            }
            return <DeliveredCheckIcon />;
        }

        if (['NOT PICKED UP', 'ATTEMPTED'].includes(event?.status?.toUpperCase())) {
            return <TaskFailedIcon />;
        }

        return config?.name || '';
    };

    const renderOperation = (event) => {
        const timeInMinuts = (event.time_slot_end - event.time_slot_start) / 60000;

        const config = colorStatusMap[event.status?.toUpperCase()];
        if (!config) {
            return null;
        }

        const leftPosition = getLeftPosition({ eta: event.time_slot_start });
        return (
            <div
                className={classes.operation}
                style={{
                    backgroundColor: config.backgroundColor,
                    width: getEventWidth(timeInMinuts),
                    color: config.color,
                    border: config.border,
                    position: event.time_slot_start ? 'sticky' : 'unset',
                    left: leftPosition,
                    marginLeft: 0,
                    gap: 1,
                }}
            >
                {getEventIcon(event)}
            </div>
        );
    };

    const renderIcon = (iconId, event) => {
        const IconComponent = iconMapping[iconId];
        return IconWrapper(IconComponent, event);
    };

    const renderBreak = (event) => {
        const breakInMinuts = (event.time_slot_end - event.time_slot_start) / 60000;
        const width = getEventWidth(breakInMinuts);

        const currentTime = +new Date();

        const isPastEvent = currentTime > event.time_slot_start && currentTime > event.time_slot_end;

        const lineColor = isPastEvent ? '#FF9999' : '#FFF';
        const secondColor = isPastEvent ? '#FFF' : '#FF9999';
        return (
            <div
                key={uniqueId()}
                className={classes.operation}
                style={{
                    width,
                    left: getLeftPosition({ eta: event.time_slot_start }),
                    position: event.time_slot_start ? 'sticky' : 'unset',
                    backgroundColor: isPastEvent ? '#FF9999' : '#FFF',
                    border: isPastEvent ? 'none' : '1px solid #FF9999',
                    background: `repeating-linear-gradient(
                        45deg,
                        ${lineColor}, 
                        ${lineColor} 5px,
                        ${secondColor} 2px,
                        ${lineColor} 7px
                      )`,
                }}
            />
        );
    };

    const renderEvent = (event) => {
        switch (event.status?.toUpperCase()) {
            // case 'STARTED': return renderIcon('start', event);
            // case 'PLANNED START TIME': return renderIcon('start', event);
            case 'LOADING': {
                const currentTime = +new Date();
                if (currentTime > event.time_slot_start && currentTime > event.time_slot_end) {
                    return renderIcon('loading', event);
                }
                return renderIcon('planned_loading', event);

            }
            // case 'UNLOADING': {
            //     const currentTime = +new Date();
            //     if (currentTime > event.time_slot_start && currentTime > event.time_slot_end) {
            //         return renderIcon('unloading', event);
            //     }
            //     return renderIcon('planned_unloading', event);
            // }
            case 'INSPECTION': {
                const currentTime = +new Date();
                if (currentTime > event.time_slot_start && currentTime > event.time_slot_end) {
                    return renderIcon('inspection', event);
                }
                return renderIcon('planned_inspection', event);
            }
            case 'BREAK': return renderBreak(event);
            default: return renderOperation(event);
        }
    };

    const getDeliveryTimeSlot = (event) => {
        if (event.timestamp && !event?.time_slot_start && !event?.time_slot_end) {
            return moment(event.timestamp).format('HH:mm');
        }
        if (!event?.time_slot_start || !event?.time_slot_end) {
            return 'NA';
        }

        const startTime = moment(event.time_slot_start).format('HH:mm');
        const endTime = moment(event.time_slot_end).format('HH:mm');

        return `${startTime} - ${endTime}`;
    };

    const renderEventList = (tripData) => {
        return (
            <div
                className={classes.singleTrip}
            >
                {tripData?.map((event) => (
                    <Popover
                        content={renderEventPopover(event)}
                        key={uniqueId()}
                    >
                        {renderEvent(event)}
                    </Popover>
                ))}
            </div>
        );
    };

    const getShiftHrs = (shifts) => {
        const activeHrs: any = [];
        const breakHrs: any = [];

        shifts.forEach((shift) => {
            activeHrs.push({
                leftPosition: getLeftPosition({
                    time: covertToMoment(shift.shift_start_time),
                    returnNumber: true,
                    skipAdd: true,
                }),
                rightPosition: getLeftPosition({
                    time: covertToMoment(shift.shift_end_time),
                    returnNumber: true,
                    skipAdd: true,
                }),
            });

            if (shift?.break_details?.length) {
                shift.break_details.forEach((breakTime) => {
                    if (breakTime.startTime && breakTime.endTime) {
                        breakHrs.push({
                            leftPosition: getLeftPosition({
                                time: covertToMoment(breakTime.startTime),
                                returnNumber: true,
                                skipAdd: true,
                            }),
                            rightPosition: getLeftPosition({
                                time: covertToMoment(breakTime.endTime),
                                returnNumber: true,
                                skipAdd: true
                            }),
                        });
                    }
                });
            }
        });

        return { activeHrs, breakHrs };
    };

    const getShiftsOfWorker = (workerId) => {
        const workerRoaster = shiftDetails?.find((shift) => shift.worker_id === workerId);

        return workerRoaster || {};
    };

    const renderSlotsForRider = (tripData, allShiftsOfWorker) => {
        const { activeHrs, breakHrs } = getShiftHrs(allShiftsOfWorker?.shifts || []);

        const colourArray = [];

        breakHrs.forEach((position) => {
            colourArray.push({
                position: position.leftPosition,
                color: '#C0F4FF',
                priority: 1,
            });
            colourArray.push({
                position: position.leftPosition,
                color: '#fae1e1',
            });
            colourArray.push({
                position: position.rightPosition,
                priority: 1,
                color: '#fae1e1',
            });
            colourArray.push({
                position: position.rightPosition,
                color: '#C0F4FF',
            });
        });

        activeHrs.forEach(position => {
            colourArray.push({
                position: position.leftPosition,
                color: '#C0F4FF',
            });
            colourArray.push({
                position: position.rightPosition,
                color: '#C0F4FF',
            });
        });

        const sortedArray = sortBy(colourArray, ['position', 'priority']);

        let colorBlue = 'transparent 0%';

        sortedArray.forEach((item, index) => {
            if (index === 0) {
                colorBlue += `, transparent ${item.position}%`;
            }
            if (colorBlue === '') {
                colorBlue += `${item.color} ${item.position}%`;
            } else {
                colorBlue += `, ${item.color} ${item.position}%`;
            }

            if (index === sortedArray.length - 1) {
                colorBlue += `, transparent ${item.position}%`;
                colorBlue += ', transparent 100%';
            }
        });

        return (
            <div
                className={classes.plannedSlots}
                style={{
                    background: `
                        linear-gradient(to bottom, transparent 50%, transparent 50%),
                        linear-gradient(to right, ${colorBlue})`,
                    backgroundSize: '100% 100%, 100% 50%',
                    backgroundRepeat: 'no-repeat',
                    backgroundPosition: 'top, bottom',
                }}
            >
                {renderEventList(tripData)}
            </div>
        );
    };

    const renderRiderRow = (riderTrips) => {
        if (!riderTrips) {
            return null;
        }

        let workerId = null;

        const tripData = [];
        riderTrips?.data?.forEach((trip) => {
            workerId = trip.worker_id;
            tripData.push(...(trip?.eventDetails || []));
        });

        const allShiftsOfWorker = getShiftsOfWorker(workerId);

        return (
            <div className={classes.riderRow}>
                {renderRiderNameAndSlot(riderTrips, allShiftsOfWorker?.shifts || [])}
                {renderSlotsForRider(tripData, allShiftsOfWorker)}
            </div>
        );
    };

    const renderRiderSlotPlanning = () => {
        return (
            <div className={classes.riderSlotPlanning}>
                {riderTripData?.workersData?.map((riderTrips) => {
                    return renderRiderRow(riderTrips);
                })}
            </div>
        );
    };

    if (isLoading) {
        return <Loader zIndex={100} />;
    }

    return (
        <div className={classes.mainDiv}>
            {renderSlotsHeader()}
            {renderRiderSlotPlanning()}
        </div>
    );
};

const TripManagerDriversTab = withStyles(styles, { injectTheme: true })(
    TripManagerDrivers,
);

const mapDispatchToProps = (dispatch, ownProps) => {
    const viewType = MAP_EDIT_VIEW_TYPE;
    const { bucket } = ownProps;
    return bindActionCreators({
        fetchTrips: fetchConsignments(viewType),
        setSelectedRowKeys: setSelectedRowKeys(viewType)(bucket),
        applyBucketSort: applyBucketSort(viewType)(bucket),
    }, dispatch);
};

const mapStateToProps = ({ genericConsignmentReducer }, ownProps) => {
    const viewType = MAP_EDIT_VIEW_TYPE;
    const currentViewReducer = genericConsignmentReducer[viewType];
    const { globalAppliedFilters } = currentViewReducer;
    return {
        globalAppliedFilters,
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(TripManagerDriversTab);
