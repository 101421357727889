import * as React from 'react';
import { Tooltip, Input, Select, InputNumber  } from 'antd';
import * as moment from 'moment';
import store from '@store/appStore';

export const getSortByDateFilterMapping = {
  order_pickup_time: 'last_main_event_time',

};
export function renderPrettyDateTime(text) {
  return (
    text ?
    <div>
      <div>{moment(text).format('DD MMM, YYYY')}</div>
      <div>{moment(text).format('hh:mm A')}</div>
    </div> : null
  );
}
export function ellipsisWithTooltip(text, args?: {
  style?: React.CSSProperties;
}) {
  const { style } = args || {};
  return (
    <Tooltip title={text}>
      <div style={{
        maxWidth: '170px',
        display: 'inline-block',
        whiteSpace: 'nowrap',
        overflow: 'hidden',
        textOverflow: 'ellipsis',
        cursor: 'pointer',
        ...style,
      }}
      >{text}</div>
    </Tooltip>);
}
export function ellipsisWithoutTooltip(text, props?: {
  style?: React.CSSProperties;
}) {
  const { style } = props || {};
  return (
    <div style={{
      maxWidth: '170px',
      display: 'inline-block',
      whiteSpace: 'nowrap',
      overflow: 'hidden',
      textOverflow: 'ellipsis',
      cursor: 'pointer',
      ...style,
    }}>
      {text}
    </div>);
}
export function getHubKeyLabel(hub) {
  return { key: hub.id, label: `${hub.name}, ${hub.code} (${hub.category_name})` };
}

export function getHubCodeLabel(hub) {
  return { key: hub?.code, label: hub?.code };
}

export function getSelectedHubList(key?) {
  const state = store?.getState();
  const { masterData, cachedData } = state;
  const {
    allow_multiple_hub_selection_in_dispatch: allowMultipleHubSelection,
  } = masterData;
  const {
    selectedHubsList: selectedHubs,
    currHub,
  } = cachedData;

  if (key && key.trim().length) {
    return (allowMultipleHubSelection && selectedHubs)
    ? selectedHubs.map(hub => hub[key])
    : [currHub[key]];
  }

  return (allowMultipleHubSelection && selectedHubs)
    ? selectedHubs : [currHub];
}

export const renderCustomFieldInput = (key, name, type, value, handleInputChange) => {
  switch (type) {
    case 'number':
      return (
        <InputNumber
          style={{ width: '100%' }}
          placeholder={`Enter ${name}`}
          value={value}
          onChange={(value) => handleInputChange(key, value)}
        />
      );
    case 'boolean':
      return (
        <Select
          value={value}
          placeholder={`Enter ${name}`}
          style={{ width: '100%' }}
          onChange={(value) => handleInputChange(key, value)}
          options={[
            { label: 'True', value: true },
            { label: 'False', value: false },
          ]}
        />
      );
    default:
      return (
        <Input
          placeholder={`Enter ${name}`}
          value={value}
          onChange={(e) => handleInputChange(key, e.target.value)}
          allowClear
        />
      );
  }
};

export const appliedExceptionColumns = [
  {
    column_id: 'reference_number',
    pretty_name: 'Reference Number',
  },
  {
    column_id: 'exception_name',
    pretty_name: 'Exception Name',
  },
  {
    column_id: 'exception_code',
    pretty_name: 'Exception Code',
  },
  {
    column_id: 'applied_by',
    pretty_name: 'Applied By',
  },
  {
    column_id: 'hub_code',
    pretty_name: 'Hub Code',
  },
  {
    column_id: 'event_time',
    pretty_name: 'Time',
  },
  {
    column_id: 'notes',
    pretty_name: 'Reason',
  },
  {
    column_id: 'images',
    pretty_name: 'Images',
  },
];

export const fetchAuditLogTitle = (eventType, objectType) => {
  const titles = {
    worker: {
      insert: 'Rider Created',
      update: 'Rider Updated',
      enable: 'Rider Enabled',
      disable: 'Rider Disabled',
    },
  };
  return titles[objectType]?.[eventType] || 'Status Changed';
};

export const fetchAuditLogNavbarTitle = (workerName: string, objectType: string): string => {
  const titles = {
    worker: workerName,
  };
  return titles[objectType];
};
export const fetchAuditLogActionOption = (objectType) => {
  const actionOptions = {
    worker: {
      insert: "Insert",
      update: "Update",
      enable: "Enable",
      disable: "Disable",
    },
  };
  return actionOptions[objectType] || {};
};

export const capitalize = (text = '') =>
  text
    .toString()
    .split('_')
    .map(word => word.charAt(0).toUpperCase() + word.slice(1))
    .join(' ');
