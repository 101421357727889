export const colorStatusMapping = {
  softdata_upload: '#ca5db4',
  tracking_pending: '#ca5db4',
  accepted_for_delivery: '#874db7',
  pickup_completed: '#4d73b7',
  in_transit: '#64a2d0',
  reachedatdestinationhub: '#4cc9ce',
  outfordelivery: '#89ec98',
  delivered: '#4cba38',
  attempted: '#ec544a',
  rto: '#9b2626',
  archived: '#9b9b9b',
  rto_initiated : '#ca651c',
  rto_in_transit: '#0689e7',
  rto_delivered : '#118462',

};

export const bagTypePrettyNameMapping = {
  hub_to_hub: 'Hub to Hub',
  address_to_hub: 'Address to Hub',
  hub_to_address: 'Hub to Address',
  address_to_address: 'Address to Address',
};

export const consignmentStatusPrettyNameMapping = {
  attempted: 'Undelivered',
  reachedathub: 'Reached At Hub',
  on_hold: 'On Hold',
  fts: 'FTS',
  'hub-to-hub': 'Hub To Hub',
  rto: 'RTO',
  inscan_at_hub: 'Inscan At Hub',
  inscan_at_origin_hub: 'Inscan At Origin Hub',
  cancelled: 'Cancelled',
  intransittohub: 'In Transition To Hub',
  outfordelivery: 'Out for Delivery',
  pickup_completed: 'Pickup Completed',
  created: 'Created',
  accept: 'Accept',
  in_transit: 'In Transit',
  archived: 'Archived',
  delivered: 'Delivered',
  softdata_upload: 'Softdata Upload',
  tracking_pending: 'Tracking Pending',
  bagged : 'Bagged',
  booked_at_hub : 'Booked At Hub',
  delivery : 'Delivery',
  not_picked_up : 'Not Picked Up',
  out_for_pickup : 'Out for Pickup',
  pickup_awaited : 'Pickup Awaited',
  pickup_scheduled : 'Pickup Scheduled',
  rts_initiated : 'RTS Initiated',
  rto_initiated : 'RTO Initiated',
  rto_in_transit: 'RTO in Transit',
  rto_delivered : 'RTO Delivered',
  lost : 'Lost',
  shelved: 'Shelved',
  rescheduled : 'Rescheduled',
  received: 'Received',
  not_received: 'Not Received',
  out_for_store_pickup: 'Out for Store Pickup',
  loaded: 'Loaded',
  sealed: 'Sealed',
  departed: 'Departed',
  intransit_to_hub: 'In Transit',
  outscan_at_hub: 'Outscan',
  loading: 'Loading',
  all_consignment_received: 'All CNs Received',
  debagged: 'Debagged',
  added_to_ist: 'Added to IST',
  received_against_ist: 'Received Against IST',
  inscan_at_gateway: 'Inscan At Gateway',
  mark_clearance_at_origin: 'Marked Clearance At Origin',
  mark_clearance_at_destination: 'Marked Clearance At Destination',
  handover_courier_partner: 'Handover Carrier Partner',
  assign_courier_partner: 'Assign Carrier Partner',
  receive_shipments_in_gateway: 'Receive shipments in the gateway',
  pending_custom: 'Pending custom',
  shipment_clear_successfully: 'Shipment clear successfully',
  shipment_not_clear: 'Shipment not clear',
  inscan_at_planning_hub: 'Inscan At Planning Hub',
  manifest_submitted: 'Manifest Submitted',
  manifest_rejected: 'Manifest Rejected',
  manifest_accepted: 'Manifest Accepted',
  declaration_submitted: 'Declaration Submitted',
  declaration_rejected: 'Declaration Rejected',
  declaration_accepted: 'Declaration Accepted',
  approval_pending: 'Approval Pending',
  archive_created: 'Archive Created',
  archive_submitted: 'Archive Submitted',
  archive_rejected: 'Archive Rejected',
  archive_saved: 'Archive Saved',
  archive_sent: 'Archive Sent',
  pdf_submitted: 'PDF Submitted',
  rejected: 'Rejected',
  rvp_delivered: 'RVP Delivered',
  exception: 'Exception',
  bag_received: 'Bag Received',
  vehicle_arrived: 'Vehicle Arrived',
  added_to_bag: 'Added To Bag',
  deps_exception: 'DEPS',
  create_courier_booking: 'Create Carrier Booking',
  pickup_arrival: 'Pickup Arrival',
  received_againt_ist: 'Received Against IST',
  ist_rider_consent_send: 'IST Rider Consent Send',
  trip_rider_consent_send: 'Trip Rider Consent Send',
};
export function getPrettyStatusCustomerActivity(status) {
  const mapping = {
    success : 'Delivered',
    attempted: 'Attempted',
    reachedathub: 'Reached At Hub',
    on_hold: 'On Hold',
    fts: 'FTS',
    'hub-to-hub': 'Hub To Hub',
    rto: 'RTO',
    inscan_at_hub: 'Inscan At Hub',
    inscan_at_origin_hub: 'Inscan At Origin Hub',
    cancelled: 'Cancelled',
    intransittohub: 'In Transition To Hub',
    outfordelivery: 'Out for Delivery',
    pickup_completed: 'Pickup Completed',
    created: 'Created',
    accept: 'Accept',
    in_transit: 'In Transit',
    archived: 'Archived',
    delivered: 'Delivered',
    softdata_upload: 'Softdata Upload',
    outscan_at_hub: 'Outscan at hub',
    added_to_ist: 'Added to IST',
    sealed: 'Sealed',
    deist : 'De-IST',
    tracking_pending: 'Tracking Pending',
    bagged : 'Bagged',
    booked_at_hub : 'Booked At Hub',
    delivery : 'Delivery',
    not_picked_up : 'Not Picked Up',
    out_for_pickup : 'Out for Pickup',
    pickup_awaited : 'Pickup Awaited',
    pickup_scheduled : 'Pickup Scheduled',
    rts_initiated : 'RTS Initiated',
    rto_initiated : 'RTO Initiated',
    rto_in_transit: 'RTO in Transit',
    rto_delivered : 'RTO Delivered',
    rvp_delivered: 'RVP Delivered',
    exception: 'Exception',
  };
  return mapping[status];
}

export function getConsignmentStatusPrettyName(status) {
  return status ?
    (consignmentStatusPrettyNameMapping.hasOwnProperty(status)
      ? consignmentStatusPrettyNameMapping[status] : status)
    : status;
}

export function getBagTypePrettyName(bagType) {
  return bagTypePrettyNameMapping.hasOwnProperty(bagType) ?
    bagTypePrettyNameMapping[bagType] : bagType;
}

export const getColorStatusMapping = (status) => {
  return colorStatusMapping[status] || '#4cba38';
};
