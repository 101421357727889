export const columnsToShow = [
  {
    column_id: 'organisation_reference_number',
    pretty_name: 'Trip #',
  },
  {
    pretty_name: 'Creation Time',
    column_id: 'created_at',
  },
  {
    pretty_name: 'Current Hub',
    column_id: 'hub_name',
  },
  {
    pretty_name: 'Trip Type',
    column_id: 'type',
  },
  {
    pretty_name: 'Vehicle#',
    column_id: 'vehicle_registration_number',
  },
  {
    pretty_name: 'Driver',
    column_id: 'worker_name',
  },
  {
    pretty_name: 'Status',
    column_id: 'status',
  },
  {
    pretty_name: '#Consignments',
    column_id: 'task',
  },
  {
    pretty_name: 'Last Updated',
    column_id: 'updated_at',
  },
  {
    pretty_name: 'Actions',
    column_id: 'actions',
  },
];
export const detailColumnsToShow = [
  {
    column_id: 'reference_number',
    pretty_name: 'CN #',
  },
  {
    pretty_name: 'Address',
    column_id: 'receiver_address',
  },
  {
    pretty_name: 'Pincode',
    column_id: 'destination_pincode',
  },
  {
    pretty_name: 'Customer',
    column_id: 'customer_name',
  },
  {
    pretty_name: 'Current Status',
    column_id: 'status',
  },
  {
    pretty_name : 'Status(In Trip)',
    column_id : 'in_trip_status',
  },
  {
    pretty_name: 'Number of Pieces',
    column_id: 'no_of_pieces',
  },
  {
    pretty_name: 'Consignment Type',
    column_id: 'consignment_type',
  },
  {
    pretty_name: 'Checkin Status',
    column_id: 'arrival_time',
  },
  {
    pretty_name: 'Service Type',
    column_id: 'service_type_id',
  },
  {
    pretty_name : 'Failure Reason',
    column_id : 'failure_reason',
  },
  {
    pretty_name: 'Weight(in Kgs)',
    column_id: 'weight',
  },
  {
    pretty_name: 'Task Type',
    column_id: 'task_type',
  },
  {
    pretty_name: 'Order Service Time(mins)',
    column_id: 'service_time_mins',

  },
  {
    pretty_name: 'Customer Reference Number',
    column_id: 'customer_reference_number',
  },
];

export const vendorDetailColumns = [
  {
    pretty_name: 'Vendor Name',
    column_id: 'vendorName',
  },
  {
    pretty_name: 'Assigned Time',
    column_id: 'assigned_time',
  },
];

export const stopDetailColumns = [
  {
    pretty_name: 'Stop ID',
    column_id: 'sequence_number',
  },
  {
    pretty_name: 'Customer',
    column_id: 'customer_name',
  },
  {
    pretty_name: 'Address',
    column_id: 'address',
  },
  {
    pretty_name: 'Delivery Timeslot Start',
    column_id: 'delivery_time_slot_start',
  },
  {
    pretty_name: 'Delivery Timeslot End',
    column_id: 'delivery_time_slot_end',
  },
  {
    pretty_name: 'Pickup Timeslot Start',
    column_id: 'pickup_time_slot_start',
  },
  {
    pretty_name: 'Pickup Timeslot End',
    column_id: 'pickup_time_slot_end',
  },
  {
    pretty_name: 'No. of Consignments',
    column_id: 'number_of_consignment',
  },
  {
    pretty_name: 'Status',
    column_id: 'checkin_checkout_status',
  },
  {
    pretty_name: 'Actions',
    column_id: 'actions',
  },
];

export const taskDetailColumns = [
  {
    pretty_name: 'Task ID',
    column_id: 'task_reference_number',
  },
  {
    pretty_name: 'Consignment No.',
    column_id: 'consignment_reference_number',
  },
  {
    pretty_name: 'Task Type',
    column_id: 'task_type',
  },
  {
    pretty_name: 'Expected Consignments',
    column_id: 'expected_cn',
  },
  {
    pretty_name: 'Picked Consignments',
    column_id: 'picked_cn',
  },
  {
    pretty_name: 'Sender Name',
    column_id: 'customer_name',
  },
  {
    pretty_name: 'Address',
    column_id: 'address_line_1',
  },
  {
    pretty_name: 'Pincode',
    column_id: 'pincode',
  },
  {
    pretty_name: 'Zone',
    column_id: 'zone',
  },
  {
    pretty_name: 'Time Slot',
    column_id: 'time_slot_start',
  },
  {
    pretty_name: 'Weight (Kg)',
    column_id: 'given_weight',
  },
  {
    pretty_name: 'Closing Time',
    column_id: 'closing_time_string',
  },
  {
    pretty_name: 'Status',
    column_id: 'task_status',
  },
  {
    pretty_name: 'Failure Reason',
    column_id: 'failure_reason',
  },
  {
    pretty_name: 'Actions',
    column_id: 'actions',
  },
];

export const pickupTaskDetailColumns = [
  {
    pretty_name: 'Task ID',
    column_id: 'task_reference_number',
  },
  {
    pretty_name: 'Consignment No.',
    column_id: 'consignment_reference_number',
  },
  {
    pretty_name: 'Task Type',
    column_id: 'task_type',
  },
  {
    pretty_name: 'Expected Consignments',
    column_id: 'expected_cn',
  },
  {
    pretty_name: 'Picked Consignments',
    column_id: 'picked_cn',
  },
  {
    pretty_name: 'Sender Name',
    column_id: 'customer_name',
  },
  {
    pretty_name: 'Address',
    column_id: 'address_line_1',
  },
  {
    pretty_name: 'Pincode',
    column_id: 'pincode',
  },
  {
    pretty_name: 'Zone',
    column_id: 'zone',
  },
  {
    pretty_name: 'Time Slot',
    column_id: 'time_slot_start',
  },
  {
    pretty_name: 'Weight (Kg)',
    column_id: 'given_weight',
  },
  {
    pretty_name: 'Closing Time',
    column_id: 'closing_time_string',
  },
  {
    pretty_name: 'Status',
    column_id: 'task_status',
  },
  {
    pretty_name: 'Failure Reason',
    column_id: 'failure_reason',
  },
  {
    pretty_name: 'Actions',
    column_id: 'actions',
  },
];

export const pieceDetailColumns = [
  {
    pretty_name: 'Detail',
    column_id: 'description',
  },
  {
    pretty_name: 'Weight(Kg)',
    column_id: 'weight',
  },
  {
    pretty_name: 'Volume',
    column_id:'volume',
  },
  {
    pretty_name: 'Status',
    column_id: 'status',
  },
  {
    pretty_name: 'Quantity',
    column_id: 'quantity',
  },
  {
    pretty_name: 'NDR',
    column_id: 'delivery_failure_reason',
  },
  {
    pretty_name: 'NPR',
    column_id: 'pickup_failure_reason',
  },
  {
    pretty_name: 'Amount To Be Collected',
    column_id: 'cod_amount',
  },
];

export const extraPieceDetailColumns = [
  {
    pretty_name: 'Item Unit',
    column_id: 'item_unit',
  },
];

export const taskConsignmentDetailsColumns = [
  {
    pretty_name: 'Name',
    column_id: 'name',
  },
  {
    pretty_name: 'Reference Number',
    column_id: 'reference_number',
  },
  {
    pretty_name: 'Status',
    column_id: 'status',
  },
  {
    pretty_name: 'Address',
    column_id: 'address_line_1',
  },
  {
    pretty_name: 'City',
    column_id: 'city',
  },
  {
    pretty_name: 'State',
    column_id: 'state',
  },
  {
    pretty_name: 'Weight',
    column_id: 'weight',
  },
  {
    pretty_name: 'Created At',
    column_id: 'created_at',
  },
];

export const pieceDetailColumnsExpress = [
  {
    pretty_name: 'CN#',
    column_id: 'reference_number',
  },
  {
    pretty_name: 'Detail',
    column_id: 'description',
  },
  {
    pretty_name: 'Hub Code',
    column_id: 'hub_code',
  },
  {
    pretty_name: 'Weight(Kg)',
    column_id: 'weight',
  },
  {
    pretty_name: 'Volume',
    column_id:'volume',
  },
  {
    pretty_name: 'Status',
    column_id: 'status',
  },
  {
    pretty_name: 'Ordered Quantity',
    column_id: 'quantity',
  },
  {
    pretty_name: 'Picked Quantity',
    column_id: 'quantity_pickedup',
  },
  {
    pretty_name: 'Delivered Quantity',
    column_id: 'quantity_delivered',
  },
  {
    pretty_name: 'Attempt Proof',
    column_id: 'attempt_proof',
  },
  {
    pretty_name: 'CN Verification',
    column_id: 'is_cnv_done',
  },
  {
    pretty_name: 'Amount to be Collected',
    column_id: 'cod_amount',
  },
  {
    pretty_name: 'Delivery Failure Reason',
    column_id: 'delivery_failure_reason',
  },
  {
    pretty_name: 'Pickup Failure Reason',
    column_id: 'pickup_failure_reason',
  },
  {
    pretty_name: 'Proof Image',
    column_id: 'proof_image',
  },
];

export const getDetailColumnsToShow = (showCODAmount, showVolume,
  showSimulateEta, hideActualEta) => {
  let columnsToReturn = detailColumnsToShow;
  const extraColumns = [
    {
      pretty_name: 'Volume(m3)',
      column_id: 'volume',
    },
    {
      pretty_name: 'Amount',
      column_id: 'cod_value',
    },
  ];
  const actions = [
    {
    pretty_name: 'Actions',
    column_id: 'actions',
    },
  ];
  if(showCODAmount && showVolume){
    columnsToReturn = columnsToReturn.concat(extraColumns);
  }
  if(!hideActualEta){
    columnsToReturn = columnsToReturn.concat({
      pretty_name: 'Actual ETA',
      column_id: 'eta',
    });
  }
  if(showSimulateEta){
    columnsToReturn = columnsToReturn.concat({
      pretty_name: 'Planned ETA',
      column_id: 'simulated_eta',
    },
    {
      pretty_name: 'Planned ETA Breached',
      column_id: 'eta_breached',
    },
    {
      pretty_name: 'Delivery Timeslot Start',
      column_id: 'delivery_time_slot_start',
    },
    {
      pretty_name: 'Delivery Timeslot End',
      column_id: 'delivery_time_slot_end',
    },
    );
  }
  return columnsToReturn.concat(actions);
};
export const planningConsignmentColumns = [
  {
    pretty_name: 'CN#',
    column_id: 'reference_number',
  },
  {
    pretty_name: 'Destination Address',
    column_id: 'destination_address',
  },
  {
    pretty_name: 'Destination Pincode',
    column_id: 'destination_pincode',
  },
  {
    pretty_name: 'Destination Name',
    column_id: 'destination_name',
  },
  {
    pretty_name: 'Current Status',
    column_id: 'consignment_status',
  },
  {
    pretty_name: 'Pieces',
    column_id: 'num_pieces',
  },
  {
    pretty_name: 'Service Type',
    column_id: 'service_type',
  },
  {
    pretty_name: 'Weight(Kg)',
    column_id: 'weight',
  },
];
export const taskFailureColumns = [
  {
    title: 'Task Number',
    dataIndex: 'referenceNumber',
  },
  {
    title: 'Reason',
    dataIndex: 'error',
  },
];
export const bulkDeleteFailureColumns = [
  {
    title: 'Task Number',
    dataIndex: 'reference_number',
  },
  {
    title: 'Reason',
    dataIndex: 'message',
  },
];

export const reorderCNColumns = [
  {
    pretty_name: 'S. No.',
    id: 's_no',
    key: 's_no',
    type: 'number',
  },
  {
    pretty_name: 'CN #',
    id: 'reference_number',
    key: 'reference_number',
    type: 'string',
  },
  {
    pretty_name: 'Address',
    id: 'receiver_address',
    key: 'receiver_address',
    type: 'string',
  },
  {
    pretty_name: 'Customer',
    id: 'customer_name',
    key: 'customer_name',
    type: 'string',
  },
  {
    pretty_name: 'Consignment Type',
    id: 'consignment_type',
    key: 'consignment_type',
    type: 'string',
  },
  {
    pretty_name: 'Number Of Pieces',
    id: 'no_of_pieces',
    key: 'no_of_pieces',
    type: 'number',
  },
];
