import * as React from 'react';
import withStyles from 'react-jss';
import { connect } from 'react-redux';
import { CssType,
  ThemeType} from '../../../../theming/jssTypes';
import { Modal, message, Select, Button, DatePicker, Checkbox, Row, Col, InputNumber, TimePicker,
  Popconfirm, Tooltip, Input, Radio, Table,
 } from 'antd';
import lodash from 'lodash';
import { CalendarOutlined, CloseOutlined, PlusOutlined, DeleteOutlined,
  InfoOutlined, MinusCircleOutlined } from '@ant-design/icons';
import { createRoute, createRouteAll,
  getHubProfiles, createHubProfiles, updateHubProfiles, deleteHubProfiles,
  getVehicleMakeDataForHub } from '../../../../api/retailDashboard';
import { getRetailTripManagerRoute, getAutoAllocateRequestsPageRoute } from '../../../../routing/utils';
import { withRouter } from 'react-router';
import { generateTimeSlotsOptionsFromList } from '../../../../utils/retailTimeslotsOptions';
import { getKeyArrayFromObject, downloadFileData, columnsToShowCreateRouteHubProfile } from '../../../../utils/utils';
import { getViewBuckets }from '../../../../utils/genericConfig/utils';
import * as moment from 'moment';
import FailureArrayHandler from '../../../common/FailureArrayHandler';
import { checkForRescheduledCNs } from '../../../../api/trips';
import ConfirmationModal from '../../../common/ConfirmationModal';
import { createSecondaryTrips } from '../../../../api/trips';
import { Link } from 'react-router-dom';
import { renderCustomFieldInput } from 'src/components/common/utils';

const styles = (theme: ThemeType): CssType => ({
  createRouteModal: {
    marginTop: 5,
    '& input': {
      width: '200px',
      borderRadius: '4px 0 0 4px',
      lineHeight: '32px',
    },
  },
  noWidthInput: {
    width: 'auto',
    margin: '0 !important',
    '& .ant-input': {
      width: '0px',
      padding: '0',
      borderLeft: 'none',
    },
  },
  createRouteModalInfo: {
    marginTop: '-20px',
    backgroundColor: '#F1F2F6',
    fontStyle: 'italic',
    height: '32px',
    padding: '5px',
    marginBottom: '14px',
  },
  defaultBody: {
    flex: 1,
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    padding: '5px 0',
  },
  vehicleContainer: {
    display: 'flex',
    flexDirection: 'row',
    height: '100%',
  },
  vehicleLeftPanel: {
    display: 'flex',
    flexDirection: 'column',
    borderRight: '1px solid #f0f0f0',
    width: '25%',
    scrollbarWidth: 'thin',
    scrollbarColor: 'transparent transparent',
    overflowY: 'scroll',
  },
  vehicleProfile: {
    padding: '5px 0',
  },
  vehicleRightPanel: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-between',
    width: '100%',
  },
  selectStyle: {
    width: '100%',
    minWidth: '200px',
  },
  selectContainer: {
    marginBottom: '10px',
  },
  infoText: {
    paddingTop: '12px',
  },
  profilesTable: {
    '& .ant-table-thead > tr > th': {
      padding: '12px',
      lineHeight: '0.5715',
    },
  },
  dedicatedVehiclesContainer: {
    position: 'relative',
    width: '100%',
    '& .ant-select-selection-item': {
      display: 'none !important',
    },
  },
  dedicatedVehicleOptions: {
    position: 'absolute',
    left: '17px',
    top: '45%',
    transform: 'translateY(-50%)',
    pointerEvents: 'none',
    fontSize: '14px',
  },
  optionsListContainer: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    width: '100%',
    fontSize: '12px',
  },
  tripReferenceNumberContainer: {
    color: 'black',
    fontSize: '10px',
  },
  option: {
    display: 'flex',
    alignItems: 'center',
    gap: '8px',
  },
  availableText: {
    color: '#7CBE28',
    fontSize: '10px',
  },
});

const inputTypeEnum = {
  vehicleInput: 'vehicleInput',
  rosterInput: 'rosterInput',
  onDutyInput: 'onDutyInput',
  bothRosterOnDutyInput: 'bothRosterOnDutyInput',
};

const vehicleFleetTypeEnum = {
  DEDICATED: 'DEDICATED',
  MARKET: 'MARKET',
};

class CreateRouteModal extends React.Component<any, any> {

  state: any = {
    isLoading: false,
    startTime: '',
    endTime: '',
    prettyName: '',
    failureArrayModalVisible: false,
    failureArray: [],
    isRescheduledCNConfirmationgModalVisible: false,
    rescheduledCNArray: [],
    tripStartTime: '',
    enableVehicleInput: false,
    vehicleMakeDetails: [{
      vehicleMakeCode: null,
      quantity: null,
    }],
    vehicleMakeMapping: {},
    loadingVehicleMakes: true,
    errors: {},
    vehicleProfiles: [],
    fetchedVehicleMakes: [],
    selectedProfile: {
      profile_name: '',
      profile_data: [],
      profile_id: '',
    },
    editingProfileName: '',
    editingProfileData: [],
    selectedProfileIndex: -1,
    enableRosterInput: false,
    enableOnDutyInput: false,
    enableBothRosterOnDutyInput: false,
    disableMapping: {
      vehicleInput: false,
      rosterInput: false,
      onDutyInput: false,
      bothRosterOnDutyInput: false,
    },
    tripDurationHours: '',
    tripDurationMinutes: '',
    customFields: {},
    isModalOpen: false,
    createUpdateLoading: false,
    deleteLoading: false,
    vehicleSearchText: '',
    totalVehicleOptionsMap: {},
  };
  failureColumns = [
    {
      title: 'Consignment Number',
      dataIndex: 'reference_number',
    },
    {
      title: 'Reason',
      dataIndex: 'reason',
    },
  ];

  async fetchProfiles(hubId) {
    const { allowVehicleInputCreateRoute, classes } = this.props;
    if (!hubId || !allowVehicleInputCreateRoute) return;

    const res = await getHubProfiles({ hub_id: hubId });
    if (res.isSuccess) {
      const data = res.data || {};
      this.setState({
        fetchedVehicleMakes: data.default_data,
        vehicleProfiles: data.profiles,
      });
    } else {
      message.error(res.errorMessage ?? 'Error in fetching vehicle make data');
    }
  }

  async componentDidMount() {
    await this.fetchProfiles(this.props.currHub?.id);
    this.initializeVehicleOptionsMap();
  }

  handleModalClose = () => {
    this.props.handleModalClose(false);
  };

  showModal = () => {
    this.setState({ isModalOpen: true });
  };

  closeModal = () => {
    this.setState({ isModalOpen: false });
  };

  getScheduledDate = (value) => {
    let startDate = moment().format('YYYY-MM-DD');
    let endDate = moment().format('YYYY-MM-DD');
    if (value && value.length > 1) {
      startDate = value[0].format('YYYY-MM-DD');
      endDate = value[1].format('YYYY-MM-DD');
    }
    const obj = {
      fromDateString : startDate,
      toDateString : endDate,
    };
    return value && value.length > 1 ? obj : {};
  };

  disabledDate = (current) => {
    return current && current <= moment().subtract(1, 'days');
  };

  getPaddedString = (finalStringLength, actualString) => {
    actualString = String(actualString).trim();
    if (actualString.length >= finalStringLength) {
      return actualString;
    }
    return actualString.padStart(finalStringLength, '0');
  };

  getTripDuration = () => {
    const { tripDurationHours, tripDurationMinutes } = this.state;
    if(!tripDurationHours && !tripDurationMinutes){
      return '';
    }
    return this.getPaddedString(2, tripDurationHours) + ':' + this.getPaddedString(2, tripDurationMinutes);
  };

  getAPIRequestBody = () => {
    const {
      bucketFilters,
      isFiltered,
      action,
      dateFilter,
      showTripStartTimeForOptimisation,
      showTripStartTime,
      selectedHub,
      filters,
      trips,
      rosterEnabled,
    } = this.props;
    const { tripStartTime, prettyName, startTime, endTime,
      enableVehicleInput, vehicleMakeDetails, customFields, selectedProfile = {} } = this.state;
    const defaultStartTime = tripStartTime ? Date.parse(tripStartTime) : Date.now();

    const commonParams = {
      source: 'dashboard_create_route',
      hubId: selectedHub.id,
      fromDate: this.props.filters.startDate,
      toDate :  this.props.filters.endDate,
      dateField: filters.dateFilterType,
      routeOptimizationTimeSlots : {
        pretty_name: prettyName,
        start_time: startTime,
        end_time: endTime,
      },
      applyFilters: isFiltered ? true : undefined,
      ...(showTripStartTimeForOptimisation &&
        showTripStartTime && { defaultStartTime, duration: this.getTripDuration() }),
      custom_fields: customFields,
    };
    const filterParams = {
      ...bucketFilters,
      statusList: isFiltered && bucketFilters.statusList || undefined,
      attemptCountList: isFiltered && bucketFilters.attemptCountList || undefined,
      bookingServiceTypeIdList: isFiltered &&
                      bucketFilters.bookingServiceTypeIdList || undefined,
      customerIdList: isFiltered &&
                      getKeyArrayFromObject(bucketFilters.customerIdList) || undefined,
      consignmentType: isFiltered && bucketFilters.consignmentType || undefined,
      orderTypeList: isFiltered && bucketFilters.orderTypeList || undefined,
      proximityList: isFiltered && bucketFilters.proximityList || undefined,
      purchasingGroup : isFiltered && bucketFilters.purchasingGroup || undefined,
      originPincodeList : isFiltered && bucketFilters.originPincodeList || undefined,
      movementTypeList : isFiltered && bucketFilters.movementTypeList || undefined,
      destinationPincodeList : isFiltered && bucketFilters.destinationPincodeList || undefined,
      referenceNumberListInclusive :
      isFiltered && bucketFilters.referenceNumberListInclusive || undefined,
      scheduledDate :  isFiltered ?
                       this.getScheduledDate(bucketFilters.scheduledDate) : undefined,
      nextHubIdList: isFiltered && getKeyArrayFromObject(bucketFilters.nextHubIdList) || undefined,
      assignedRider: isFiltered &&
          getKeyArrayFromObject(bucketFilters.assignedRider) || undefined,
      storeHubIdList: isFiltered &&
          getKeyArrayFromObject(bucketFilters.storeHubIdList) || undefined,
      exceptionIdList: isFiltered &&
          getKeyArrayFromObject(bucketFilters.exceptionIdList) || undefined,
      destinationHubIdList: isFiltered &&
          getKeyArrayFromObject(bucketFilters.destinationHubIdList) || undefined,
      kiranaHubIdList: isFiltered &&
          getKeyArrayFromObject(bucketFilters.kiranaHubIdList) || undefined,
    };

    let body;
    if (this.props.isAll) {
      body = action === 'create_secondary_trips' ? {
        endPointType: 'all',
        ...commonParams,
        ...filterParams,
      } : {
        ...commonParams,
        ...filterParams,
      };
      if(this.props.tripIsConsolidatedPlanningType)
    {
      body ={
        ...body,
        planningType : this.props.viewType,
      };
    }
    } else {
      body = action === 'create_secondary_trips' ? {
        endPointType: 'selected',
        tripReferenceNumberList: trips,
        ...commonParams,
      } : {
        // consignmentCapacity,
        consignments : this.props.consignments,
        hubId: this.props.selectedHub.id,
        routeOptimizationTimeSlots : {
          pretty_name : this.state.prettyName,
          start_time : this.state.startTime,
          end_time : this.state.endTime,
        },
        scheduledDate : dateFilter.dateFilterType === 'scheduled_at' ?
        { fromDateString: dateFilter.start , toDateString: dateFilter.end } : undefined,
        ...(showTripStartTimeForOptimisation &&
          showTripStartTime && { defaultStartTime, duration: this.getTripDuration() }),
        custom_fields: customFields,
      };
    if(this.props.tripIsConsolidatedPlanningType)
    {
      body ={
        ...body,
        taskReferenceNumberList : this.props.tasks,
        planningType : this.props.viewType,
      };
    }
    }

    if (enableVehicleInput && this.state.editingProfileData?.length) {
      body.inputDataOfVehicleMake = this.state.editingProfileData;
    }

    body.useOnDutyRiders = this.state.enableOnDutyInput;
    if(rosterEnabled){
      body.useRosterEnabledRiders = this.state.enableRosterInput;
      if(this.state.enableBothRosterOnDutyInput){
        body.useOnDutyRiders = true;
        body.useRosterEnabledRiders = true;
      }
    }

    body.changeVehicle = true;
    return body;
  };

  showSuccessMessage = (successMessage, link) => {
    return (
      message.success({
        content: (
          <div>
            {successMessage}{' '}
            <a href={`/ops${link}`} target="_blank">
              View
            </a>
            <CloseOutlined
              style={{
                color: '#333333',
                marginLeft: '0.6rem',
                marginRight: '0',
              }}
              onClick={() => message.destroy()}
            />
          </div>
        ),
        className: 'custom-antd-success-message',
        duration: 7,
      })
    );
  };

  createRouteApiCall = async () => {
    const { viewType, bucket, action, isAll, isAsync } = this.props;

    let actionConfig : any = {};
    if (action) {
      const bucketConfig = getViewBuckets(viewType)[bucket];
      actionConfig = bucketConfig.actionsApiCalls ?
                     bucketConfig.actionsApiCalls[action] : {};
    }

    let response;
    const body = this.getAPIRequestBody();
    if (action === 'create_secondary_trips') {
      response = actionConfig?.createSecondaryTrips ?
        await actionConfig.createSecondaryTrips(body, isAsync) :
        await createSecondaryTrips(body, isAsync);
    } else if (isAll) {
      response = actionConfig && actionConfig.createRouteForAll ?
        await actionConfig.createRouteForAll(body, this.props.isAsync) :
        await createRouteAll(body, this.props.isAsync);
    } else {
      response = actionConfig && actionConfig.createRouteForSelected ?
        await actionConfig.createRouteForSelected(body, this.props.isAsync) :
        await createRoute(body, this.props.isAsync);
    }

    if (response.isSuccess) {
      if (!this.props.isAsync) {
        message.success('Trips Created');
        downloadFileData(response.data, 'consignments');
        if (this.props.shouldRedirectToNewTab) {
          this.showSuccessMessage('Route created successfully', getRetailTripManagerRoute());
        } else {
          this.props.history.push(getRetailTripManagerRoute(), {
            activeBucket: 'created',
          });
        }
      } else if (this.props.shouldRedirectToNewTab) {
        this.showSuccessMessage('Auto allocation request created', getAutoAllocateRequestsPageRoute());
      } else {
        message.success('Auto allocation request created');
        this.props.history.push(getAutoAllocateRequestsPageRoute());
      }
      this.props.handleModalClose(true);
    } else if(response?.failures?.length){
      this.setState({failureArray: response.failures, failureArrayModalVisible: true});
    } else{
      message.error(response.errorMessage);
      this.props.handleModalClose(false);
    }

    this.setState({
      confirmLoading: false,
    });
  };

  handleRescheduledCNConfirmation = async() => {
    this.setState({
      confirmLoading: true,
      isRescheduledCNConfirmationgModalVisible: false,
    });

    this.createRouteApiCall();
  };

  checkForRescheduledCNApiCall = async() => {
    const body = this.getAPIRequestBody();
    const response = await checkForRescheduledCNs(body);

    if (response.isSuccess) {
      const data = response.data;
      if (data && data.consignments && data.consignments.length > 0) {
        const rescheduledConsignments = data.consignments.map(rCN => {
          rCN.message = 'Consignment is scheduled for ' + moment(rCN.rescheduled_date).format('DD/MM/YYYY');
          return rCN;
        });

        this.setState({
          isRescheduledCNConfirmationgModalVisible : true,
          rescheduledCNArray: rescheduledConsignments,
          confirmLoading: false,
        });
      } else {
        this.createRouteApiCall();
      }
    } else {
      message.error(response.errorMessage);
      this.props.handleModalClose(false);
      this.setState({
        confirmLoading: false,
      });
    }
  };

  handleCreateRouteSubmit = async() => {
    const { checkForRescheduledCNs } = this.props;

    // validation for vehicle make data
    const { enableVehicleInput } = this.state;

    this.setState({
      confirmLoading: true,
    });

    const body = this.getAPIRequestBody();
    if (checkForRescheduledCNs) {
      this.checkForRescheduledCNApiCall();
    } else {
      this.createRouteApiCall();
    }
  };
  handleRescheduledCNConfirmationgModalClose = () => {
    this.setState({
      isRescheduledCNConfirmationgModalVisible: false,
    });
  };
  handleConsignmentCapacityChange = (e) => {
    this.setState({ consignmentCapacity: Number(e.target.value) });
  };
  onTimeSlotChange = (val) => {
    const timeSlot = val.split('_');
    if (timeSlot.length && timeSlot.length > 2) {
      this.setState({
        startTime: timeSlot[0],
        endTime: timeSlot[1],
        prettyName: timeSlot[2],
      });
    }
  };

  renderFailureArray = () => {
    const { failureArrayModalVisible, failureArray } = this.state;

    return failureArrayModalVisible ?
      <FailureArrayHandler isVisible={failureArrayModalVisible}
       failureColumns={this.failureColumns}
       failureArray={failureArray}
       onModalClose = {() => this.setState({failureArrayModalVisible: false})} />
      : null;
  };

  renderTimeSlots = () => {
    const { timeSlots, classes } = this.props;
    return timeSlots?.length > 0 && (
      <div className={classes.selectContainer}>
        <span>CN Time Slot</span>
        <Select
          allowClear
          className={classes.selectStyle}
          placeholder="Select Time Slot"
          onChange={this.onTimeSlotChange}
          suffixIcon={<CalendarOutlined />}
        >
          {generateTimeSlotsOptionsFromList(timeSlots)}
        </Select>
      </div>
    );
  };

  disableCheckBoxInput = (action,value) => {
    if(value === true){
      switch(action){
        case inputTypeEnum.vehicleInput:
          this.setState({
            disableMapping : {
              vehicleInput: false,
              rosterInput: true,
              onDutyInput: true,
              bothRosterOnDutyInput: true,
            },
            enableRosterInput: false,
            enableOnDutyInput: false,
            enableBothRosterOnDutyInput: false,
          });
          break;
        case inputTypeEnum.rosterInput:
          this.setState({
            disableMapping : {
              vehicleInput: true,
              rosterInput: false,
              onDutyInput: true,
              bothRosterOnDutyInput: true,
            },
            enableVehicleInput: false,
            enableOnDutyInput: false,
            enableBothRosterOnDutyInput: false,
          });
          break;
        case inputTypeEnum.onDutyInput:
          this.setState({
            disableMapping : {
              vehicleInput: true,
              rosterInput: true,
              onDutyInput: false,
              bothRosterOnDutyInput: true,
            },
            enableVehicleInput: false,
            enableRosterInput: false,
            enableBothRosterOnDutyInput: false,
          });
          break;
        case inputTypeEnum.bothRosterOnDutyInput:
          this.setState({
            disableMapping : {
              vehicleInput: true,
              rosterInput: true,
              onDutyInput: true,
              bothRosterOnDutyInput: false,
            },
            enableVehicleInput: false,
            enableRosterInput: false,
            enableOnDutyInput: false,
          });
          break;
      }
    } else {
      this.setState({
        disableMapping : {
          vehicleInput: false,
          rosterInput: false,
          onDutyInput: false,
          bothRosterOnDutyInput: false,
        },
      });
    }
  };

  onEnableVehicleInputChange = (e) => {
    const valueToSet = e.target?.checked ?? false;
    this.setState({
      enableVehicleInput: valueToSet,
    });
    this.disableCheckBoxInput(inputTypeEnum.vehicleInput,valueToSet);
  };

  onEnableRosterInputChange = (e) => {
    const valueToSet = e.target?.checked ?? false;
    this.setState({
      enableRosterInput: valueToSet,
    });
    this.disableCheckBoxInput(inputTypeEnum.rosterInput, valueToSet);
  };

  onEnableOnDutyInputChange = (e) => {
    const valueToSet = e.target?.checked ?? false;
    this.setState({
      enableOnDutyInput: valueToSet,
    });
    this.disableCheckBoxInput(inputTypeEnum.onDutyInput, valueToSet);
  };

  onEnableBothRosterOnDutyInputChange = (e) => {
    const valueToSet = e.target?.checked ?? false;
    this.setState({
      enableBothRosterOnDutyInput: valueToSet,
    });
    this.disableCheckBoxInput(inputTypeEnum.bothRosterOnDutyInput, valueToSet);
  };

  renderEnableOnDutyRidersInput = () => {
    const { classes } = this.props;
    return (
      <div className={classes.selectContainer} style={{ display: 'flex' }}>
        <Checkbox
          value={this.state.enableOnDutyInput}
          onChange={this.onEnableOnDutyInputChange}
          disabled={this.state.disableMapping.onDutyInput}
        />
        <span style={{ paddingLeft: '15px' }}>Consider On Duty Riders Only</span>
      </div>
    );
  };

  renderEnableRosterRidersInput = () => {
    const { rosterEnabled, classes } = this.props;
    return rosterEnabled && (
      <div className={classes.selectContainer} style={{ display: 'flex' }}>
        <Checkbox
          value={this.state.enableRosterInput}
          onChange={this.onEnableRosterInputChange}
          disabled={this.state.disableMapping.rosterInput}
        />
        <span style={{ paddingLeft: '15px' }}>Consider Roster Riders Only</span>
      </div>
    );
  };

  renderEnableBothOnDutyRosterRidersInput = () => {
    const { rosterEnabled, classes } = this.props;
    return rosterEnabled && (
      <div className={classes.selectContainer} style={{ display: 'flex' }}>
        <Checkbox
          value={this.state.enableBothRosterOnDutyInput}
          onChange={this.onEnableBothRosterOnDutyInputChange}
          disabled={this.state.disableMapping.bothRosterOnDutyInput}
        />
        <span style={{ paddingLeft: '15px' }}>Consider Both On Duty and Roster Riders</span>
      </div>
    );
  };

  renderEnableVehicleInput = () => {
    const { allowVehicleInputCreateRoute, classes } = this.props;
    return allowVehicleInputCreateRoute && (
      <div className={classes.selectContainer} style={{ display: 'flex' }}>
        <Checkbox
          value={this.state.enableVehicleInput}
          onChange={this.onEnableVehicleInputChange}
          disabled={this.state.disableMapping.vehicleInput}
        />
        <span style={{ paddingLeft: '15px' }}>Enable Vehicle Input</span>
      </div>
    );
  };

  updateVehicleMakeProfile = (index: number, key: string, value: any) => {
    const { selectedProfile = {}, editingProfileData } = this.state;
    this.setState({
      editingProfileData: editingProfileData?.map((v: any, i: number) => {
        if (i === index) {
          return {
            ...v,
            [key]: value,
          };
        }
        return v;
      }),
    });
  };
  handleSearch = (e) => {
    // Prevent the select dropdown from closing
    e.preventDefault();
    e.stopPropagation();
    this.setState({ vehicleSearchText: e.target.value });
  };
  generateOptionsFromList = (list, selectedVehicleMakeCode, searchText = '') => {
    const { classes } = this.props;
    const optionsList = [];
    if (!Array.isArray(list) || !list.length) {
      return optionsList;
    }
    optionsList.push(
      <Select.Option
        key="search-input"
        value=""
        style={{ cursor: 'default' }}
      >
        <Input
          value={this.state.vehicleSearchText}
          placeholder="Search vehicle code"
          onChange={this.handleSearch}
          onKeyDown={e => e.stopPropagation()}
          onClick={e => e.stopPropagation()}
          onMouseDown={e => e.stopPropagation()}
          style={{ width: '100%' }}
        />
      </Select.Option>,
    );

    const filteredList = searchText ?
      list.filter(ele => ele.vehicle_code.toLowerCase().includes(searchText.toLowerCase())) : list;

    filteredList.forEach((ele) => {
      if (ele.trip_reference_number) {
        optionsList.push(
          <Select.Option
            key={ele.vehicle_code}
            value={ele.vehicle_code}
            disabled={true}
          >
            <div className={classes.optionsListContainer}>
              <div className={classes.option}>
                <input
                  type="checkbox"
                  checked={false}
                  disabled={true}
                  style={{ cursor: 'not-allowed' }}
                />
                <span>{ele.vehicle_code}</span>
              </div>
              <span className={classes.tripReferenceNumberContainer}>
                {ele.trip_reference_number}
              </span>
            </div>
          </Select.Option>,
        );
      } else {
        optionsList.push(
          <Select.Option
            key={ele.vehicle_code}
            value={ele.vehicle_code}
          >
            <div className={classes.optionsListContainer}>
              <div className={classes.option}>
                <input
                  type="checkbox"
                  checked={this.isVehicleSelected(ele.vehicle_code, selectedVehicleMakeCode)}
                  readOnly
                />
                <span>{ele.vehicle_code}</span>
              </div>
              <span className={classes.availableText}>
                Available
              </span>
            </div>
          </Select.Option>,
        );
      }
    });
    return optionsList;
  };
  isVehicleSelected = (vehicleCode, selectedVehicleMakeCode) => {
    const { editingProfileData } = this.state;
    const dedicatedVehicleCodeList = editingProfileData
      ?.find(v => v.vehicle_make_code === selectedVehicleMakeCode)?.vehicle_list
      ?.filter((v) => v.type === vehicleFleetTypeEnum.DEDICATED)
      ?.[0]?.vehicle_id_list || [];
    return dedicatedVehicleCodeList.some(v => v.vehicle_code === vehicleCode);
  };

  componentDidUpdate(prevProps, prevState) {
    if(prevState.editingProfileData !== this.state.editingProfileData) {
      this.initializeVehicleOptionsMap();
    }
  }

  initializeVehicleOptionsMap = () => {
    const { fetchedVehicleMakes = [], editingProfileData = [] } = this.state;
    const newTotalVehicleOptionsMap = { ...this.state.totalVehicleOptionsMap };
    let hasChanges = false;

    editingProfileData.forEach(profile => {
      const selectedVehicleMakeCode = profile?.vehicle_make_code;
      if (selectedVehicleMakeCode && !newTotalVehicleOptionsMap[selectedVehicleMakeCode]) {
        const fetchedVehicleMakeMap = lodash.keyBy(fetchedVehicleMakes, 'vehicle_make_code');
        const fetchedVehicleMakeData = fetchedVehicleMakeMap[selectedVehicleMakeCode];
        if (fetchedVehicleMakeData) {
          const dedicatedVehicleList = fetchedVehicleMakeData.dedicated_vehicle_list || [];
          newTotalVehicleOptionsMap[selectedVehicleMakeCode] = dedicatedVehicleList.length;
          hasChanges = true;
        }
      }
    });

    if (hasChanges) {
      this.setState({ totalVehicleOptionsMap: newTotalVehicleOptionsMap });
    }
  };

  onDropdownVisibleChange = (open: boolean) => {
    if (!open) {
      this.setState({ vehicleSearchText: '' });
    }
  };

  generateDedicatedVehicleOptions = (index) => {
    const {
      fetchedVehicleMakes = [],
      editingProfileData,
      vehicleSearchText = '',
      totalVehicleOptionsMap,
    } = this.state;

    if (!editingProfileData?.[index]) {
      return [];
    }

    const selectedVehicleMakeData = editingProfileData[index];
    const selectedVehicleMakeCode = selectedVehicleMakeData?.vehicle_make_code;
    if (!selectedVehicleMakeCode) {
      return [];
    }

    const fetchedVehicleMakeMap = lodash.keyBy(fetchedVehicleMakes, 'vehicle_make_code');
    const fetchedVehicleMakeData = fetchedVehicleMakeMap[selectedVehicleMakeCode];
    if (!fetchedVehicleMakeData) {
      return [];
    }

    const dedicatedVehicleList = fetchedVehicleMakeData.dedicated_vehicle_list || [];
    const enhancedVehicleList = dedicatedVehicleList.map(vehicle => ({
      ...vehicle,
    }));

    return this.generateOptionsFromList(
      enhancedVehicleList,
      selectedVehicleMakeCode,
      vehicleSearchText,
    );
  };

  updateDedicatedVehicleList = (index, value) => {
    const { selectedProfile = {}, editingProfileData } = this.state;
    this.setState({
      editingProfileData: [
        ...editingProfileData.slice(0, index),
        {
          ...editingProfileData[index],
          vehicle_list: editingProfileData[index]?.vehicle_list?.map((vl) => {
            if (vl.type === vehicleFleetTypeEnum.DEDICATED) {
              return {
                ...vl,
                vehicle_id_list: value.map((v) => ({
                  vehicle_code: v,
                  is_active: true,
                })),
              };
            }
            return vl;
          }),
        },
        ...editingProfileData.slice(index + 1),
      ],
    });
  };

  updateMarketVehicleData = (index, key, value) => {
    const { selectedProfile = {}, editingProfileData } = this.state;
    this.setState({
      editingProfileData: editingProfileData.map((v, i) => {
        if (i === index) {
          return {
            ...v,
            vehicle_list: v.vehicle_list.map((vl) => {
              if (vl.type === vehicleFleetTypeEnum.MARKET) {
                if (key === 'infinte_vehicles') {
                  return {
                    ...vl,
                    infinite_vehicles: true,
                    quantity: null,
                  };
                }
                return {
                  ...vl,
                  [key]: value,
                  infinite_vehicles: false,
                };
              }
              return vl;
            }),
          };
        }
        return v;
      }),
    });
  };

  generateVehicleMakeOptions = () => {
    const { fetchedVehicleMakes = [], selectedProfile = {}, editingProfileData } = this.state;
    const existingVehicleMakes = editingProfileData?.map((v) => v?.vehicle_make_code);
    return fetchedVehicleMakes.filter((v) =>
      !existingVehicleMakes.includes(v?.vehicle_make_code)) || [];
  };
  addVehicleMake = () => {
    const { selectedProfile = {}, editingProfileData } = this.state;
    this.setState({
      editingProfileData: [
        ...editingProfileData,
        {
          vehicle_make_code: null,
          is_active: true,
          vehicle_make_info: {},
          vehicle_list: [
            {
              type: vehicleFleetTypeEnum.DEDICATED,
              vehicle_id_list: [],
            },
            {
              type: vehicleFleetTypeEnum.MARKET,
              quantity: 0,
              infinite_vehicles: false,
            },
          ],
        },
      ],
    });
  };

  generateDataColumns = () => {
    const { selectedProfile = {}, fetchedVehicleMakes = [], editingProfileData } = this.state;
    const columnsToShow = columnsToShowCreateRouteHubProfile;
    const { classes } = this.props;
    const columns =
      columnsToShow &&
      columnsToShow.map((col) => {
        return {
          key: col.column_id,
          dataIndex: col.column_id,
          title: col.pretty_name,
          width: col.width || '10%',
          render: (x, record, index) => {
            switch (col.column_id) {
              case 'vehicle_make_code':
                const vehicleMakeOptions = this.generateVehicleMakeOptions();
                return ( x || (
                    <Select
                      placeholder = "Select Vehicle Make"
                      showSearch
                      optionFilterProp="children"
                      style={{ width: '100%' }}
                      options={vehicleMakeOptions.map((key) => ({
                        label: key.vehicle_make_code,
                        value: key.vehicle_make_code,
                      }))}
                      filterOption={(inputVal, option: any) => {
                        if (!inputVal) {
                          return true;
                        }
                        const optionval = option.value ? option.value.trim().toLowerCase() : '';
                        return optionval.includes(inputVal.trim().toLowerCase());
                      }}
                      onChange={(value) => this.updateVehicleMakeProfile(index, 'vehicle_make_code', value)}
                    />
                  )
                );
                case 'dedicated_vehicle': {
                  const dedicatedVehicleCodeList = record?.vehicle_list
                    ?.filter((v) => v.type === vehicleFleetTypeEnum.DEDICATED)
                    ?.[0]?.vehicle_id_list || [];
                  const dedicatedVehicleCodes = dedicatedVehicleCodeList.map((v) => v.vehicle_code);
                  const totalOptions = this.state.totalVehicleOptionsMap[record.vehicle_make_code] || 0;
                  const selectedCount = dedicatedVehicleCodes.length;
                  return (
                    <div style={{ display: 'flex', alignItems: 'center', gap: '5px', justifyContent: 'center' }}>
                      <div className={classes.dedicatedVehiclesContainer} style={{ position: 'relative', width: '100%' }}>
                        <Select
                          mode="multiple"
                          style={{ width: '100%', maxHeight: '100px', overflowY: 'auto' }}
                          value={dedicatedVehicleCodes}
                          onChange={(value) => this.updateDedicatedVehicleList(index, value)}
                          maxTagCount={0}
                          placeholder={`0 / ${totalOptions}`}
                          suffixIcon={null}
                          showSearch={false}
                          dropdownStyle={{ maxHeight: '300px', overflow: 'auto' }}
                          menuItemSelectedIcon={null}
                          onDropdownVisibleChange={this.onDropdownVisibleChange}
                        >
                          {this.generateDedicatedVehicleOptions(index)}
                        </Select>
                        {selectedCount > 0 && (
                          <div className={classes.dedicatedVehicleOptions}>
                            {`${selectedCount} / ${totalOptions}`}
                          </div>
                        )}
                      </div>
                    </div>
                  );
                }
              case 'market_vehicle':
                const currentQuantity = record?.vehicle_list
                  ?.filter((v) => v.type === vehicleFleetTypeEnum.MARKET)?.[0]?.quantity || 0;
                const infiniteVehicle = record?.vehicle_list
                  ?.filter((v) => v.type === vehicleFleetTypeEnum.MARKET)
                  ?.[0]?.infinite_vehicles || false;
                return (
                  <Radio.Group value={infiniteVehicle ? 'infinite' : 'custom'}>
                    <Radio
                      value={'custom'}
                      checked={!infiniteVehicle}
                      onChange={(e) => this.updateMarketVehicleData(index, 'type', vehicleFleetTypeEnum.MARKET)}
                    >
                      <InputNumber
                        placeholder="Quantity"
                        value={currentQuantity}
                        min={0}
                        onChange={(value) => this.updateMarketVehicleData(index, 'quantity', value)}
                        type="number"
                      />
                    </Radio>
                    <Radio
                      value={'infinite'}
                      checked={infiniteVehicle}
                      onChange={(e) => this.updateMarketVehicleData(index, 'infinte_vehicles', e.target.value)}
                    >
                      Recommended
                    </Radio>
                  </Radio.Group>
                );
              case 'actions':
                return (
                  <MinusCircleOutlined
                    onClick={() => this.setState({
                      editingProfileData: editingProfileData.filter((v, i) => i !== index),
                    })}
                  />
                );
              default:
                return x;
            }
          },
        };
      });
    return columns;
  };

  handleDeleteProfile = async (profileId) => {
    this.setState({
      deleteLoading: true,
    });
    const { vehicleProfiles } = this.state;
    const res = await deleteHubProfiles({
      profile_id: profileId,
    });
    if (!res.isSuccess) {
      message.error(res.errorMessage);
    } else {
      message.success('Profile deleted successfully');
      this.setState({
        vehicleProfiles: vehicleProfiles.filter((profile) => profile.profile_id !== profileId),
        selectedProfile: {
          profile_name: '',
          profile_data: [],
          profile_id: '',
        },
        editingProfileName: '',
        editingProfileData: [],
      });
    }
    this.fetchProfiles(this.props.currHub?.id);
    this.setState({
      deleteLoading: false,
      isModalOpen: false,
    });
  };

  handleSaveOrCreateProfile = async () => {
    this.setState({
      CreateUpdateLoading: true,
    });
    const { selectedProfile, editingProfileName, editingProfileData } = this.state;
    const clonedProfile = lodash.cloneDeep(editingProfileData);
    clonedProfile.profile_data = clonedProfile.filter((v) => v.is_active);
    clonedProfile.profile_data = clonedProfile.profile_data.map((v) => {
      if (v.type === vehicleFleetTypeEnum.DEDICATED) {
        v.vehicle_id_list = (v.vehicle_id_list.filter((veh) => veh.is_active) || []).map((veh) => {
          delete veh.is_active;
          return veh;
        });
      }
      delete v.is_active;
      delete v.vehicle_make_info;
      return v;
    });
    const body = {
      data_to_update: clonedProfile?.profile_data,
      name: editingProfileName,
      hub_id: this.props.currHub?.id,
    };
    if (editingProfileName === selectedProfile?.profile_name) {
      const res = await updateHubProfiles({
        ...body,
        profile_id: selectedProfile.profile_id,
      });
      if (!res.isSuccess) {
        message.error(res.errorMessage);
      } else {
        message.success('Profile updated successfully');
      }
    }
    else {
      const res = await createHubProfiles(body);
      if (!res.isSuccess) {
        message.error(res.errorMessage);
      } else {
        message.success('Profile created successfully');
      }
    }
    this.fetchProfiles(this.props.currHub?.id);
    this.setState({
      CreateUpdateLoading: false,
    });
  };

  renderRightPanel = () => {
    const { classes } = this.props;
    const {
      selectedProfile = {},
      vehicleProfiles = [],
      editingProfileData,
      editingProfileName,
    } = this.state;
    return (
      <div className={classes.vehicleRightPanel}>
        <Row style={{ backgroundColor: '#F4F4F6', padding: '10px', flexDirection: 'column', display: 'flex' }}>
          <Row style={{justifyContent: 'space-between', width: '100%', marginBottom: '10px', gap: '20px'}}>
            <Col span={10}>
              <Input
                value={this.state.editingProfileName}
                onChange={(e) => this.setState({
                  editingProfileName: e.target.value,
                })}
              />
            </Col>
            <Col>
              <Button
                onClick={() => {
                  this.showModal();
                }}
                disabled={selectedProfile?.is_default}
                loading={this.state.deleteLoading}
                >
                  Delete Profile
              </Button>
              {(this.state.editingProfileName !== '' && this.state.editingProfileName === selectedProfile?.profile_name) && <Button
                style={{ marginLeft: '10px' }}
                onClick={() => {
                  this.handleSaveOrCreateProfile();
                }}
                disabled={selectedProfile?.is_default ||
                  ( editingProfileData === selectedProfile?.profile_data )}
                loading={this.state.CreateUpdateLoading}
                >
                  Update Profile
              </Button>}
              {(this.state.editingProfileName !== selectedProfile?.profile_name || this.state.editingProfileName === '') && (
                <Button style={{ marginLeft: '10px' }} disabled={editingProfileData.length === 0 || editingProfileName === ''} onClick={this.handleSaveOrCreateProfile} loading={this.state.CreateUpdateLoading}>
                  New Profile
                </Button>
              )}

            </Col>
          </Row>
          <Row>
          <Modal
            title="Delete Profile"
            visible={this.state.isModalOpen}
            onCancel={this.closeModal}
            footer={[
              <Button key="cancel" onClick={this.closeModal}>
                Cancel
              </Button>,
              <Button
                key="delete"
                danger
                onClick={() => this.handleDeleteProfile(selectedProfile?.profile_id)}
                disabled={selectedProfile?.is_default}
              >
                Delete
              </Button>,
            ]}
              >
            <p>Are you sure you want to delete this Profile?</p>
          </Modal>
            <div style={{ height: '300px', overflow: 'auto', width: '100%' }}>
              <Table
                className={classes.profilesTable}
                dataSource={editingProfileData || []}
                columns={this.generateDataColumns()}
                style={{ height: '90%' }}
                pagination={false}
              />
            </div>
          </Row>
          <Row>
            <div style={{ width: '100%' }}>
              <Button
                type="text"
                onClick={() => this.addVehicleMake()}
              >
                <PlusOutlined /> Add Vehicle Make
              </Button>
            </div>
          </Row>
        </Row>
      </div>
    );
  };

  renderVehicleMakeDetails = () => {
    const { classes } = this.props;
    const { enableVehicleInput, vehicleProfiles, selectedProfile = {} } = this.state;
    if (enableVehicleInput) {
      return <div style={{ paddingLeft: '8px' }} >
        <div style={{ marginBottom: '5px' }}>
          Profiles
        </div>
        <Select
          style={{ width: '50%', marginBottom: '16px' }}
          placeholder="Select Profile"
          value={selectedProfile?.profile_id || null}
          onChange={(value) => {
            if (value === selectedProfile?.profile_id) {
              return;
            }

            const profile = vehicleProfiles.find(p => p.profile_id === value);
            if (!profile) {
              return;
            }

            const index = vehicleProfiles.findIndex(p => p.profile_id === value);
            const sanitizedProfileData = (profile?.profile_data || [])
              .filter((v) => v.is_active)
              .map(item => ({
                ...item,
                vehicle_list: item.vehicle_list.map(vl => ({
                  ...vl,
                  vehicle_id_list: vl.vehicle_id_list || [],
                })),
              }));

            this.setState({
              selectedProfile: {
                profile_name: profile.profile_name,
                profile_data: sanitizedProfileData,
                profile_id: profile.profile_id,
                is_default: profile.is_default,
              },
              selectedProfileIndex: index,
              editingProfileName: profile.profile_name,
              editingProfileData: sanitizedProfileData,
            });
          }}
        >
          {vehicleProfiles.map((profile) => (
            <Select.Option
              key={profile.profile_id || profile.profile_name}
              value={profile.profile_id}
            >
              {profile.profile_name} {profile.is_default && ' (Default)'}
            </Select.Option>
          ))}
        </Select>

        <div className={classes.vehicleContainer}>
          {this.renderRightPanel()}
        </div>
      </div>;
    }
    return <></>;
  };

  shouldRenderInfoText(durationHours, durationMinutes, tripStartTime) {
    let isDurationHoursValid = false;
    let isDurationMinutesValid = false;
    let isTripStartTimeValid = false;

    if (String(durationHours) === '0' || durationHours) {
      isDurationHoursValid = true;
    }

    if (String(durationMinutes) === '0' || durationMinutes) {
      isDurationMinutesValid = true;
    }

    if (tripStartTime) {
      isTripStartTimeValid = true;
    }

    return isDurationHoursValid && isDurationMinutesValid && isTripStartTimeValid;
  }

  handleInputChange = (key, value) => {
    this.setState((prevState) => ({
      customFields: {
        ...prevState.customFields,
        [key]: value,
      },
    }));
  };

  renderCustomFields = () => {
    const { opsFullTripColumnList, opsDashboardTripCreationModalFields } = this.props;
    const { customFields } = this.state;
    const customFieldList = opsDashboardTripCreationModalFields?.length && opsFullTripColumnList
      ?.filter((item) => opsDashboardTripCreationModalFields.includes(item.column_id));
    if (Array.isArray(customFieldList) && customFieldList.length) {
      return customFieldList?.map((field) => {
        const { column_id, pretty_name, type } = field;
        return (
          <Row align="middle" style={{ paddingTop: '10px' }} key={column_id}>
            <Col span={6}>
              {pretty_name}:
            </Col>
            <Col span={10}>
              {renderCustomFieldInput(column_id,
                pretty_name, type, customFields[column_id], this.handleInputChange)}
            </Col>
          </Row>
        );
      });
    }
  };

  onOptimisationTypeChange = (value) => {
    this.setState({
      enableVehicleInput: false,
      enableRosterInput: false,
      enableOnDutyInput: false,
      enableBothRosterOnDutyInput: false,
      [value]: true,
    });
  };

  renderOptionsOptimisationType = () => {
    const { allowVehicleInputCreateRoute, rosterEnabled } = this.props;
    const options = [];
    options.push({ label: 'All Vehicles', value: 'allVehicles'});
    options.push({ label: 'Consider On Duty Riders Only', value: 'enableOnDutyInput' });
    if (allowVehicleInputCreateRoute) {
      options.push({ label: 'Enable Vehicle Input', value: 'enableVehicleInput' });
    }
    if (rosterEnabled) {
      options.push({ label: 'Consider Roster Riders Only', value: 'enableRosterInput' });
      options.push({ label: 'Consider Both On Duty and Roster Riders', value: 'enableBothRosterOnDutyInput' });
    }
    return options.map((item) => (
      <Select.Option key={item.value} value={item.value}>
        {item.label}
      </Select.Option>
    ));
  };

  render() {
    const { isVisible, consignments, tasks, action, trips, classes,
            tripIsConsolidatedPlanningType, showTripStartTimeForOptimisation,
            showTripStartTime, timeSlots } = this.props;
    const { confirmLoading,
      isRescheduledCNConfirmationgModalVisible, rescheduledCNArray, tripStartTime,
      enableVehicleInput, selectedProfile, isModalOpen } = this.state;
    let count = consignments?.length ? consignments.length : 0;
    let type = 'consignments';
    if(tripIsConsolidatedPlanningType)
    {
      const taskCount =  tasks?tasks.length:0;
      count += taskCount;
      type = 'tasks';
    }
    const defaultStartTime = tripStartTime ? Date.parse(tripStartTime) : Date.now();
    const defaultStartTimeHours = (new Date(defaultStartTime)).getHours();
    const defaultStartTimeMinutes = (new Date(defaultStartTime)).getMinutes();
    const selectionType = action === 'create_secondary_trips' ? 'trip' : type;
    const selectedCount = action === 'create_secondary_trips' ? trips?.length : count;
    return (
      <Modal
        width={900}
        confirmLoading={confirmLoading}
        title={<b>Create Route</b>}
        maskClosable={false}
        footer={[
          <Button disabled = {confirmLoading}
          onClick={() => this.props.handleModalClose(false)}>Cancel</Button>,
          <Button type="primary"
            onClick={this.handleCreateRouteSubmit} loading={confirmLoading}>Create</Button>]}
        visible={isVisible}
        onCancel={() => this.props.handleModalClose(false)}
        destroyOnClose
      >
        <div className={classes.createRouteModalInfo}>
          {this.props.isAll ?
            <p>All {selectionType} are selected for optimisation</p> :
            <p>{selectedCount.length} {selectedCount} {selectionType} are selected
              for route optimisation</p>
          }
        </div>
        <Row>
            <Col span={12}>
              {this.renderEnableVehicleInput()}
            </Col>
            <Col span={12}>
              {this.renderEnableRosterRidersInput()}
            </Col>
            <Col span={12}>
              {this.renderEnableOnDutyRidersInput()}
            </Col>
            <Col span={12}>
              {this.renderEnableBothOnDutyRosterRidersInput()}
            </Col>
          </Row>
        {this.renderTimeSlots()}
        <div className={classes.defaultBody}>
          {timeSlots.length > 0 && (
            <div style={{padding: '5px'}}>
              <Row>
                <span>Time Slot</span>
              </Row>
              <Row>
                <Select
                  allowClear
                  className={classes.selectStyle}
                  placeholder="Select Time Slot"
                  onChange={this.onTimeSlotChange}
                  suffixIcon={<CalendarOutlined />}
                >
                  {generateTimeSlotsOptionsFromList(timeSlots)}
                </Select>
              </Row>
            </div>
          )}
          {showTripStartTimeForOptimisation && showTripStartTime && (
            <div style={{padding: '5px', display: 'flex', gap: '20px'}}>
                <span style={{ marginBottom: '3px', width: '145px' }}>Trip Start Time</span>
                <DatePicker
                  className={classes.selectStyle}
                  placeholder="Select Start Date"
                  showTime={{ defaultValue: moment('00:00:00', 'HH:mm:ss') }}
                  disabledDate={this.disabledDate}
                  onChange={(value) => this.setState({ tripStartTime: value })}
                  size="small"
                />
            </div>
          )}
        </div>
        {showTripStartTimeForOptimisation && showTripStartTime && enableVehicleInput && (
          <>
            <div className={classes.defaultBody}>
              <div style={{padding: '5px', display: 'flex', alignItems: 'center'}}>
                  <span style={{ marginBottom: '3px', width: '117px' }}>Trip Duration</span>
                  <InputNumber
                    size="middle"
                    min={0}
                    max={167}
                    step={1}
                    placeholder={'Hours'}
                    style={{ marginRight: '8px' }}
                    value={this.state.tripDurationHours}
                    onChange={(value) => this.setState({ tripDurationHours: value })}
                  />
                  <InputNumber
                    size="middle"
                    min={0}
                    max={59}
                    step={1}
                    placeholder={'Minutes'}
                    value={this.state.tripDurationMinutes}
                    onChange={(value) => this.setState({ tripDurationMinutes: value })}
                  />
              </div>
            </div>
            <div className={classes.defaultBody}>
              {
                this.shouldRenderInfoText(
                  this.state.tripDurationHours,
                  this.state.tripDurationMinutes,
                  this.state.tripStartTime,
                ) ? (
                  <p className={classes.infoText}>
                      Vehicle will be available for {this.state.tripDurationHours} Hours and {
                      this.state.tripDurationMinutes} minutes starting from {
                      this.getPaddedString(2, defaultStartTimeHours)} : {
                      this.getPaddedString(2, defaultStartTimeMinutes) } today.
                      If you wish to change, modify the duration and time slot start</p>
                ) : null
              }
            </div>
          </>
        )}
        <div>
          {this.renderVehicleMakeDetails()}
          {this.renderCustomFields()}
        </div>
        {this.renderFailureArray()}
        { isRescheduledCNConfirmationgModalVisible ?
          <ConfirmationModal
            title={'Confirmation'}
            dataArray={rescheduledCNArray}
            isVisible={isRescheduledCNConfirmationgModalVisible}
            onModalClose={this.handleRescheduledCNConfirmationgModalClose}
            showFooterSection={true}
            footerText={'Do you want to continue?'}
            primaryButtonText={'Yes'}
            onClickPrimaryButton={this.handleRescheduledCNConfirmation}
            secondaryButtonText={'No'}
            onClickSecondaryButton={this.handleRescheduledCNConfirmationgModalClose}
            >
          </ConfirmationModal> : null
        }
      </Modal>
    );
  }
}

const mapStateToProps = ({ masterData, cachedData }, ownProps) => {
  const { viewType } = ownProps;
  return {
    showTripStartTimeForOptimisation:
      masterData?.ops_dashboard_config?.parts_to_show?.etaConfig
        ?.input_start_time_for_optimisation || false,
    allowVehicleInputCreateRoute: masterData.ops_dashboard_config?.
    parts_to_show?.flm_trip_config?.allow_vehicle_input_create_route,
    rosterEnabled: masterData?.show_roster_management_ondemand || false,
    currHub: cachedData.currHub,
    opsDashboardTripCreationModalFields: masterData.ops_dashboard_trip_creation_modal_fields,
    opsFullTripColumnList: masterData.ops_full_trip_column_list || [],
  };
};

const CreateRouteModalStyled: any = withStyles(styles, { injectTheme: true })(CreateRouteModal);
const CreateRouteModalWithRouter = withRouter(CreateRouteModalStyled) as  React.ComponentType<any>;
export default connect(
  mapStateToProps,
)(CreateRouteModalWithRouter);
