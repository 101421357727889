import { CryptoUtils } from "src/utils/crypto-utils";

export enum LocalStorageKeys {
    _TABLE_COLUMS_WIDTH_DATA = '_TABLE_COLUMS_WIDTH_DATA',
    __ORGANISATION_ID__ = '__ORGANISATION_ID__',
    ___USER_ID__ = '___USER_ID__',
    __AUTH_TOKEN__ = '__AUTH_TOKEN__',
    __FAVICON_URL__ = '__FAVICON_URL__',
    __SHOW_SCHEDULED_MAINTAINENCE_BANNER__ = '__SHOW_SCHEDULED_MAINTAINENCE_BANNER__',
    __REDIRECT_TO_VIEW_TYPE__ = '__REDIRECT_TO_VIEW_TYPE__',
    __CURRENT_HUB__ = '__CURRENT_HUB__',
    __REDIRECT_TO_HUB__ = '__REDIRECT_TO_HUB__',
    __NAME__ = '__NAME__',
    __LOGO_URL__ = '__LOGO_URL__',
    __BOOKING_VISIBLE__ = '__BOOKING_VISIBLE__',
    __PERMISSION__ = '__PERMISSION__',
    __CURRENT_HUB_ID__ = '__CURRENT_HUB_ID__',
    __CURRENT_HUB_CODE__ = '__CURRENT_HUB_CODE__',
    __CURRENT_HUB_NAME__ = '__CURRENT_HUB_NAME__',
    __STATE__ = '__STATE__',
    __EMPLOYEE_PHONE__ = '__EMPLOYEE_PHONE__',
    __DEV_MODE__ = '__DEV_MODE__',
    __CURRENT_HUBS__ = '__CURRENT_HUBS__',
};

const KeyConfig = {
  [LocalStorageKeys.__ORGANISATION_ID__]: {
      encrypt: false,
  },
  [LocalStorageKeys.___USER_ID__]: {
      encrypt: true,
  },
  [LocalStorageKeys.__AUTH_TOKEN__]: {
      encrypt: true,
  },
};

export class LocalStorageService {
    static async set(key: string, value: string | null | undefined): Promise<void> {
      try {
        if (!value) return;
        let dataToSet = value;
        const encryptData: Boolean = KeyConfig[key]?.encrypt || false;
        if (encryptData) {
            dataToSet = await CryptoUtils.encryptAES(dataToSet);
        }

        localStorage.setItem(key.toString(), dataToSet);
      } catch (e) {
        console.log(e);
      }
    }

    static exists(key: string): boolean {
      return !!localStorage.getItem(key);
    }

    static getRaw(key: string): string | null {
      return localStorage.getItem(key);;
    }

    static async get(key: string): Promise<string | null> {
      try {
        const decryptData: Boolean = KeyConfig[key]?.encrypt || false;
        let dataToGet: string = localStorage.getItem(key);
        if (!dataToGet) return '';
        if (decryptData) {
            dataToGet = await CryptoUtils.decryptAES(dataToGet);
        }
        return dataToGet;
      } catch (e) {
        console.log(e.message);
        return null;
      }
    }

    static remove(key: string): void {
      localStorage.removeItem(key);
    }
}
