import * as React from 'react';
import withStyles from 'react-jss';
import { Form } from '@ant-design/compatible';
import '@ant-design/compatible/assets/index.css';
import { Modal, Input, Button, message } from 'antd';
import styles from './styles';
import { changeUserPassword } from '../../../../api/auth';
import { connect } from 'react-redux';
import { logoutUserAndClearCache } from 'src/api/utils';
const FormItem = Form.Item;

function ChangePasswordModal(props: any) {
  const layout:any = {
    labelCol: { span: 8 },
    wrapperCol: { span: 16 },
  };
  const tailLayout:any = {
    wrapperCol: { offset: 8, span: 16 },
  };

  const { classes, isModalActive, onModalClose, form, hideCloseButton, isFirstLogin, emailActivationOption, allowEscExit, disableMaskClosable } = props;
  const [isLoading, setIsLoading] = React.useState(false);
  const { getFieldDecorator, getFieldValue } =  form;

  const handleCancel = () => {
    onModalClose();
  };

  const HandleSubmit = async() => {
    const data = form.getFieldsValue();
    const valid = form.validateFields();
    if (data.confirm_password === data.new_password) {
      const payload = {};
      payload['old_password'] = data.old_password;
      payload['new_password'] = data.new_password;
      setIsLoading(true);
      const response = await changeUserPassword(payload);
      if (response.isSuccess) {
        setIsLoading(false);
        message.success('Password Updated Successfully, you will be logged out now');
        handleCancel();
        // Since the user has changed their password, their authentication tokens are already invalidated.  
        // Instead of calling the /logout API, we directly clear the session and redirect to the login page.  
        const isFromChangePassword = true;
        logoutUserAndClearCache(isFromChangePassword);
      } else {
        message.error(response.errorMessage);
        setIsLoading(false);
      }
    } else {
      message.warn('New password fields do not match!');
    }
  };

  return (
    <Modal
        title={ <h4 className={ classes.changePasswordModalHeading }>
          <b>{isFirstLogin && emailActivationOption ? "Set Password" : "Change Password"}</b></h4> }
        visible={ isModalActive }
        maskStyle={{ backgroundColor: '#FFFFFF' }}
        closable={hideCloseButton ? false : true}
        maskClosable={ (disableMaskClosable || hideCloseButton ) ? false : true }
        keyboard={ allowEscExit || false }
        onCancel={ handleCancel }
        className= { classes.changePasswordModal }
        footer={ null }
    >
        <Form
        {...layout}
        name="basic"
        >
        <div className={ classes.formError }>{
            getFieldValue('confirm_password') !== getFieldValue('new_password')
          ?
            'New password fields do not match!'
          :
            ''}
        </div>
        {isFirstLogin && emailActivationOption ?
          null
          :
          <FormItem label="Old Password">
            {getFieldDecorator('old_password', {
              rules: [{ required: true, message: 'Please enter old Password!' }],
            })(
              <Input.Password placeholder="Enter old password"/>,
            )}
          </FormItem>
        }
        <FormItem label={isFirstLogin && emailActivationOption ? "Set Password" : "New Password"}>
          {getFieldDecorator('new_password', {
            rules: [{ required: true, message: `Please enter ${isFirstLogin && emailActivationOption ? "" : "new" } Password!` }],
          })(
            <Input.Password placeholder={isFirstLogin && emailActivationOption ? "Enter a password" : "Enter new password"} />,
          )}
        </FormItem>
        <FormItem label="Confirm Password">
          { getFieldDecorator('confirm_password', {
            rules: [{ required: true, message: `Please confirm ${isFirstLogin && emailActivationOption ? "" : "new" } Password!` }],
          })(
            <Input.Password placeholder={isFirstLogin && emailActivationOption ? "Confirm password" : "Confirm new password"} />,
          )}
        </FormItem>
        <FormItem { ...tailLayout }>
          <Button
              type="primary"
              className={ classes.updatePassBtn }
              onClick={ HandleSubmit }
              loading={ isLoading }
              disabled={ isLoading || (!(isFirstLogin && emailActivationOption) && !getFieldValue('old_password')) || !getFieldValue('new_password') || !getFieldValue('confirm_password')}
              >
              Submit
          </Button>
        </FormItem>
      </Form>
    </Modal>
  );
}

const mapStateToProps = (state) => {
  const { masterData } = state;
  return {
    emailActivationOption: masterData.employee_email_activation_option,
    isFirstLogin: masterData.user_data?.is_first_login,
  };
};

export default connect(mapStateToProps)(withStyles(styles,
  { injectTheme: true })(Form.create()(ChangePasswordModal) as React.ComponentType<any>));
