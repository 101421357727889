import {
  printLabelScanClick,
  printLabelUploadClick,
  cnVerificationUploadClick,
  setGenericScanSelectModalVisible,
  setGenericBulkUploadModalVisible,
  setActionType,
  createRouteAllClick,
  createRouteFilteredClick,
  downloadChildCnClick,
  customTripClick,
  customTripUploadClick,
  customBulkTripUploadClick,
  switchModalVisibility,
} from './CustomActionBarActions';
import {
  bulkCNLabelPrint,
  pickedUpInscanAtHub,
  inscanAtHub,
  arrivedInCountry,
  ptlConsignmentInscan,
  markArrival,
  markInternalHandover,
  outscanAtHub,
  markDelivered,
  markConsignmentAsRTO,
  markPickupAndDelivery,
  revokePickupAndDelivery,
  setOnhold,
  pendingCustom,
  shipsymentAsClear,
  releaseOnhold,
  markNpr,
  consignmentStatusUpdate,
  printManifestDRS,
  printPOD,
  arrivedAtReturnHub,
  revokeDeliveryCn,
  fulfilmentHandover,
} from '../../../../api/genericConsignmentView';

import {
  markConsignmentLost,
} from '@src/api/dashboard';
import {
  getPrintLabelRequestsRoute,
  getAutoAllocateRequestsPageRoute,
  getCustomTripRequestsRoute,
} from '../../../../routing/utils';
import {
  getTMSCreateBagRoute,
  getTMSAssignThirdPartyBagRoute,
  getTMSDeBagRoute,
} from '../../TMSDashboard/routes';
import {
  markCancel,
  releaseExceptionOnConsignments,
  applyExceptionOnConsignments,
  markConsignmentAsPickupCompleted,
  markCnEligibleForLmPlanning,
  downloadSampleExcelForActions,
  updateConsignmentDestinationHub,
} from '@api/dashboard';

import { bulkManualTrip } from '@api/trips';

const createRouteChildren = [
  {
    id: 'all',
    label: 'All',
    onClick: createRouteAllClick(),
  },
  {
    id: 'upload',
    label: 'Upload',
    onClick: switchModalVisibility('bulkCreateRouteModal', true),
  },
  {
    id: 'selected',
    label: 'Selected',
    onClick: switchModalVisibility('createRouteModal', true),
    requireRows: true,
  },
  {
    id: 'all_filtered',
    label: 'All Filtered',
    onClick: createRouteFilteredClick(),
  },
  {
    id: 'view_requests',
    label: 'View Requests',
    type: 'route',
    route: getAutoAllocateRequestsPageRoute,
  },
];
const defaultSuccessMessage = 'Selected consignments have been';
export const defaultSampleLink =
  'https://shipsy-public-assets.s3-us-west-2.amazonaws.com/shipsy/' +
  'sample-downloads/ops/bulk_scan_sample.xlsx';
export const sampleLinkWithNotes =
  'https://shipsy-public-assets.s3-us-west-2.amazonaws.com/shipsy/' +
  'sample-downloads/ops/bulk_update_with_notes.xlsx';

const genericBulkScanActions = (
  title,
  parentId,
  status = '',
  actionId?: any,
) => {
  return {
    id: parentId + '_selected',
    label: 'Selected',
    onClick: setGenericScanSelectModalVisible(actionId),
    title,
    successMessage: defaultSuccessMessage + status,
  };
};
const genericBulkUploadActions = (
  title,
  parentId,
  status = '',
  actionId?: any,
) => {
  return {
    id: parentId + '_bulk_upload',
    label: 'Bulk Upload',
    onClick: setGenericBulkUploadModalVisible(actionId),
    title,
    successMessage: defaultSuccessMessage + status,
  };
};

export const actionsConfig = [
  {
    id: 'addCN',
    label: 'Add Consignments',
    onClick: switchModalVisibility('bulkUploadModal', true),
  },
  {
    id: 'updateCN',
    label: 'Update Consignments',
    children: [
      {
        id: 'consignment',
        label: 'Consignment',
        onClick: switchModalVisibility('bulkUpdateModal', true),
      },
      {
        id: 'pieces',
        label: 'Pieces',
        onClick: switchModalVisibility('bulkUpdatePiecesModal', true),
      },
    ],
  },
  {
    id: 'handlingUnits',
    label: 'Handling Units',
    children: [
      {
        id: 'add',
        label: 'Add',
        onClick: switchModalVisibility('addHandlingUnit', true),
      },
      {
        id: 'update',
        label: 'Update',
        onClick: switchModalVisibility('updateHandlingUnit', true),
      },
      {
        id: 'delete',
        label: 'Delete',
        onClick: switchModalVisibility('deleteHandlingUnit', true),
      },
    ],
  },
  {
    id: 'cn_label',
    label: 'Print Label',
    children: [
      {
        ...genericBulkScanActions('Print Label', 'cn_label'),
        onClick: printLabelScanClick('THIRD_PARTY_CONSIGNMENT_LABEL'),
        actionApiCall: bulkCNLabelPrint,
        successMessage:
          'Print consignment label request generated successfully',
        routeOnSuccess: getPrintLabelRequestsRoute(),
      },
      {
        ...genericBulkUploadActions('Print Label', 'cn_label'),
        onClick: printLabelUploadClick('THIRD_PARTY_CONSIGNMENT_LABEL'),
        actionApiCall: bulkCNLabelPrint,
        successMessage:
          'Print consignment label request generated successfully',
        routeOnSuccess: getPrintLabelRequestsRoute(),
        sampleLink:
          'https://shipsy-public-assets.s3.us-west-2.amazonaws.com/shipper-dashboard' +
          '/sample_consignment_list.xlsx',
      },
    ],
  },
  {
    id: 'credit_note',
    label: 'Print Credit Note',
    children: [
      {
        ...genericBulkScanActions('Credit Note', 'credit_note'),
        onClick: printLabelScanClick('BULK_CREDIT_NOTE'),
        actionApiCall: bulkCNLabelPrint,
        successMessage: 'Print credit note request generated successfully',
        routeOnSuccess: getPrintLabelRequestsRoute(),
      },
      {
        ...genericBulkUploadActions('Credit Note', 'credit_note'),
        onClick: printLabelUploadClick('BULK_CREDIT_NOTE'),
        actionApiCall: bulkCNLabelPrint,
        successMessage: 'Print credit note request generated successfully',
        routeOnSuccess: getPrintLabelRequestsRoute(),
        sampleLink:
          'https://shipsy-public-assets.s3.us-west-2.amazonaws.com/shipper-dashboard' +
          '/sample_consignment_list.xlsx',
      },
    ],
  },
  {
    id: 'inventory_inscan',
    label: 'Inventory Inscan',
    onClick: switchModalVisibility('inventoryInscanModal', true),
  },
  {
    id: 'qc_check',
    label: 'CN Verification',
    children: [
      {
        ...genericBulkScanActions('CN Verification', 'qc_check'),
        onClick: switchModalVisibility('qcCheckModal', true),
      },
      {
        ...genericBulkUploadActions('CN Verification', 'qc_check'),
        onClick: cnVerificationUploadClick(),
      },
    ],
  },
  {
    id: 'mark_cn_internal_handover',
    label: 'Internal Handover',
    children: [
      {
        ...genericBulkScanActions('Internal Handover', 'mark_cn_internal_handover', '', 'mark_cn_internal_handover'),
        actionApiCall: markInternalHandover,
        successMessage: 'Successfully marked internal handover',
        routeOnSuccess: getPrintLabelRequestsRoute(),
      },
      {
        ...genericBulkUploadActions('Internal Handover', 'mark_cn_internal_handover', '', 'mark_cn_internal_handover'),
        actionApiCall: markInternalHandover,
        sampleLink: defaultSampleLink,
        successMessage: 'Successfully marked internal handover',
        routeOnSuccess: getPrintLabelRequestsRoute(),
      },
    ],
  },
  {
    ...genericBulkScanActions('Unassign Consignments', 'unassign'),
    id: 'unassign',
    label: 'Unassign',
    onOkClick: switchModalVisibility('bulkDeleteCNModal', true),
  },
  {
    ...genericBulkScanActions(
      'Inscan at Hub',
      'pickedup_consignment_inscan',
      ' marked inscan at hub',
    ),
    id: 'pickedup_consignment_inscan',
    label: 'Inscan Picked Up CNs',
    actionApiCall: pickedUpInscanAtHub,
  },
  {
    id: 'arrival',
    label: 'Arrival',
    children: [
      {
        ...genericBulkScanActions('Arrival', 'arrival', ' added to hub'),
        actionApiCall: markArrival,
      },
      {
        ...genericBulkUploadActions('Arrival', 'arrival', ' added to hub'),
        actionApiCall: markArrival,
        sampleLink: defaultSampleLink,
      },
    ],
  },
  {
    id: 'outscan',
    label: 'OutScan',
    children: [
      {
        ...genericBulkScanActions('', 'outscan'),
        onClick: switchModalVisibility('cnOutscanModal', true),
      },
      {
        ...genericBulkUploadActions('OutScan at hub', 'outscan', ' outscaned'),
        actionApiCall: outscanAtHub,
        sampleLink: defaultSampleLink,
      },
    ],
  },
  {
    id: 'inscan',
    label: 'Inscan at hub',
    children: [
      {
        ...genericBulkScanActions(
          'Inscan at Hub',
          'inscan',
          ' marked inscan at hub',
        ),
        actionApiCall: inscanAtHub,
      },
      {
        ...genericBulkUploadActions(
          'Inscan at hub',
          'inscan',
          ' marked inscan at hub',
          'inscan_at_hub',
        ),
        showNotes: true,
        actionApiCall: inscanAtHub,
      },
    ],
  },
  {
    id: 'arrived_in_country',
    label: 'Arrived In Country',
    children: [
      {
        ...genericBulkScanActions(
          'Mark Arrived In Country',
          'arrived_in_country',
          ' marked arrived in country',
        ),
        actionApiCall: arrivedInCountry,
      },
      {
        ...genericBulkUploadActions(
          'Mark Arrived In Country',
          'arrived_in_country',
          ' marked arrived in country',
          'arrived_in_country',
        ),
        actionApiCall: arrivedInCountry,
        sampleLink: defaultSampleLink,
      },
    ],
  },
  {
    id: 'arrived_at_return_hub',
    label: 'Arrived At Return Hub',
    children: [
      {
        ...genericBulkScanActions(
          'Mark Arrived At Return Hub',
          'arrived_at_return_hub',
          ' marked arrived at return hub',
        ),
        actionApiCall: arrivedAtReturnHub,
      },
      {
        ...genericBulkUploadActions(
          'Mark Arrived At Return Hub',
          'arrived_at_return_hub',
          ' marked arrived at return hub',
          'arrived_at_return_hub',
        ),
        actionApiCall: arrivedAtReturnHub,
        sampleLink: defaultSampleLink,
      },
    ],
  },
  {
    id: 'createRoute',
    label: 'Create Route',
    onClick: setActionType('create_route'),
    children: createRouteChildren,
  },
  {
    id: 'addToTrip',
    label: 'Add to Trip',
    onClick: setActionType('add_to_existing'),
    children: createRouteChildren,
  },
  {
    id: 'mark_delivered',
    label: 'Mark Delivered',
    children: [
      {
        ...genericBulkScanActions(
          'Mark Delivered',
          'mark_delivered',
          ' marked delivered',
        ),
        actionApiCall: markDelivered,
        showPodModal: true,
      },
      {
        ...genericBulkUploadActions(
          'Mark Delivered',
          'mark_delivered',
          ' marked delivered',
        ),
        actionApiCall: markDelivered,
        useSampleApiForSampleFile: true,
      },
    ],
  },
  {
    id: 'mark_rto',
    label: 'Mark RTO',
    children: [
      {
        ...genericBulkScanActions(
          'Mark RTO',
          'mark_rto',
          ' marked as RTO',
          'rto',
        ),
        showNotes: true,
        actionApiCall: markConsignmentAsRTO,
      },
      {
        ...genericBulkUploadActions(
          'Mark RTO',
          'mark_rto',
          ' marked as RTO',
          'rto',
        ),
        showNotes: true,
        actionApiCall: markConsignmentAsRTO,
      },
    ],
  },
  {
    id: 'pickup_and_delivery',
    label: 'Mark Hyperlocal',
    children: [
      {
        ...genericBulkScanActions(
          'Mark Hyperlocal',
          'pickup_and_delivery',
          ' marked as Hyperlocal',
          'pickup_and_delivery',
        ),
        showNotes: true,
        actionApiCall: markPickupAndDelivery,
      },
      {
        ...genericBulkUploadActions(
          'Mark Hyperlocal',
          'pickup_and_delivery',
          ' marked as Hyperlocal',
          'pickup_and_delivery',
        ),
        showNotes: true,
        actionApiCall: markPickupAndDelivery,
      },
    ],
  },
  {
    id: 'revoke_pickup_and_delivery',
    label: 'Revoke Hyperlocal',
    children: [
      {
        ...genericBulkScanActions(
          'Revoke Hyperlocal',
          'revoke_pickup_and_delivery',
          ' Revoked as Hyperlocal',
          'revoke_pickup_and_delivery',
        ),
        showNotes: true,
        actionApiCall: revokePickupAndDelivery,
      },
      {
        ...genericBulkUploadActions(
          'Revoke Hyperlocal',
          'revoke_pickup_and_delivery',
          ' Revoked as Hyperlocal',
          'revoke_pickup_and_delivery',
        ),
        showNotes: true,
        actionApiCall: revokePickupAndDelivery,
      },
    ],
  },
  {
    id: 'mark_onhold',
    label: 'Mark OnHold',
    children: [
      {
        ...genericBulkScanActions(
          'Mark OnHold',
          'mark_onhold',
          ' marked as Onhold',
          'mark_on_hold',
        ),
        showNotes: true,
        actionApiCall: setOnhold,
      },
      {
        ...genericBulkUploadActions(
          'Mark OnHold',
          'mark_onhold',
          ' marked as Onhold',
          'mark_on_hold',
        ),
        showNotes: true,
        actionApiCall: setOnhold,
      },
    ],
  },
  {
    id: 'pending_custom',
    label: 'Pending Custom',
    children: [
      {
        ...genericBulkScanActions(
          'Pending Custom',
          'pending_custom',
          ' pending custom',
          'pending_custom',
        ),
        showNotes: true,
        actionApiCall: consignmentStatusUpdate,
      },
      {
        ...genericBulkUploadActions(
          'Pending Custom',
          'pending_custom',
          ' pending custom',
          'pending_custom',
        ),
        showNotes: true,
        actionApiCall: consignmentStatusUpdate,
      },
    ],
  },
  {
    id: 'shipment_clear_successfully',
    label: 'Shipment Clear Successfully',
    children: [
      {
        ...genericBulkScanActions(
          'Shipment Clear Successfully',
          'shipment_clear_successfully',
          ' shipment clear successfully',
          'shipment_clear_successfully',
        ),
        showNotes: true,
        actionApiCall: consignmentStatusUpdate,
      },
      {
        ...genericBulkUploadActions(
          'Shipment Clear Successfully',
          'shipment_clear_successfully',
          ' shipment clear successfully',
          'shipment_clear_successfully',
        ),
        showNotes: true,
        actionApiCall: consignmentStatusUpdate,
      },
    ],
  },
  {
    id: 'release_onhold',
    label: 'Release OnHold',
    children: [
      {
        ...genericBulkScanActions(
          'Release OnHold',
          'release_onhold',
          ' released from onhold',
          'release_on_hold',
        ),
        showNotes: true,
        actionApiCall: releaseOnhold,
      },
      {
        ...genericBulkUploadActions(
          'Release OnHold',
          'release_onhold',
          ' released from onhold',
          'release_on_hold',
        ),
        showNotes: true,
        actionApiCall: releaseOnhold,
      },
    ],
  },
  {
    id: 'print_manifest_drs',
    label: 'Print Manifest',
    children: [
      {
        ...genericBulkScanActions('Print Manifest Drs', 'print_manifest_drs'),
        actionApiCall: printManifestDRS,
        successMessage: 'Print manifest drs generated successfully',
        downloadData: true,
      },
    ],
  },
  {
    id: 'print_pod',
    label: 'Print POD',
    children: [
      {
        ...genericBulkScanActions('Print POD', 'print_pod', '', 'print_pod'),
        actionApiCall: printPOD,
        successMessage: 'Print POD generated successfully',
        downloadData: true,
      },
    ],
  },
  {
    id: 'consignment_sieze',
    label: 'Consignment Sieze',
    children: [
      {
        ...genericBulkScanActions(
          'Consignment Sieze',
          'consignment_sieze',
          ' marked as Seized',
          'seized',
        ),
        showNotes: true,
        actionApiCall: consignmentStatusUpdate,
      },
      {
        ...genericBulkUploadActions(
          'Consignment Sieze',
          'consignment_sieze',
          ' marked as Seized',
          'seized',
        ),
        showNotes: true,
        actionApiCall: consignmentStatusUpdate,
      },
    ],
  },
  {
    id: 'mark_clearance_origin',
    label: 'Mark Clearance at Origin',
    children: [
      {
        ...genericBulkScanActions(
          'Mark Clearance at Origin',
          'mark_clearance_origin',
          ' marked cleared at origin',
          'mark_clearance_at_origin',
        ),
        showNotes: true,
        actionApiCall: consignmentStatusUpdate,
      },
      {
        ...genericBulkUploadActions(
          'Mark Clearance at Origin',
          'mark_clearance_origin',
          ' marked cleared at origin',
          'mark_clearance_at_origin',
        ),
        showNotes: true,
        actionApiCall: consignmentStatusUpdate,
      },
    ],
  },
  {
    id: 'mark_clearance_destination',
    label: 'Mark Clearance at Destination',
    children: [
      {
        ...genericBulkScanActions(
          'Mark Clearance at Destination',
          'mark_clearance_destination',
          ' marked cleared at destination',
          'mark_clearance_at_destination',
        ),
        showNotes: true,
        actionApiCall: consignmentStatusUpdate,
      },
      {
        ...genericBulkUploadActions(
          'Mark Clearance at Destination',
          'mark_clearance_destination',
          ' marked cleared at destination',
          'mark_clearance_at_destination',
        ),
        showNotes: true,
        actionApiCall: consignmentStatusUpdate,
      },
    ],
  },
  {
    id: 'inscan_at_gateway',
    label: 'Inscan at Gateway',
    children: [
      {
        ...genericBulkScanActions(
          'Inscan at Gateway',
          'inscan_at_gateway',
          ' marked inscan at gateway',
          'inscan_at_gateway',
        ),
        showNotes: true,
        actionApiCall: consignmentStatusUpdate,
      },
      {
        ...genericBulkUploadActions(
          'Inscan at Gateway',
          'inscan_at_gateway',
          ' marked inscan at gateway',
          'inscan_at_gateway',
        ),
        showNotes: true,
        actionApiCall: consignmentStatusUpdate,
      },
    ],
  },
  {
    id: 'mark_npr',
    label: 'Mark NPR',
    children: [
      {
        ...genericBulkScanActions('Mark NPR', 'mark_npr', ' marked as NPR'),
        actionApiCall: markNpr,
        requireReason: true,
      },
      {
        ...genericBulkUploadActions('Mark NPR', 'mark_npr', ' marked as NPR'),
        actionApiCall: markNpr,
        sampleLink: defaultSampleLink,
        requireReason: true,
      },
    ],
  },
  {
    id: 'cancel',
    label: 'Cancel Consignments',
    children: [
      {
        ...genericBulkScanActions(
          'Cancel',
          'cancel',
          ' cancelled successfully',
        ),
        requireReason: true,
        actionApiCall: markCancel,
      },
      {
        ...genericBulkUploadActions(
          'Cancel',
          'cancel',
          ' cancelled successfully',
        ),
        requireReason: true,
        actionApiCall: markCancel,
        sampleLink: defaultSampleLink,
      },
    ],
  },
  {
    id: 'cn_copy_label',
    label: 'Print Consignment Copy Label',
    onClick: printLabelScanClick('CONSIGNMENT_COPY'),
    title: 'Print Label',
    actionApiCall: bulkCNLabelPrint,
    successMessage: 'Print consignment label request generated successfully',
    routeOnSuccess: getPrintLabelRequestsRoute(),
  },
  {
    id: 'download',
    label: 'Download',
    onClick: switchModalVisibility('downloadsModal', true),
  },
  {
    id: 'downloadHUs',
    label: 'Download Child CN',
    onClick: downloadChildCnClick(),
  },
  {
    ...genericBulkScanActions('Move to Unplanned', 'moveFailedCns'),
    id: 'moveFailedCns',
    label: 'Move to Unplanned',
    onOkClick: switchModalVisibility('moveCNModal', true),
  },
  {
    ...genericBulkScanActions(
      'Inscan at Hub',
      'ptl_consignment_inscan',
      ' marked inscan at hub',
    ),
    id: 'ptl_consignment_inscan',
    label: 'PTL Consignment Inscan',
    actionApiCall: ptlConsignmentInscan,
  },
  {
    id: 'create_bag',
    label: 'Create Bag',
    type: 'route',
    route: getTMSCreateBagRoute,
  },
  {
    id: 'assign_bag',
    label: 'Assign Third Party',
    type: 'route',
    route: getTMSAssignThirdPartyBagRoute,
  },
  {
    id: 'de_bag',
    label: 'De-Bag',
    type: 'route',
    route: getTMSDeBagRoute,
  },
  {
    id: 'createCustomTrip',
    label: 'Custom Trip',
    onClick: setActionType('create_custom_trip'),
    children: [
      {
        id: 'all',
        label: 'All',
        onClick: customTripClick('all'),
      },
      {
        id: 'filtered',
        label: 'All Filtered',
        onClick: customTripClick('filtered'),
      },
      {
        id: 'selected',
        label: 'Scan/Selected',
        onClick: customTripClick('selected'),
      },
      {
        id: 'upload',
        label: 'Upload',
        onClick: customTripUploadClick('upload'),
      },
      {
        id: 'view_requests',
        label: 'View Requests',
        type: 'route',
        route: getCustomTripRequestsRoute,
      },
      {
        id: 'bulk_trip',
        label: 'Bulk Trip',
        onClick: customBulkTripUploadClick('bulk'),
        requireOption: true,
        actionApiCall: bulkManualTrip,
      },
    ],
  },
  {
    id: 'mark_cn_eligible_for_lm_planning',
    label: 'Mark Consignment Eligibility for LM Planning',
    children: [
      {
        ...genericBulkUploadActions(
          'Mark Consignment Eligibility for LM Planning',
          'mark_cn_eligible_for_lm_planning',
          ' marked as according to eligiblity status for last mile planning',
          'mark_cn_eligible_for_lm_planning',
        ),
        actionApiCall: markCnEligibleForLmPlanning,
        useSampleApiForSampleFile: true,
      },
    ],
  },
  {
    id: 'apply_exception',
    label: 'Apply Exception',
    children: [
      {
        ...genericBulkScanActions(
          'Apply Exception',
          'apply_exception',
          ' marked as Exception',
          'apply_exception',
        ),
        actionApiCall: applyExceptionOnConsignments,
        showExceptionCodeWithNotes: true,
        showNotes: true,
      },
      {
        ...genericBulkUploadActions(
          'Apply Exception',
          'apply_exception',
          ' marked as Exception',
          'apply_exception',
        ),
        actionApiCall: applyExceptionOnConsignments,
        showExceptionCodeWithNotes: true,
        sampleLink:
          'https://shipsy-public-assets.s3-us-west-2.amazonaws.com/shipsy/' +
          'sample-downloads/ops/bulk_scan_sample_with_exception_code.xlsx',
      },
    ],
  },
  {
    id: 'release_exception',
    label: 'Release Exception',
    children: [
      {
        ...genericBulkScanActions(
          'Release Exception',
          'release_exception',
          ' marked as Exception Released',
          'release_exception',
        ),
        actionApiCall: releaseExceptionOnConsignments,
        showNotes: true,
      },
      {
        ...genericBulkUploadActions(
          'Release Exception',
          'release_exception',
          ' marked as Exception Released',
          'release_exception',
        ),
        actionApiCall: releaseExceptionOnConsignments,
        showExceptionCodeWithNotes: true,
        sampleLink:
          'https://shipsy-public-assets.s3-us-west-2.amazonaws.com/shipsy/' +
          'sample-downloads/ops/bulk_scan_sample_with_exception_code.xlsx',
      },
    ],
  },
  {
    id: 'pickup_completed',
    label: 'Pickup Completed',
    children: [
      {
        ...genericBulkScanActions(
          'Pickup Completed',
          'pickup_completed',
          ' marked a pickup completed',
        ),
        actionApiCall: markConsignmentAsPickupCompleted,
      },
      {
        ...genericBulkUploadActions(
          'Pickup Completed',
          'pickup_completed',
          ' marked a pickup completed',
        ),
        actionApiCall: markConsignmentAsPickupCompleted,
        sampleLink: defaultSampleLink,
      },
    ],
  },
  {
    id: 'set_rto',
    label: 'Set RTO',
    children: [
      {
        ...genericBulkScanActions('Set RTO', 'set_rto', 'Set RTO'),
        actionApiCall: markConsignmentAsRTO,
        showNotes: true,
      },
      {
        ...genericBulkUploadActions('Set RTO', 'set_rto', 'Set RTO'),
        actionApiCall: markConsignmentAsRTO,
        sampleFileDownloadUrl: downloadSampleExcelForActions.bind(null, 'set_rto'),
        showNotes: true,
        useSampleApiForSampleFile: true,
      },
    ],
  },
  {
    id: 'revoke_delivery',
    label: 'Revoke Delivery',
    children: [
      {
        ...genericBulkScanActions('Revoke Delivery', 'revoke_delivery', 'Revoked Delivery'),
        actionApiCall: revokeDeliveryCn,
        requireReason: true,
        reasonFreeText: true,
      },
      {
        ...genericBulkUploadActions('Revoke Delivery', 'revoke_delivery', 'Revoked Delivery'),
        actionApiCall: revokeDeliveryCn,
        sampleFileDownloadUrl: downloadSampleExcelForActions.bind(null, 'revoke_delivery'),
        showNotes: true,
        useSampleApiForSampleFile: true,
      },
    ],
  },
  {
    id: 'mark_lost',
    label: 'Mark Lost',
    children: [
      {
        ...genericBulkScanActions('Mark Lost', 'mark_lost', 'Marked Lost'),
        actionApiCall: markConsignmentLost,
        requireReason: true,
        reasonFreeText: true,
      },
      {
        ...genericBulkUploadActions('Mark Lost', 'mark_lost', 'Marked Lost'),
        actionApiCall: markConsignmentLost,
        sampleFileDownloadUrl: downloadSampleExcelForActions.bind(null, 'mark_lost'),
        showNotes: true,
        useSampleApiForSampleFile: true,
      },
    ],
  },
  {
    ...genericBulkScanActions(
      'Fulfilment Handover ',
      'fulfilment_handover',
      ' marked fulfllment handover',
    ),
    id: 'fulfilment_handover',
    label: 'Fulfilment Handover',
    actionApiCall: fulfilmentHandover,
  },
  {
    id: 'scan_print_label',
    label: 'Scan & Print Label',
    onClick: switchModalVisibility('scanAndPrintLabelModal', true),
  },
  {
    id: 'update_consignment_destination_hub',
    label: 'Change Destination Hub',
    children: [
      {
        ...genericBulkScanActions('Change Destination Hub', 'update_consignment_destination_hub', 'Change Destination Hub'),
        actionApiCall: updateConsignmentDestinationHub,
        showNotes: true,
        showHubSearch: true,
        useGenericBulkUploaderService: true,
      },
      {
        ...genericBulkUploadActions('Change Destination Hub', 'update_consignment_destination_hub', 'Change Destination Hub'),
        actionApiCall: updateConsignmentDestinationHub,
        showNotes: true,
        useSampleApiForSampleFile: true,
        useGenericBulkUploaderService: true,
      },
    ],
  },
];
