import { combineReducers } from 'redux';
import { INITIALIZE_COMPLETE, INITIALIZE_RESET } from '../actions/constants';
import navigation from './navigationReducer';
import masterData from './masterDataReducer';
import retailDashboard from './retailDashboardReducer';
import tripDashboard from './tripReducer';
import tripTracking from './tripTrackingReducer';
import cachedData from './cachedData';
import ltlTrackingReducer from './ltlTrackingReducer';
import carrierManagement  from './carrierManagementReducer';
import customerFeedbackReducer  from './customerFeedbackReducer';
import customerActivityShipmentViewReducer from './customerActivityShipmentViewReducer';
import invoice from './invoiceReducer';
import genericConsignmentReducer from './genericConsignmentReducer';
import FoodDashboardReducer from './FoodDashboardReducer';
import genericAnalyticsReducer from './genericAnalyticsReducer';
import generateReportsReducer from './generateReportsReducer';
import persistentIframe from './persistentIframeReducer';
import opsDashboardStateReducer from './opsDashboardStateReducer';
import opsDashboardManage from './opsDashboardManageReducer';
import riderTransactionHistory from './riderTransactionHistoryReducer';
import TMSDashboardReducer from './TMSDashboardReducer';
import TMSOverviewReducer from './TMSOverviewReducer';
import integrationMarketplace from './integrationMarketplace';
import genericPageReducer from './genericPageReducer';
import landingPageDashboardGroup from './landingPageDashboardGroupReducer';
import uiTheme from './themeReducer';
import reasonsDataReducer from './reasonsDataReducer';
import notificationReducer from './notificationReducer';
import liveDashboardReducer from '../components/pages/retail/dashboard/Live/LiveDashboard.reducer';
import valletTransactionsReducer from './ValletTransactionRoutes';
import rosterManagementReducer from './RosterManagementReducer';
import jsonArrayTableViewReducer from './JsonArrayTableViewReducer';

const initializing = function (state = true, action) {
 switch (action.type) {
   case INITIALIZE_COMPLETE:
     return false;
   case INITIALIZE_RESET:
     return true;


   default:
     return state;
 }
};


export default combineReducers({
  uiTheme,
  initializing,
  navigation,
  masterData,
  cachedData,
  retailDashboard,
  tripDashboard,
  tripTracking,
  ltlTrackingReducer,
  carrierManagement,
  customerFeedbackReducer,
  customerActivityShipmentViewReducer,
  invoice,
  genericConsignmentReducer,
  FoodDashboardReducer,
  genericAnalyticsReducer,
  generateReportsReducer,
  persistentIframe,
  opsDashboardManage,
  riderTransactionHistory,
  TMSDashboardReducer,
  TMSOverviewReducer,
  opsDashboardStateReducer,
  integrationMarketplace,
  genericPageReducer,
  landingPageDashboardGroup,
  notificationReducer,
  liveDashboardReducer,
  reasonsDataReducer,
  valletTransactionsReducer,
  rosterManagementReducer,
  jsonArrayTableViewReducer,
});
