import { Carousel, Modal } from 'antd';
import React from 'react';
import RenderImage from './RenderImage';

const PodImagePreviewModal = (props: any) => {
  const { isVisible, handleClose, imageList } = props;

  const [modalWidth, setModalWidth] = React.useState(800);

  React.useEffect(() => {
    if (imageList?.length > 0) {
      const img = new Image();
      img.src = imageList[0].url;
      img.onload = () => {
        setModalWidth(Math.min(img.naturalWidth, 800));
      };
    }
  }, [imageList]);

  const styles = {
    dotStyle: `
      .custom-dots {
        position: absolute;
        bottom: -30px !important;
        left: -16% !important;
        display: flex;
        justify-content: center;
        width: 100%;
      }
      .custom-dots li button {
        background-color: #333 !important;
      }
      .custom-dots li.slick-active button {
        background-color: #333 !important;
      }
    `,
    carouselWrapper: {
      position: 'relative' as const, // To ensure proper positioning of dots
      padding: '40px 0', // Add space for dots below the carousel
    },
  };

  return (
    <>
      {/* Inline Style Tag for Custom CSS for corousel */}
      <style>{styles.dotStyle}</style>
      <Modal
        visible={isVisible}
        footer={null}
        onCancel={handleClose}
        width={modalWidth}
      >
        <div style={styles.carouselWrapper}>
          <Carousel dots={{ className: 'custom-dots' }}>
            {imageList.map((image: any) => (
              <div style={{ background: '#CCCCCC' }}>
                <RenderImage image={image} />
              </div>
            ))}
          </Carousel>
        </div>
      </Modal>
    </>
  );
};

export default PodImagePreviewModal;
