import { API_BASE_URL } from '@src/globals';
import { POST, GET } from '@api/apiHandler';
import {
    GET_WORFLOW_BUILDER_METADATA,
    SET_WORFLOW_BUILDER_METADATA,
    GENERATE_OBJECT_BUILDER_EXCEL,
    GET_CHARGES_DATA
} from '@api/routes';
import { getAuthenticationHeaders } from './utils';

export const getWorkFlowBuilderMetaData = (params) => {
    return GET(`${API_BASE_URL}${GET_WORFLOW_BUILDER_METADATA}`, params);
};

export const updateWorkFlowBuilderMetaData = (body) => {
    return POST(`${API_BASE_URL}${SET_WORFLOW_BUILDER_METADATA}`, body);
};

export const generateSampleExcel = async (body) => {
    return POST(`${API_BASE_URL}${GENERATE_OBJECT_BUILDER_EXCEL}`,body, await getAuthenticationHeaders() , true);
};

export const getAdhocCharges = (params) => {
    return GET(`${API_BASE_URL}${GET_CHARGES_DATA}?chargeType=${params}`);
};