import React from "react";
import { message } from "antd";
import GenericUploadStatusBar from "./GenericUploadStatusBar";
import ReactDOM from "react-dom";

const beforeUnload = (e) => {
    e.preventDefault();
    return '';
}

const uploadData = (config: {
    heading: string,
    data: any[],
    apiCallFn: (requestData: any) => Promise<any>,
    getRequestBody: (batchData: any[]) => any;
    failureArrayKeysToShow: string[];
    isSingleApi?: boolean;
    onAllBatchesComplete?: () => Promise<any>;
    dataHeaders: any;
    concurrencyLimit?: number;
}) => {
    const containerElement = document.getElementById('generic-bulk-upload-service-container');
    if (containerElement.children.length === 0) {
        const filteredData = config.data.filter((item) => Object.keys(item).length > 0);
        if (config.data?.length) {
            ReactDOM.render(
                <GenericUploadStatusBar
                    heading={config.heading}
                    apiCallFn={config.apiCallFn}
                    data={filteredData}
                    getRequestBody={config.getRequestBody}
                    onCompletion={() => {
                        window.removeEventListener('beforeunload', beforeUnload);
                        ReactDOM.unmountComponentAtNode(containerElement);
                    }}
                    failureArrayKeysToShow={config.failureArrayKeysToShow}
                    isSingleApi={config.isSingleApi}
                    onAllBatchesComplete={config.onAllBatchesComplete}
                    dataHeaders={config.dataHeaders}
                    concurrencyLimit={config.concurrencyLimit}
                />,
                containerElement,
            );

            window.addEventListener('beforeunload', beforeUnload);

        } else {
            throw new Error('No data to upload.');
        }
    } else {
        throw new Error('An upload is already in progress, please wait for it to complete and close the dialog before starting a new one.');
    }
};

export const GenericBulkUploadService = {
  uploadData,
};
