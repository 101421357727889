import * as React from 'react';
import { withRouter } from 'react-router';
import withStyles, { WithStylesProps } from 'react-jss';
import { connect } from 'react-redux';
import { CloseOutlined } from '@ant-design/icons';
import {
  searchAccounts, getPickupSlots, getClientAddresses, saveAddress, getPincodeList,
  autopopulateCityAndState, getHubList, getPickupHubForAutoAssignment, savePickup,
} from '../../api/dashboard';
import {
  Typography, Input, Button, Row, Col, Select, DatePicker, Radio, Form, message,
  TimePicker, Checkbox, AutoComplete, Modal,
} from 'antd';
import moment from 'moment';
import { sendMessage } from './persistentIframeRenderer/iframeMessageUtils';
import { IframeApps } from './persistentIframeRenderer/constants';
import useDebounce from '../pages/TMSDashboard/hooks/useDebounce';
import { useTranslation } from 'react-i18next';

const { Text } = Typography;
const { Option } = Select;

const styles = (theme) => ({
  mainDiv: {
    width: '100%',
    display: 'flex',
    flexDirection: 'column',
    zIndex: 1000,
    '& .ant-modal-title': {
      fontSize: '20px',
    },
  },
  instructionText: {
    color: '#1659CB',
    fontSize: '14px',
    marginBottom: '16px',
  },
  formContainer: {
    padding: '20px',
    paddingLeft: '30px',
    borderRadius: '4px',
    flex: 2,
  },
  halfWidth: {
    maxWidth: '320px',
  },
  submitButton: {
    width: '100%',
    marginTop: '20px',
  },
  timePickerStyle: {
    width: '100%',
    border: '1px solid #bfbfbf',
    borderBottom: '1px solid',
    borderRadius: '4px',
    fontSize: '12px',
  },
  panel: {
    maxHeight: '600px',
    border: '1px solid #c4c4c4',
    overflowY: 'auto',
    padding: '20px',
    borderRadius: '4px',
  },
  panelContainer: {
    flex: 1,
    flexDirection: 'column',
    gap: '10px',
    marginTop: '10px',
  },
  panelHeader: {
    background: '#1659CB',
    height: '36px',
    display: 'flex',
    alignItems: 'center',
    padding: '0 10px',
    color: 'white',
    fontSize: '16px',
    fontWeight: '400',
    justifyContent: 'space-between',
  },
  closeButton: {
    color: 'white',
    backgroundColor: '#1659CB',
    padding: '5px 10px',
    borderRadius: '4px',
    textDecoration: 'none',
    cursor: 'pointer',
  },
  addressItem: {
    marginBottom: '10px',
  },
  formPanelContainer: {
    display: 'flex',
    justifyContent: 'space-between',
    flexDirection: 'row',
    gap: '20px',
  },
  panelToggleButton: {
    display: 'flex',
    justifyContent: 'flex-end',
    marginBottom: '10px',
  },
});

interface IProps extends WithStylesProps<typeof styles> {
  classes: any;
  enableAutopopulateCityAndState: boolean;
  hideAutoAllocateHubForPickupCreation: boolean;
  areaCodeVisibility: boolean;
  phoneRegex: string;
  mandatoryFieldsPickupAddress: boolean;
  onModalClose: () => void;
  uuid: string;
}

const AddPickupForm: React.FC<IProps> = (props) => {
  const { classes, enableAutopopulateCityAndState, hideAutoAllocateHubForPickupCreation,
    areaCodeVisibility, onModalClose, mandatoryFieldsPickupAddress, uuid } = props;
  const { t } = useTranslation();
  const [pickupForm] = Form.useForm();
  const [addressForm] = Form.useForm();
  const [customerOptions, setCustomerOptions] = React.useState([]);
  const [customerSearchString, setCustomerSearchString] = React.useState('');
  const [loading, setLoading] = React.useState({} as any);
  const [timeSlots, setTimeSlots] = React.useState([]);
  const [savedAddressArray, setSavedAddressArray] = React.useState([]);
  const [filteredAddressArray, setFilteredAddressArray] = React.useState([]);
  const [selectedClientId, setselectedClientId] = React.useState(null);
  const [selectedAddress, setSelectedAddress] = React.useState(null);
  const [showNewAddressPanel, setShowNewAddressPanel] = React.useState(false);
  const [showSavedAddressPanel, setShowSavedAddressPanel] = React.useState(false);
  const [filteredPincodes, setFilteredPincodes] = React.useState([]);
  const [autoAllocate, setAutoAllocate] = React.useState(false);
  const [hubOptions, setHubOptions] = React.useState([]);
  const [hubSearchText, setHubSearchText] = React.useState('');
  const pincodeLabelName = areaCodeVisibility ? t('area_code') : t('pincode_label');
  const debouncedCustomerSearchTerm = useDebounce(customerSearchString, 300);

  const mandatoryAddressFields = mandatoryFieldsPickupAddress ?
    ['name', 'addressLine1', 'cityName', 'stateName'] :
    ['name', 'phone', 'addressLine1', 'addressLine2', 'pincode', 'cityName', 'stateName'];

  React.useEffect(() => {
    if (debouncedCustomerSearchTerm) {
      handleCustomerSearch(debouncedCustomerSearchTerm);
    } else {
      setCustomerOptions([]);
    }
  }, [debouncedCustomerSearchTerm]);

  const handleCustomerSearch = async (queryString) => {
    setLoading(loading => ({ ...loading, customerLoading: true }));
    try {
      if (!queryString.trim()) {
        setCustomerOptions([]);
        return;
      }
      if (queryString.length >= 3) {
        const response = await searchAccounts(queryString);
        const searchedAccounts = response.data
          ? response.data.map((account) => ({
            id: account.id,
            code: account.code,
            name: account.name,
          }))
          : [];
        setCustomerOptions(searchedAccounts);
      }
    }
    catch (error) {
      message.error('Error fetching customer data');
    }
    finally {
      setLoading(loading => ({ ...loading, customerLoading: false }));
    }
  };

  const fetchPickupSlots = async (date) => {
    const pickupDate = moment(date).format('DD/MM/YYYY');
    const response = await getPickupSlots({ pickupDate, clientId: selectedClientId });

    if (response?.isSuccess && Array.isArray(response.data)) {
      // Map timeSlots to formatted strings, e.g., "08:00 - 10:00"
      const formattedSlots = response?.data?.map(slot => ({
        date: pickupDate,
        start: slot.start,
        end: slot.end,
      })) || [];
      setTimeSlots(formattedSlots);
    } else if (!response?.isSuccess) {
      message.error(response?.errorMessage || 'Error fetching pickup slots');
    }
  };

  React.useEffect(() => {
    // Fetch pickup slots for today's date
    const currentDate = moment();
    fetchPickupSlots(currentDate);
    pickupForm.setFieldsValue({
      pickupTimeSlot: timeSlots[0] ?
        JSON.stringify(timeSlots[0]) : undefined,
    });
  }, []);

  React.useEffect(() => {
    // Set pickupTimeSlot once timeSlots are loaded
    if (timeSlots.length > 0) {
      pickupForm.setFieldsValue({
        pickupTimeSlot: JSON.stringify(timeSlots[0]),
      });
    }
  }, [timeSlots]);

  const handleClientChange = async (clientId) => {
    setselectedClientId(clientId);

    // Fetch pickup slots with current date and new client ID
    const pickupDate = pickupForm.getFieldValue('pickupDate');
    const currentDate = moment();
    await fetchPickupSlots(pickupDate || currentDate);

    // Fetch saved addresses for the selected client
    const response = await getClientAddresses({ clientId });
    if (response?.data?.length) {
      setSavedAddressArray(response?.data || []);
      setFilteredAddressArray(response?.data || []);
    }
  };

  const handleCreateNewPickupAddress = async (values) => {
    const response = await saveAddress({ ...values, clientId: selectedClientId });
    if (response?.isSuccess) {
      message.success('Address saved successfully');
      const updatedAddressList = [...savedAddressArray];
      updatedAddressList.push(values);
      setSavedAddressArray(updatedAddressList);
      setFilteredAddressArray(updatedAddressList);
      setSelectedAddress(values);
      setShowNewAddressPanel(false);
    } else {
      message.error(response?.errorMessage || 'Error saving address');
    }
  };

  const fetchPincodeSuggestions = async (searchText) => {
    try {
      const response = await getPincodeList({ query: searchText });
      return response.data || [];
    } catch (error) {
      return [];
    }
  };

  // Populate city and state based on pincode
  const populateCityAndState = async (pincode) => {
    try {
      const response = await autopopulateCityAndState({ pincode });
      if (response?.isSuccess) {
        addressForm.setFieldsValue({
          cityName: response?.data?.city,
          stateName: response?.data?.state,
        });
      } else {
        message.error('City and State not found for this pincode.');
        addressForm.setFieldsValue({
          cityName: '',
          stateName: '',
        });
      }
    } catch (error) {
      message.error('Error fetching city and state for pincode');
    }
  };
  const handlePincodeSearch = async (queryString) => {

    addressForm.setFieldsValue({ pincode: queryString });
    if (queryString.length >= 3) {
      const results = await fetchPincodeSuggestions(queryString);
      setFilteredPincodes(results);
    } else {
      setFilteredPincodes([]);
    }

    if (queryString.length === 6 && enableAutopopulateCityAndState) {
      populateCityAndState(queryString);
    }
  };

  const handlePickupFormSubmit = async (values) => {
    try {
      setLoading(loading => ({ ...loading, savePickupLoading: true }));
      const clientDetails = customerOptions.find((client) => client.id === selectedClientId);
      const requestBody = {
        autoAllocate,
        childClient: {
          code: clientDetails.code,
          id: selectedClientId,
          name: clientDetails.name,
        },
        clientId: selectedClientId,
        courierType: values.courierType,
        description: values.description,
        hubId: values.hubId,
        pickupType: 'BUSINESS',
        totalItems: values.totalItems,
        totalWeight: values.totalWeight,
        pickupSlot: JSON.parse(values.pickupTimeSlot),
        closingTimeString: values.closingTimeString ?
          new Date(values.closingTimeString).toLocaleTimeString([], { hour: '2-digit', minute: '2-digit', hour12: false }) : undefined,
        pickupAddress: {
          ...selectedAddress,
        },
      };
      const response = await savePickup(requestBody);
      if (response.isSuccess && response?.data?.pickupId) {
        message.success(`Pickup request ${response?.data?.pickupId} saved successfully`);
        sendMessage(IframeApps.OpsDashboard, JSON.stringify({
          type: 'CLOSE_ADD_PICKUP',
          applicationId: 'crm_dashboard',
          uuid,
        }));
        onModalClose();
      } else {
        message.error(response.errorMessage);
      }
    } catch (error) {
      console.error('Error saving pickup request:', error);
      message.error('Error saving pickup request', error);
    } finally {
      setLoading(loading => ({ ...loading, savePickupLoading: false }));
    }
  };

  const handleSelectSavedAddress = () => {
    setShowSavedAddressPanel(true);
    setShowNewAddressPanel(false);
  };

  const handleAddNewAddress = () => {
    setShowNewAddressPanel(true);
    setShowSavedAddressPanel(false);
  };
  const handleEditAddress = () => {
    setShowSavedAddressPanel(true);
  };

  const handleHubSearch = async (value) => {
    if (value.length >= 2) {
      try {
        setLoading(loading => ({ ...loading, hubListLoading: true }));
        const response = await getHubList({ q: value });
        setHubOptions(response.data);
      } catch (error) {
        console.error('Error fetching hub data:', error);
      } finally {
        setLoading(loading => ({ ...loading, hubListLoading: false }));
      }
    } else {
      setHubOptions([]);
    }
  };

  const fetchAutoAllocHub = async () => {
    try {
      const response = await getPickupHubForAutoAssignment({
        customer_id: selectedClientId,
        pincode: selectedAddress?.pincode,
        shipment_type: pickupForm.getFieldValue('courierType'),
      });

      if (response?.isSuccess) {
        const hub = response.data;
        pickupForm.setFieldsValue({ hubId: hub.id });
        setHubSearchText(hub.name);
      } else {
        message.error(response.errorMessage);
      }
    } catch (error) {
      message.error('Error fetching auto-allocated hub:', error);
    }
  };

  const handleHubSelect = (value, option) => {
    pickupForm.setFieldsValue({ hubId: value });
    setHubSearchText(option.children);
  };

  const handleAddressSearch = (e) => {
    const query = e?.target?.value?.toLowerCase() || '';

    if (!query) {
      return;
    }
    const filtered = savedAddressArray.filter((address) =>
      address.name?.toLowerCase().includes(query) ||
      address.phone?.includes(query) ||
      address.pincode?.includes(query));

    setFilteredAddressArray(filtered);
  };

  React.useEffect(() => {
    if (selectedAddress && !hideAutoAllocateHubForPickupCreation) {
      fetchAutoAllocHub();
    }
  }, [selectedAddress]);

  const handleSelectedAddressChange = (address) => {
    setSelectedAddress(address);
  };

  return (
    <Modal
      width="100%"
      title={t('add_pickup')}
      visible={true}
      closable={true}
      maskClosable={false}
      onCancel={onModalClose}
      footer={null}
      className={classes.mainDiv}
    >
      <div className={classes.formPanelContainer}>
        <div className={classes.formContainer}>
          <Text className={classes.instructionText}>
            {t('fill_in_the_details_to_request_pickup')}
          </Text>
          <Form form={pickupForm} onFinish={handlePickupFormSubmit} layout="vertical" initialValues={{
            pickupDate: moment(),
          }}>
            <Row gutter={8} justify="start">
              <Col span={12}>
                <Form.Item
                  label={<span>{t('customer')}</span>}
                  name="childClient"
                  rules={[{ required: true, message: 'Please select a customer' }]}
                  className={classes.halfWidth}
                >
                  <Select
                    showSearch
                    placeholder={t('customer_name')}
                    onSearch={(value) => setCustomerSearchString(value)}
                    loading={loading.customerLoading}
                    filterOption={false}
                    onChange={handleClientChange}
                  >
                    {customerOptions.map((option) => (
                      <Select.Option key={option.id} value={option.id}>
                        {option.name}, {option.code}
                      </Select.Option>
                    ))}
                  </Select>
                </Form.Item>
              </Col>
            </Row>

            <Row gutter={8} justify="start">
              <Col span={11}>
                <Form.Item
                  label={<span>{t('number_of_consignments_2')}</span>}
                  name="totalItems"
                  rules={[
                    { required: true, message: 'Please enter the number of consignments' },
                    { pattern: /^\d+$/, message: 'Must be a valid number' },
                    { max: 3, message: 'Cannot exceed 3 digits' },
                  ]}
                  className={classes.halfWidth}
                >
                  <Input placeholder={t('number_of_consignment')} />
                </Form.Item>
              </Col>
              <Col span={11}>
                <Form.Item
                  label={<span>{t('total_weight')}</span>}
                  name="totalWeight"
                  rules={[
                    { required: true, message: 'Please enter the total weight' },
                    { pattern: /^\d+(\.\d{1,2})?$/, message: 'Must be a valid weight' },
                    { max: 5, message: 'Cannot exceed 5 digits' },
                  ]}
                  className={classes.halfWidth}
                >
                  <Input placeholder={t('total_weight_kgs')} />
                </Form.Item>
              </Col>
            </Row>

            <Row gutter={8} justify="start">
              <Col span={11}>
                <Form.Item
                  label={<span>{t('destination_description')}</span>}
                  name="description"
                  className={classes.halfWidth}
                >
                  <Input.TextArea placeholder={t('description')} />
                </Form.Item>
              </Col>
            </Row>

            <Form.Item label={<span>{t('item_type')}</span>} name="courierType" initialValue="DOCUMENT">
              <Radio.Group>
                <Radio value="DOCUMENT">{t('document')}</Radio>
                <Radio value="NON-DOCUMENT">{t('non_document')}</Radio>
              </Radio.Group>
            </Form.Item>

            <Row gutter={8} justify="start">
              <Col span={11}>
                <Form.Item
                  label={<span>{t('pickup_date')}</span>}
                  name="pickupDate"
                  rules={[{ required: true, message: 'Please select a date' }]}
                  className={classes.halfWidth}
                >
                  <DatePicker
                    format="DD/MM/YYYY"
                    style={{ width: '100%' }}
                    onChange={(date) => fetchPickupSlots(date)}
                  />
                </Form.Item>
              </Col>
              <Col span={11}>
                <Form.Item
                  label={<span>{t('pickup_time_slot')}</span>}
                  name="pickupTimeSlot"
                  rules={[{ required: true, message: 'Please select a time slot' }]}
                  className={classes.halfWidth}
                >
                  <Select
                    placeholder={t('select_time_slot')}
                  >
                    {timeSlots.map((slot, index) => (
                      <Option key={index} value={JSON.stringify(slot)}>
                        {`${slot.start} - ${slot.end}`}
                      </Option>
                    ))}
                  </Select>
                </Form.Item>
              </Col>
            </Row>

            <Row gutter={8} justify="start">
              <Col span={11}>
                <Form.Item
                  label={<span>{t('closing_time')}</span>}
                  name="closingTimeString"
                  rules={[{ required: true, message: 'Please select a closing time' }]}
                  className={classes.halfWidth}
                >
                  <TimePicker
                    className={classes.timePickerStyle}
                    format="HH:mm"
                    placeholder={t('closing_time')}
                  />
                </Form.Item>
              </Col>
            </Row>

            <Row gutter={8} justify="start">
              <Col span={12}>
                <Form.Item
                  label={<span>{t('pickup_address')}</span>}
                  name="closingTimeString"
                  rules={[{ required: true, message: 'Please select a Pickup Address' }]}
                  style={{ width: '100%' }}
                >
                  {savedAddressArray.length > 0 && !selectedAddress ? (
                    <React.Fragment>
                      <span>{savedAddressArray.length} {t('saved_addresses')}</span>
                      <div>
                        <a onClick={handleSelectSavedAddress}>{t('select_from_saved_address_caps')}</a>
                        <span> | </span>
                        <a onClick={handleAddNewAddress}>{t('add_new_address_caps')}</a>
                      </div>
                    </React.Fragment>
                  ) : !selectedAddress ? (
                    <a
                      onClick={selectedClientId ? handleAddNewAddress : undefined}
                      style={{ color: selectedClientId ? '#1890ff' : '#c4c4c4', cursor: selectedClientId ? 'pointer' : 'not-allowed' }}
                    >
                      {t('add_new_address_caps')}
                    </a>
                  ) : null}

                  {selectedAddress && (
                    <div style={{ marginTop: '8px', padding: '8px', border: '1px solid #ddd', borderRadius: '4px' }}>
                      <p style={{ margin: 0, fontWeight: 'bold' }}>
                        {selectedAddress.name} | {selectedAddress.phone}
                      </p>
                      <p style={{ margin: 0 }}>
                        {selectedAddress.addressLine1}, {selectedAddress.addressLine2}
                      </p>
                      <p style={{ margin: 0 }}>
                        {selectedAddress.city}, {selectedAddress.state} - {selectedAddress.pincode}
                      </p>
                      <Button
                        type="link"
                        onClick={handleEditAddress}
                        style={{ color: 'red', paddingLeft: 0, textDecoration: 'underline' }}
                      >
                        {t('edit_address_caps')}
                      </Button>
                    </div>
                  )}
                </Form.Item>
              </Col>
            </Row>
            <Row>
              <Col span={8}>
                <Checkbox
                  name="autoAllocate"
                  checked={autoAllocate}
                  onChange={(e) => setAutoAllocate(e.target.checked)}
                >
                  {t('auto_allocate')}
                </Checkbox>
              </Col>
            </Row>
            {/* Allot Hub Input */}
            {!autoAllocate && (
              <Row gutter={8} justify="start">
                <Col span={11}>
                  <Form.Item
                    label={<span>{t('allot_hub')}</span>}
                    name="hubId"
                    rules={[{ required: true, message: 'Please select a hub' }]}
                    className={classes.halfWidth}
                  >
                    <Select
                      showSearch
                      placeholder={t('type_to_search_hub')}
                      onSearch={handleHubSearch} // Trigger search function as the user types
                      onSelect={handleHubSelect}
                      loading={loading.hubListLoading}
                      value={hubSearchText}
                      filterOption={false} // Disable built-in filter to use custom search
                    >
                      {hubOptions.map((hub) => (
                        <Select.Option key={hub.id} value={hub.id}>
                          {hub.name}
                        </Select.Option>
                      ))}
                    </Select>
                    {!hideAutoAllocateHubForPickupCreation && (
                      <Button
                        type="link"
                        onClick={fetchAutoAllocHub}
                        style={{ color: '#c4c4c4', textDecoration: 'underline', fontSize: 14 }}
                      >
                        {t('auto_allot_a_hub')}
                      </Button>
                    )}
                  </Form.Item>
                </Col>
              </Row>
            )}
            <Button type="primary" htmlType="submit" className={classes.submitButton}
              loading={loading.savePickupLoading}>
              {t('request_pickup_caps')}
            </Button>
          </Form>
        </div>

        {/* New Address Panel */}
        {showSavedAddressPanel && (
          <div className={classes.panelContainer}>
            <div className={classes.panelHeader}>
              <span>{t('saved_addresses')}</span>
              <a
                onClick={() => setShowSavedAddressPanel(false)}
                className={classes.closeButton}
              >
                Close
              </a>
            </div>
            <div className={classes.panel}>
              <div className={classes.panelToggleButton}>
                <Button
                  type="link"
                  onClick={handleAddNewAddress}
                  style={{ color: '#1890ff', textDecoration: 'underline' }}
                >
                  {t('add_new_address_caps')}
                </Button>
              </div>
              <Input
                placeholder={t('search_by_name_mobile_pin')}
                onChange={handleAddressSearch}
                style={{ marginBottom: '10px', width: '100%' }}
              />
              {filteredAddressArray.map((address, index) => (
                <div key={index} className={classes.addressItem}>
                  <Radio
                    value={address}
                    checked={selectedAddress === address}
                    onChange={() => handleSelectedAddressChange(address)}
                  >
                    <p style={{ margin: 0, fontWeight: 'bold' }}>
                      {address.name} | {address.phone}
                    </p>
                    <p style={{ margin: 0 }}>
                      {address.addressLine1}, {address.addressLine2}
                    </p>
                    <p style={{ margin: 0 }}>
                      {address.cityName}, {address.stateName} - {address.pincode}
                    </p>
                  </Radio>
                </div>
              ))}
            </div>
          </div>
        )}
        {/* New Address Panel */}
        {showNewAddressPanel && (
          <div className={classes.panelContainer}>
            <div className={classes.panelHeader}>
              <span>{t('add_new_address_caps')}</span>
              <a
                onClick={() => setShowNewAddressPanel(false)}
                className={classes.closeButton}
              >
                Close
              </a>
            </div>
            <div className={classes.panel}>
              <div className={classes.panelToggleButton}>
                <Button
                  type="link"
                  onClick={handleSelectSavedAddress}
                  style={{ color: '#1890ff', textDecoration: 'underline', width: '80%' }}
                >
                  {t('choose_from_saved_address_caps')}
                </Button>
              </div>
              <Form form={addressForm} onFinish={handleCreateNewPickupAddress} layout="vertical">
                <Row gutter={12} justify="start">
                  <Col span={12}>
                    <Form.Item
                      label={<span>{pincodeLabelName}</span>}
                      name="pincode"
                      rules={[{
                        required: mandatoryAddressFields.includes('pincode')
                        , message: 'Please enter a pincode'
                      }]}
                      className={classes.halfWidth}
                    >
                      <AutoComplete
                        options={filteredPincodes.map((option) => ({
                          value: option.code,
                          label: (
                            <div>
                              {option.code}
                              <span style={{ float: 'right', color: 'gray', fontSize: '0.9em' }}>
                                {option.district}
                              </span>
                            </div>
                          ),
                        }))}
                        onSearch={handlePincodeSearch}
                        onChange={(value) => addressForm.setFieldsValue({ pincode: value })}
                      >
                        <Input placeholder={pincodeLabelName} />
                      </AutoComplete>
                    </Form.Item>
                  </Col>
                </Row>
                <Row gutter={24} justify="start">
                  <Col span={12}>
                    <Form.Item
                      label={<span>{t('name')}</span>}
                      name="name"
                      rules={[{
                        required: mandatoryAddressFields.includes('name'),
                        message: 'Please enter a name'
                      }]}
                      className={classes.halfWidth}
                    >
                      <Input placeholder={t('name')} />
                    </Form.Item>
                  </Col>
                  <Col span={12}>
                    <Form.Item
                      label={<span>{t('phone_number')}</span>}
                      name="phone"
                      rules={[{ required: mandatoryAddressFields.includes('phone'), message: 'Please enter a phone number' },
                      { pattern: new RegExp(props.phoneRegex), message: 'Invalid phone number' },
                      ]}
                      className={classes.halfWidth}
                    >
                      <Input placeholder={t('phone_number')} />
                    </Form.Item>
                  </Col>
                </Row>

                <Row gutter={24} justify="start">
                  <Col span={24}>
                    <Form.Item
                      label={<span>{t('address_line_1')}</span>}
                      name="addressLine1"
                      rules={[{
                        required: mandatoryAddressFields.includes('addressLine1')
                        , message: 'Please enter Address Line 1'
                      }]}
                      className={classes.halfWidth}
                    >
                      <Input placeholder={t('address_line_1')} />
                    </Form.Item>
                  </Col>

                </Row>
                <Row gutter={24} justify="start">
                  <Col span={24}>
                    <Form.Item
                      label={<span>{t('address_line_2')}</span>}
                      name="addressLine2"
                      rules={[{
                        required: mandatoryAddressFields.includes('addressLine2')
                        , message: 'Please enter Address Line 2'
                      }]}
                      className={classes.halfWidth}
                    >
                      <Input placeholder={t('address_line_2')} />
                    </Form.Item>
                  </Col>
                </Row>

                <Row gutter={24} justify="start">
                  <Col span={12}>
                    <Form.Item
                      label={<span>{t('city')}</span>}
                      name="cityName"
                      rules={[{
                        required: mandatoryAddressFields.includes('cityName')
                        , message: 'Please enter a city'
                      }]}
                      className={classes.halfWidth}
                    >
                      <Input placeholder={t('city')} disabled={enableAutopopulateCityAndState} />
                    </Form.Item>
                  </Col>
                  <Col span={12}>
                    <Form.Item
                      label={<span>{t('state')}</span>}
                      name="stateName"
                      rules={[{
                        required: mandatoryAddressFields.includes('stateName')
                        , message: 'Please enter a state'
                      }]}
                      className={classes.halfWidth}
                    >
                      <Input placeholder={t('state')} disabled={enableAutopopulateCityAndState} />
                    </Form.Item>
                  </Col>
                </Row>

                <Button type="primary" htmlType="submit" className={classes.submitButton}
                >
                  {t('save_&_use_as_pickup_address')}
                </Button>
              </Form>
            </div>
          </div>
        )}

        {!showNewAddressPanel && !showSavedAddressPanel && (
          <div className={classes.panelContainer}>
          </div>
        )}
      </div>
    </Modal>
  );
};

const mapStateToProps = ({ masterData, opsDashboardStateReducer }) => {
  return {
    uuid: opsDashboardStateReducer.uuid,
    enableAutopopulateCityAndState:
      masterData.ops_dashboard_config?.parts_to_show?.enable_autocomplete_city_state_from_pincode,
    hideAutoAllocateHubForPickupCreation:
      masterData.ops_dashboard_config?.parts_to_show?.hide_auto_allocate_hub_for_pickup_creation,
    areaCodeVisibility: masterData.ops_dashboard_config?.config?.use_area_code,
    phoneRegex: masterData.ops_dashboard_config?.config?.phone_regex?.toString().replace(/^\//, '').replace(/\/$/, '') || '',
    mandatoryFieldsPickupAddress:
      masterData.ops_dashboard_config?.parts_to_show?.mandatory_fields_address_key_pickup_dashboard,
  };
};

const AddPickupFormStyled = withStyles(styles, { injectTheme: true })(AddPickupForm);

export default withRouter(connect(mapStateToProps, null)(AddPickupFormStyled));
