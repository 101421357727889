export const addressRegisterFieldsList =  [
    {
        name: 'Address Code',
        id: 'address_id',
        width: 130,
    },
    {
        name: 'Name',
        id: 'name',
        width: 200,
    },
    {
        name: 'Address',
        id: 'address_detail',
        width: 200,
    },
    {
        name: 'Pincode',
        id: 'pincode',
        width: 130,
    },
    {
        name: 'City',
        id: 'city',
        width: 130,
    },
    {
        name: 'State',
        id: 'state',
        width: 130,
    },
    {
        name: 'Country',
        id: 'country',
        width: 130,
    },
    {
        name: 'Phone',
        id: 'phone',
        width: 130,
    },
    {
        name: 'Email',
        id: 'email',
        width: 200,
    },
    {
        name: 'Address Status',
        id: 'status',
        width: 130,
    },
    {
        name: 'Latitude',
        id: 'latitude',
    },
    {
        name: 'Longitude',
        id: 'longitude',
    },
    {
        name: 'Location Picture',
        id: 'location_image',
    },
    {
        name: 'Fixed Time Service(Mins)',
        id: 'service_time',
    },
    {
        name: 'Delivery/Pickup Time Slot',
        id: 'time_slot_list',
    },
];

export const csvKeyMapping = {
    address_id: 'Address Code',
    address_line_1: 'Address Line 1',
    address_line_2: 'Address Line 2',
    pincode: 'Pincode',
    city: 'City',
    state: 'State',
    country: 'Country',
    status: 'Address Status',
    name: 'Name',
    phone: 'Phone',
    email: 'Email',
    latitude: 'Latitude',
    longitude: 'Longitude',
    service_time: 'Fixed Service Time(mins)',
    time_slot_list: 'Delivery/Pickup Time Slot',
};

export const formFieldList = [
    {
        id: 'basic_details',
        name: 'Basic Details',
        fields_list: [
            {
                name: 'Address Code',
                id: 'address_id',
                type: 'string',
            },
            {
                name: 'Verification Status',
                id: 'status',
                type: 'boolean',
                checkedChildren: 'Verified',
                unCheckedChildren: 'Unverified',
                defaultChecked: false,
            },
            {
                name: 'Address Line 1',
                id: 'address_line_1',
                type: 'string',
            },
            {
                name: 'Address Line 2',
                id: 'address_line_2',
                type: 'string',
            },
            {
                name: 'Name',
                id: 'name',
                type: 'string',
            },
            {
                name: 'Phone',
                id: 'phone',
                type: 'string',
            },
            {
                name: 'Email',
                id: 'email',
                type: 'email',
            },
        ],
    },
    {
        id: 'location',
        name: 'Location',
        fields_list: [
            {
                name: 'Pincode',
                id: 'pincode',
                type: 'string',
            },
            {
                name: 'State',
                id: 'state',
                type: 'string',
            },
            {
                name: 'City',
                id: 'city',
                type: 'string',
            },
            {
                name: 'Country',
                id: 'country',
                type: 'string',
            },
            {
                name: 'Latitude',
                id: 'latitude',
                type: 'number',
            },
            {
                name: 'Longitude',
                id: 'longitude',
                type: 'number',
            },
            {
                name: 'Location Picture',
                id: 'location_image',
                type: 'image_upload',
            },
        ],
    },
    {
        id: 'time_slot',
        name: 'Time Slot',
        fields_list: [
            {
                name: 'Service Time(Mins)',
                id: 'service_time',
                type: 'string',
            },
            {
                name: 'Delivery/Pickup Time Slot',
                id: 'time_slot_list',
                type: 'timeRangeList',
                format: 'HH:mm',
            },
        ],
    },
];

export const detailColumnsIdSectionWise = [
    {
        key: 'basic_details',
        name: 'Basic Details',
        columns: [
            'address_id',
            'address_detail',
            'status',
            'pincode',
            'city',
            'name',
            'email',
            'state',
            'country',
            'phone',
        ],
    },
    {
        key: 'timeslot',
        name: 'Timeslot',
        columns: [
            'service_time',
            'time_slot_list',
        ],
    },
];
