import { TableModalColumns } from './types';

export const givenPaymentDetailsColumns: TableModalColumns[] = [
  {
    pretty_name: 'Mode',
    column_id: 'mode',
  },
  {
    pretty_name: 'Value',
    column_id: 'value',
  },
];
export const geocodingUpdatesColumn: TableModalColumns[] = [
  {
    pretty_name: 'Source',
    column_id: 'source',
  },
  {
    pretty_name: 'Timestamp',
    column_id: 'timestamp',
  },
  {
    pretty_name: 'Latitude',
    column_id: 'latitude',
  },
  {
    pretty_name: 'Longitude',
    column_id: 'longitude',
  },
];
