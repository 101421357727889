import * as React from 'react';
import { message, Modal, Table } from 'antd';
import FixedHeaderTable from '../../common/FixedHeaderTable/FixedHeaderTable';
import { bindActionCreators } from 'redux';
import { fetchObjectFieldListMetadata } from 'src/actions/jsonArrayTableViewActions';
import { connect } from 'react-redux';

interface IProps {
  isVisible: boolean;
  handleClose: () => void;
  data: any;
  objectType: string;
  fetchObjectFieldListMetadata: (params: any) => void;
  errorMessage: string;
  isLoading: boolean;
  fieldListMetadata: any;
  columnKey: string;
}

const JsonArrayTableViewModal = (props: IProps) => {
  const {
    isVisible,
    handleClose,
    data,
    objectType,
    fetchObjectFieldListMetadata,
    errorMessage,
    isLoading,
    fieldListMetadata,
    columnKey,
  } = props;

  React.useEffect(() => {
    if (!fieldListMetadata?.[objectType]) {
      fetchObjectFieldListMetadata({
        objectType,
        sendCustomFieldsOnly: true,
      });
    }
  }, []);

  React.useEffect(() => {
    if (errorMessage) {
      message.error(errorMessage);
    }
  }, [errorMessage]);

  const getColumnsForTable = () => {
    const columns: any = [];
    const fieldsList = fieldListMetadata?.[objectType]?.find(field => field.id === columnKey)?.formUIComponent?.fields ?? [];

    fieldsList.forEach((field, index) => {
      columns.push({
        dataIndex: index,
        title: field.prettyName,
        key: field.id,
        render: (_, record) => {
          if (field.dataType === 'boolean' && typeof record[field.id] === 'boolean') {
            return record[field.id] ? 'Yes' : 'No';
          } else {
            return record[field.id] ?? '-';
          }
        },
        width: 200,
      });
    });
    return columns;
};

  return (
    <Modal
      title={'Details'}
      width={800}
      visible={isVisible}
      footer={null}
      onCancel={() => handleClose()}
      destroyOnClose
    >
      <Table
        columns={getColumnsForTable()}
        dataSource={data}
        loading={isLoading}
        pagination={false}
        scroll={{ x: 'max-content' }}
      />
    </Modal>
  );
};

const mapStateToProps = ({ jsonArrayTableViewReducer }, ownProps) => {
  return {
    isLoading: jsonArrayTableViewReducer.isLoading,
    fieldListMetadata: jsonArrayTableViewReducer.fieldListMetadata,
    errorMessage: jsonArrayTableViewReducer.errorMessage,
  };
};

const mapDispatchToProps = (dispatch) => {
  return bindActionCreators({
    fetchObjectFieldListMetadata,
  }, dispatch);
};

export default connect(mapStateToProps, mapDispatchToProps)(JsonArrayTableViewModal);
