import React from 'react';
import { Carousel, Modal } from 'antd';
import { appliedExceptionColumns } from './utils';
import FixedHeaderTable from './FixedHeaderTable/FixedHeaderTable';
import withStyles from 'react-jss';
import moment from 'moment';
import { LeftCircleOutlined, RightCircleOutlined } from '@ant-design/icons';

const styles = (theme: any): any => ({
  tableContainer: {
    height: 300,
  },
  modalContainer: {
    height: '1200px !important',
    width: '1200px !important',
  },
  carouselContainer: {
    padding: '1.5rem',
    maxWidth: '100%',
    '& span > svg': {
      width: '120%',
      height: '120%',
      color: 'black',
    },
  },
});

const ExceptionDetailsModal = (props) => {
  const { classes, isVisible, onModalClose, appliedExceptionList } = props;

  const [showImageSwitchView, setShowImageSwitchView] = React.useState(false);
  const [documentDetails, setDocumentDetails] = React.useState(null);

  const showDocumentDetailsModal = (record) => {
    setShowImageSwitchView(true);
    setDocumentDetails(record?.proof_image_list);
  };

  const generateDataColumns = () => {
    const columnData: any = [];
    const indexList = appliedExceptionColumns;
    indexList.forEach((item) => {
      const itemToPush = {};
      itemToPush['title'] = item.pretty_name;
      itemToPush['dataIndex'] = item.column_id;
      itemToPush['width'] = 80;
      itemToPush['key'] = item.column_id;
      if (item.column_id === 'event_time') {
        itemToPush['render'] = (x, record) => {
          const formattedTime = moment(x).format('YYYY-MM-DD HH:mm:ss');
          return <span>{formattedTime}</span>;
        };
      }
      if (item.column_id === 'applied_by') {
        itemToPush['render'] = (x, record) => {
          return (
            <span>
              {`${record.employee_username}, ${record.employee_code}`}
            </span>
          );
        };
      }
      if (item.column_id === 'images') {
        itemToPush['render'] = (x, record) => {
          return (
            <span>
              <a onClick={() => showDocumentDetailsModal(record)}>View</a>
            </span>
          );
        };
      }
      columnData.push(itemToPush);
    });
    return columnData;
  };


  return (
    <div>
      <Modal
        title="Exception Details"
        visible={isVisible}
        onCancel={onModalClose}
        width={800}
        footer={null}
        className={classes.modalContainer}
      >
        <div className={classes.tableContainer}>
          <FixedHeaderTable
            locale={{ emptyText: ('No data found') }}
            pagination={false}
            dataSource={appliedExceptionList}
            columns={generateDataColumns()}
            rowKey={(record, index) => index}
            className={classes.tableContainer}
          />
        </div>
      </Modal>
      {showImageSwitchView && (
        <Modal
          className={classes.modalContainer}
          visible={showImageSwitchView}
          onCancel={() => setShowImageSwitchView(false)}
          footer={null}
        >
          <Carousel
            className={classes.carouselContainer}
            prevArrow={<LeftCircleOutlined />}
            nextArrow={<RightCircleOutlined />}
            arrows
          >
            {documentDetails?.map((data) => (
              <div key={data}>
                <img object-fit="cover" style={{ width: '70%' }} src={data} />
              </div>
            ))}
          </Carousel>
        </Modal>
      )}
    </div>
  );
};

export default withStyles(styles, { injectTheme: true })(ExceptionDetailsModal);
