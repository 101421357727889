import {
  WORKFLOW_METADATA_SET_LOADING,
  WORKFLOW_METADATA_SET_DATA,
  WORKFLOW_METADATA_SET_ERROR,
} from '../actions/constants';

const defaultState = {
  fieldListMetadata: {},
  errorMessage: null,
  isLoading: false,
};

export default function (state: any = defaultState, action) {
  switch (action.type) {
    case WORKFLOW_METADATA_SET_LOADING:
      return {
        ...state,
        isLoading: action.data,
      };

    case WORKFLOW_METADATA_SET_DATA:
      return {
        ...state,
        fieldListMetadata: {
          ...state.fieldListMetadata,
          ...action.data,
        },
        errorMessage: null,
      };

    case WORKFLOW_METADATA_SET_ERROR:
      return {
        ...state,
        errorMessage: action.data,
      };

    default:
      return state;
  }
}
