import { IncomingActions } from 'src/components/common/persistentIframeRenderer/OpsDashboardIFrame/utils';

const defaultState = {
  showAddPickup: false,
};

export default function (state: any = defaultState, action) {
  switch (action.type) {
    case IncomingActions.ADD_PICKUP: {
      return {
        ...state,
        showAddPickup: action.data,
      };
    }
    case 'SET_UUID': {
      return {
        ...state,
        uuid: action.data,
      };
    }

    default: return state;
  }
}
