import * as React from 'react';
import withStyles from 'react-jss';
import { connect } from 'react-redux';
import '@ant-design/compatible/assets/index.css';
import { Row, Col, Timeline, Collapse, Button, Tree, Modal, message } from 'antd';
const { Panel } = Collapse;
import * as moment from 'moment';
import { camelCase, startCase, cloneDeep } from 'lodash';
import { Link } from 'react-router-dom';
import { getRetailTripManagerRoute } from '../../../routing/utils';
import { styles } from './ConsignmentLogTabStyle';
import { isBoolean, isValidUrl, formatDateTime } from '@utils/utils';
import GalleryModal from '@components/common/galleryModal';
import { ArrowRightOutlined, CaretDownOutlined, CaretRightOutlined, CopyOutlined } from '@ant-design/icons';
import ReactDiffViewer, { DiffMethod } from "react-diff-viewer";

const { TreeNode } = Tree;
import { useTranslation } from 'react-i18next';
interface GalleryModalData {
  images: string[];
  videos: string[];
}

const DiffViewer = React.memo((props: any) => {

  const { classes, oldData, newData } = props;
  const convertToCapitalisedCase = (inputText: string | number = '') => {
    return inputText?.toString()?.split('_').map(word => word.charAt(0).toUpperCase() + word.slice(1)).join(' ');
  };

  const TreeViewer = React.memo((props) => {
    const [activePanel, setActivePanel] = React.useState(false);
    const [expandedKeys, setExpandedKeys] = React.useState([]);
    const { oldValue, newValue, title, classes } = props;

    const togglePanel = () => {
      setActivePanel(!activePanel);
    };

    const getAllKeys = (data, type, parentKey = '') => {
      let keys = [];
      const isArray = Array.isArray(data); 
      for (const key in data) {
        const keyToSet = isArray ? Number(key) + 1 : key;
        const combinedKey = parentKey ? `${type}-${parentKey}-${keyToSet}` : `${type}-${keyToSet}`;
        keys.push(combinedKey);
        if (typeof data[key] === 'object' && data[key] !== null) {
          keys = keys.concat(getAllKeys(data[key], type, combinedKey));
        }
      }
      return keys;
    };

    const expandAll = () => {
      const oldKeys = getAllKeys(oldValue, 'old_value');
      const newKeys = getAllKeys(newValue, 'new_value'); 
      setExpandedKeys([...oldKeys, ...newKeys]);
    };

    const collapseAll = () => {
      setExpandedKeys([]);
    }


    const renderTreeNodes = (data, type, parentKey = '') => {
      if (!data || (!Array.isArray(data) && !(typeof data === 'object'))) {
        return null;
      }
      return Object.keys(data).map((key) => {
        const node = data[key];
        const isArray = Array.isArray(data);
        const keyToSet = isArray ? Number(key) + 1 : key;
        const combinedKey = parentKey ? `${type}-${parentKey}-${keyToSet}` : `${type}-${keyToSet}`;
        if (node && typeof node === 'object') {
          return (
            <TreeNode
              title={convertToCapitalisedCase(keyToSet)}
              key={combinedKey}
              style={{ backgroundColor: '#f7f7f7' }}
            >
              {renderTreeNodes(node, type, combinedKey)}
            </TreeNode>
          );
        }
        return (
          <TreeNode
            title={`${convertToCapitalisedCase(
              keyToSet,
            )}: ${node === null || node === undefined ? 'NULL' : '' + node }`}
            key={combinedKey}
            style={{ backgroundColor: '#f7f7f7' }}
          />
        );
      });
    };

    return (
      <div style={{ marginBottom: '20px' }}>
        <div style={{ display: 'flex', alignItems: 'center' }}>
          {activePanel ? (
            <CaretDownOutlined
              onClick={togglePanel}
              style={{ marginRight: '10px' }}
            />
          ) : (
            <CaretRightOutlined
              onClick={togglePanel}
              style={{ marginRight: '10px' }}
            />
          )}
          <div>{convertToCapitalisedCase(title)}</div>
          {activePanel && (
            <div className={classes.expandBtn} style={{marginLeft: '10px'}}>
              <Button
                className={classes.toggleDetails}
                size="small"
                onClick={expandAll}
              >
                Expand All
              </Button>
              <Button
                className={classes.toggleDetails}
                size="small"
                onClick={collapseAll}
              >
                Collapse All
              </Button>
            </div>
          )}
        </div>
        {activePanel && (
          <div
            style={{
              display: 'flex',
              marginTop: '10px',
              alignItems: 'stretch',
            }}
          >
            <div
              style={{
                width: '50%',
                border: '1px solid rgb(217, 217, 217)',
                padding: '10px',
                marginRight: '10px',
                backgroundColor: '#f7f7f7',
              }}
            >
              <Tree
                style={{ backgroundColor: '#f7f7f7', paddingTop: '10px' }}
                expandedKeys={expandedKeys}
                selectable={false}
                onExpand={setExpandedKeys}
              >
                {renderTreeNodes(oldValue, 'old_value')}
              </Tree>
            </div>
            <ArrowRightOutlined
              style={{
                alignSelf: 'center',
                marginLeft: '10px',
                marginRight: '10px',
              }}
            />
            <div
              style={{
                width: '50%',
                border: '1px solid rgb(217, 217, 217)',
                padding: '10px',
                backgroundColor: '#f7f7f7',
              }}
            >
              <Tree
                style={{ backgroundColor: '#f7f7f7', paddingTop: '10px' }}
                expandedKeys={expandedKeys}
                onExpand={setExpandedKeys}
                selectable={false}
              >
                {renderTreeNodes(newValue, 'new_value')}
              </Tree>
            </div>
          </div>
        )}
      </div>
    );
  });

  return (
    <div style = {{ maxHeight: '600px', overflowY: 'scroll' }}>
      {Object.keys(newData).map((key) => {
        const oldValue = oldData[key];
        const newValue = newData[key];
        const isArray = Array.isArray(newValue) || Array.isArray(oldValue);
        const isObject =
          (typeof newValue === 'object' && newValue) || (typeof oldValue === 'object' && oldValue);
        if (!(isArray || isObject)) {
          return (
            <div key={key} style={{ marginBottom: '20px' }}>
              <div style={{ display: 'flex', textAlign: 'left' }}>
                <div style={{ width: '25%', marginRight: '10px' }}>{convertToCapitalisedCase(key)}</div>
                <div style={{ width: '25%', marginRight: '10px' }}>
                  {oldValue === null || oldValue === undefined ? 'NULL' : '' + oldValue }
                </div>
                <div style={{ width: '25%', marginRight: '10px' }}><ArrowRightOutlined /></div>
                <div style={{ width: '25%', marginRight: '10px' }}>
                  {newValue === null || newValue === undefined ? 'NULL' : '' + newValue }
                </div>
              </div>
            </div>
          );
        }
        if (isObject) {
          return (
            <TreeViewer oldValue={oldValue} newValue={newValue} title={key} classes={classes}/>
          );
        }
      })}
    </div>
  );
});

const JSONDiffModal = (props: any) => {
  const { onClose, oldValue, newValue } = props;
  const oldCode = JSON.stringify(oldValue, null, 2);
  const newCode = JSON.stringify(newValue, null, 2);

  const copyToClipboard = async (text) => {
    try {
      await navigator.clipboard.writeText(text);
      message.success('Copied to clipboard');
    } catch (error) {
      message.error('Failed to copy :', error.message);
    }
  };

  return (
    <Modal
      title={'Softdata Updates'}
      width={'50%'}
      visible={true}
      onCancel={(e) => {
        e.preventDefault();
        e.stopPropagation();
        onClose(false);
      }}
      footer={null}
      destroyOnClose
    >
      <div style={{ maxHeight: '600px', overflowY: 'scroll' }}>
      <div style = {{ display: 'flex', alignItems: 'flex-end' }}>
        <CopyOutlined onClick={() => copyToClipboard(oldCode)} style = {{ flexGrow: '1', alignSelf: 'flex-end', textAlign: 'right' }} />
        <CopyOutlined onClick={() => copyToClipboard(newCode)} style = {{ alignSelf: 'flex-end', textAlign: 'right', flexGrow: '1' }} />
      </div>
      <ReactDiffViewer
        oldValue={oldCode}
        newValue={newCode}
        splitView={true}
        onLineNumberClick={null}
        compareMethod={DiffMethod.WORDS}
      />
      </div>
    </Modal>
  );
};

const ConsignmentLogTab = (props: any) => {
  const { classes, events, data, details } = props;
  const [expandAllDetails, setExpandAllDetails] = React.useState([]);
  const timelineEvents = events ? cloneDeep(events) : [];
  const [isGalleryModalVisible, setIsGalleryModalVisible] = React.useState(false);
  const [showJSONDiff, setShowJSONDiff] = React.useState(false); 
  const [selectedEvent, setSelectedEvent] = React.useState(null);
  const [galleryModalData, setGalleryModalData] =
    React.useState<GalleryModalData>({ images: [], videos: [] });

  React.useEffect(() => {
    for (let detailIdx = 0; detailIdx < details.length; ++detailIdx) {
      for (
        let detailColumnIdx = 0;
        detailColumnIdx < details[detailIdx].columns.length;
        ++detailColumnIdx
      ) {
        let fieldValue = '';
        if (Array.isArray(details[detailIdx].columns[detailColumnIdx].id)) {
          for (
            let detailId = 0;
            detailId < details[detailIdx].columns[detailColumnIdx].id.length;
            ++detailId
          ) {
            if (
              data[details[detailIdx].columns[detailColumnIdx].id[detailId]]
            ) {
              if (detailId === 0) {
                fieldValue +=
                  data[
                    details[detailIdx].columns[detailColumnIdx].id[detailId]
                  ].toString();
              } else {
                fieldValue +=
                  ', ' +
                  data[
                    details[detailIdx].columns[detailColumnIdx].id[detailId]
                  ].toString();
              }
            }
          }
        } else {
          fieldValue = data[details[detailIdx].columns[detailColumnIdx].id];
        }
        if (fieldValue && fieldValue !== null && fieldValue !== undefined) {
          details[detailIdx].columns[detailColumnIdx].value =
            fieldValue.toString();
        } else {
          details[detailIdx].columns[detailColumnIdx].value = null;
        }
      }
    }
  }, []);

  const toggleAllDetails = (checked) => {
    if (checked) {
      setExpandAllDetails(
        timelineEvents?.map((event, idx) => {
          if (event.collapsible) {
            return idx.toString();
          }
        }),
      );
    } else {
      setExpandAllDetails([]);
    }
  };

  const fetchTitle = (eventString) => {
    const {t} = useTranslation();
    const event_string = eventString;
    switch (true) {
      case event_string.includes('softdata_upload'):
        return t('Consignment created');
      case event_string.includes('softdata_update'):
        return t('Consignment updated');
      case event_string.includes('hub') &&
        !(
          event_string.includes('intransittohub') ||
          event_string.includes('outscan_at_hub') ||
          event_string.includes('reachedathub')
        ):
      case event_string.includes('trip'):
      case event_string.includes('rider'):
      case event_string.includes('dock'):
      case event_string.includes('gate'):
        return t(startCase(camelCase(eventString)));
      case event_string.includes('ist'):
        return t(eventString);
      case event_string.includes('call_log'):
        return t('Call Logged');
      case event_string.includes('Softdata Update'): 
        return 'CN Softdata Update';
      case event_string.includes('Custom Consignment Update'):
        return 'Custom Consignment Update';
      default:
        return t('CN Status Update');
    }
  };

  const fetchHeader = (event) => {
    const {t} = useTranslation();
    const event_string = event.event_string;
    switch (true) {
      case event_string.includes('softdata_upload'):
      case event_string.includes('softdata_update'):
        return t('CN Reference #');
      case event_string.includes('hub') &&
        !(
          event_string.includes('intransittohub') ||
          event_string.includes('outscan_at_hub') ||
          event_string.includes('reachedathub')
        ):
        return t('Hub Code');
      case event_string.includes('trip'):
      case event_string.includes('rider'):
        return t('Trip ID');
      case event_string.includes('dock'):
        return t('Dock #');
      case event_string.includes('gate'):
        return t('Gate #');
      case event_string.includes('ist'):
        return t(event.event_title);
      case event_string.includes('call_log'):
        return t(event.call_type);
      case event_string.includes('Softdata Update'): 
        return `Softdata Updates`;
      case event_string.includes('Custom Consignment Update'):
        return `Custom Consignment Update`;
      default:
        return t('Consignment Status');
    }
  };

  const formatEventType = (event) => {
    const {t} = useTranslation();
    switch (event.type) {
      case 'add_pgi':
        return t('PGI Added');
      case 'add_booking_hu_details':
        return t('Booking HU Details Added');
      case 'assigntoworker':
        return t('Assigned to Worker');
      case 'startfordelivery':
        return t('Out for Delivery');
      case 'reacheddestination':
        return t('Reached Destination');
      case 'rto':
        return t('RTO');
      case 'auto_rto_failed':
        return t('Auto RTO Failed');
      case 'revoke_rto':
        return t('Revoke RTO');
      case 'attempted':
        return (
          startCase(camelCase(event.type)) +
          ' (Reason - ' +
          (event.reason || event.rejection_reason || event.failure_reason) +
          ')'
        );
      case 'intransittohub':
        return t('Intransit to Hub');
      case 'outscan_at_hub':
        return t('Outscan at Hub');
      case 'reachedathub':
        return t('Reached at Hub');
      case 'ewb_not_required':
        return t('EWB Not Required');
      default:
        return t(startCase(camelCase(event.type)));
    }
  };

  const eventCollapsible = (event) => {
    const event_string = event.event_string;
    switch (true) {
      case event_string.includes('softdata_upload'):
      case event_string.includes('softdata_update'):
      case event_string.includes('hub'):
      case event_string.includes('trip'):
      case event_string.includes('rider'):
      case event_string.includes('dock'):
      case event_string.includes('gate'):
      case event_string.includes('assigned_courier_partner'):
      case event_string.includes('intransittohub'):
      case event_string.includes('outscan_at_hub'):
      case event_string.includes('reachedathub'):
      case event_string.includes('call_log'):
      case event_string.includes('Pickup Completed'):
      case event_string.includes('Not Picked Up'):
      case event_string.includes('Delivered'):
      case event_string.includes('RVP Delivered'):
      case event_string.includes('RTO Delivered'):
      case event_string.includes('RTO Attempted'):
      case event_string.includes('Attempted'): 
      case event_string.includes('Softdata Update') && Object.keys(event.final_data || {}).length > 0:
      case event_string.includes('Custom Consignment Update') && Object.keys(event.final_data || {}).length > 0:
      return true;
      default: return false;
    }
  };

  const handleJSONViewerButtonClick = (e, event) => {
    e.stopPropagation();
    if (event !== selectedEvent) {
      setShowJSONDiff(!showJSONDiff);
      setSelectedEvent(event);
    }
    else {
      setShowJSONDiff(false);
    }
  };

  const fetchHeaderData = (event) => {
    const {t} = useTranslation();
    const event_string = event.event_string;
    switch (true) {
      case event_string.includes('softdata_upload'):
      case event_string.includes('softdata_update'): {
        return (
          <p className={classes.textIndent8}>
            <a href="#" className={classes.activeLink}>
              {data.reference_number}
            </a>
          </p>
        );
      }
      case event_string.includes('hub') &&
        !(
          event_string.includes('intransittohub') ||
          event_string.includes('outscan_at_hub') ||
          event_string.includes('reachedathub')
        ): {
        return (
          <p className={classes.textIndent11}>
            <Link
              to={'/dashboard/setup/hubs?searchHub=' + event.hub_code}
              className={classes.activeLink}
            >
              {event.hub_code}
            </Link>
          </p>
        );
      }
      case event_string.includes('trip'):
      case event_string.includes('rider'): {
        return (
          <p className={classes.textIndent12}>
            <Link
              to={{
                pathname: getRetailTripManagerRoute(),
                state: {
                  trip_id: data.trip_organisation_reference_number,
                },
              }}
              className={classes.activeLink}
            >
              {data.trip_organisation_reference_number}
            </Link>
          </p>
        );
      }
      case event_string.includes('call_log'): {
        return <p className={classes.textIndent12}>{event.call_status}</p>;
      }
      case event_string.includes('dock'): {
        return (
          <p className={classes.textIndent8}>
            <a href="#" className={classes.activeLink}>
              {data.dock_id}
            </a>
          </p>
        );
      }
      case event_string.includes('gate'): {
        return (
          <p className={classes.textIndent8}>
            <a href="#" className={classes.activeLink}>
              {data.gate_id}
            </a>
          </p>
        );
      }
      case event_string.includes('ist'): {
        return (
          <p className={`${classes.dark45} ${classes.textIndent1dot8}`}>
            {t(event.event_title)}
          </p>
        );
      }
      case event_string.includes('Softdata Update'): {
        return (
          <div style = {{ display: 'flex', width: '100%' }}> 
            <p className={`${classes.textIndent6} ${classes.dark85}`}>
              {Object.keys(event?.final_data || {}).length} Changes
            </p>
            <div style = {{ flexGrow: 1 }} /> 
            { 
              Object.keys(event.final_data || {}).length > 0 && (<Button
              className={classes.toggleDetails}
              size="small"
              onClick={(e) =>  handleJSONViewerButtonClick(e, event) }
              >
                View JSON
              </Button>)
            }
          </div>
        );
      }
      case event_string.includes('Custom Consignment Update'): {
        return (
          <div style = {{ display: 'flex', width: '100%' }}> 
            <p className={`${classes.textIndent6} ${classes.dark85}`}>
              {Object.keys(event?.final_data || {}).length} Changes
            </p>
            <div style = {{ flexGrow: 1 }} /> 
            { 
              Object.keys(event.final_data || {}).length > 0 && (<Button
              className={classes.toggleDetails}
              size="small"
              onClick={(e) =>  handleJSONViewerButtonClick(e, event) }
              >
                View JSON
              </Button>)
            }
          </div>
        );
      }
      case event_string.includes('intransittohub'):
      case event_string.includes('outscan_at_hub'):
      case event_string.includes('reachedathub'):
      default: {
        const eventType = formatEventType(event);
        return (
          <p className={`${classes.textIndent6} ${classes.dark85}`}>
            {t(eventType)}
          </p>
        );
      }
    }
  };

  const generateAnchorLink = (data) => {
    const {t} = useTranslation();
    const entry = [];
    data?.forEach((recording) => entry.push(<a href={recording} target={'_blank'}>{t('Click Here')}<br></br></a>));
    return entry;
  };
  const renderGalleryModal = (imageArray: any[], videoArray: any[]) => {
    const {t} = useTranslation();
    if (!imageArray || !videoArray) return 'Not Available';
    return (
      <a onClick={() => {
        setGalleryModalData({
          images: imageArray,
          videos: videoArray,
        });
        setIsGalleryModalVisible(true);
      }}
      >
        {t('View')}
      </a>
    );
  };

  const renderBooleanData = (key,data) => {
    const {t} = useTranslation();
    switch(key){
      case 'otp_generated':
      case 'otp_success':
        return data ? t('Yes') : t('No');
      default:
        return isBoolean(data) ? data?.toString() : 'Not Available';
    }
  };
  const fetchEventDetails = (event) => {
    const event_string = event.event_string;
    switch (true) {
      case event_string.includes('softdata_upload'):
      case event_string.includes('softdata_update'):
        return details.filter(
          (detail) => detail.header === 'consignment_panel',
        );
      case event_string.includes('hub'):
        return details.filter((detail) => detail.header === 'hub_panel');
      case event_string.includes('trip'):
      case event_string.includes('rider'): return details.filter((detail) => detail.header === 'trip_panel');
      case event_string.includes('dock'): return details.filter((detail) => detail.header === 'dock_panel');
      case event_string.includes('gate'): return details.filter((detail) => detail.header === 'gate_panel');
      case event_string.includes('Pickup Completed'): return details.filter((detail) => detail.header === 'pickup_completed_panel');
      case event_string.includes('Not Picked Up'): return details.filter((detail) => detail.header === 'not_picked_up_panel');
      case event_string.includes('Delivered'): return details.filter((detail) => detail.header === 'delivered_panel');
      case event_string.includes('Attempted'): return details.filter((detail) => detail.header === 'attempted_panel');
      case event_string.includes('RTO Delivered'): return details.filter((detail) => detail.header === 'rto_delivered_panel');
      case event_string.includes('RTO Attempted'): return details.filter((detail) => detail.header === 'rto_attempted_panel');
      case event_string.includes('RVP Delivered'): return details.filter((detail) => detail.header === 'rvp_delivered_panel');
      case event_string.includes('assigned_courier_partner'): return details.filter((detail) => detail.header === 'courier_partner_panel');
      case event_string.includes('call_log'): return details.filter((detail) => detail.header === 'call_log_panel');
      case event_string.includes('intransittohub'):
      case event_string.includes('outscan_at_hub'):
      case event_string.includes('reachedathub'):
        return details.filter((detail) => detail.header === 'hub_panel');
      case event_string.includes('ist'):
      default:
        return [];
    }
  };

  const isCollapsible = (data) => {
    const {t} = useTranslation();
    return data ? t('icon') : t('disabled');
  };

  const getContentToDisplay = (data,timelineEvents,column) => {
    if(column.type === 'boolean'){
      return renderBooleanData(column.key,timelineEvents[column.key]);
    }
      switch(column.key){
        case 'created_at':
          return formatDateTime(Number(timelineEvents.event_time));
        case 'hub_code':
          return timelineEvents.hub_code !== null && timelineEvents.hub_code !== undefined ?
            <Link to={'/dashboard/setup/hubs?searchHub=' + timelineEvents.hub_code} className={classes.activeLink}>
              {timelineEvents.hub_code}
            </Link>
          : 'Not Available';
        case 'call_recording_url':
          return Array.isArray(timelineEvents.call_recording_url)
            && timelineEvents.call_recording_url.length ?
          <div>
            {generateAnchorLink(timelineEvents.call_recording_url)}
          </div>
          : 'Not Available';
        case 'trip_id':
          return data.trip_organisation_reference_number !== null &&
            data.trip_organisation_reference_number !== undefined ?
          <Link to={{
            pathname: getRetailTripManagerRoute(),
            state: {
              trip_id: data.trip_organisation_reference_number,
            },
          }} className={classes.activeLink}>
            {data.trip_organisation_reference_number}
          </Link>
          :
          'Not Available';
        case 'event_proof_images':
          return Array.isArray(timelineEvents.event_proof_images) &&
            timelineEvents.event_proof_images.length ?
          <div>
            {renderGalleryModal(timelineEvents.event_proof_images, [])}
          </div>
          : 'Not Available';
        case 'task_failure_reason':
          return timelineEvents.task_failure_reason ? timelineEvents.task_failure_reason : 'Not Available';
        case 'updated_by':
          return timelineEvents.employee_code ? timelineEvents.employee_code : timelineEvents.call_logs_updated_by ? timelineEvents.call_logs_updated_by : timelineEvents.worker_code ? timelineEvents.worker_code : 'Not Available';
        case 'message':
          return timelineEvents.message ? isValidUrl(timelineEvents.message) ? generateAnchorLink([timelineEvents.message]) : timelineEvents.message :'Not Available';
        default:
          return ((timelineEvents[column.key] !== null && timelineEvents[column.key] !== undefined)?
                              timelineEvents[column.key]
                            :
                              (column.value !== null && column.value !== undefined ? column.value : 'Not Available'));
      }

  };
  let eventDetail = [];
  for (let i = 0; i < timelineEvents?.length; ++i) {
    timelineEvents[i].event_title = fetchTitle(timelineEvents[i].event_string);
    timelineEvents[i].collapsible = eventCollapsible(timelineEvents[i]);
    timelineEvents[i].event_header = (
      <div className={classes.timelineEvent}>
        <p
          className={`${classes.timelineEventHeader} ${
            timelineEvents[i].collapsible ? '' : classes.textIndent1dot7
          }`}
        >
          {fetchHeader(timelineEvents[i])}
        </p>
        <div style={{ width: '50%'}}> 
          {fetchHeaderData(timelineEvents[i])}
        </div>
      </div>
    );
    eventDetail = fetchEventDetails(timelineEvents[i]);

    timelineEvents[i].eventDetails = (
      <Panel
        header={timelineEvents[i].event_header}
        key={i.toString()}
        showArrow={timelineEvents[i].collapsible}
        collapsible={isCollapsible(timelineEvents[i].collapsible)}
      >

        { timelineEvents[i].type === 'softdata_update' ? <DiffViewer classes={classes} oldData={timelineEvents[i]?.initial_data || {}} newData={timelineEvents[i]?.final_data || {}} /> : null }
        { timelineEvents[i]?.type === 'custom_consignment_update' ? <DiffViewer classes={classes} oldData={timelineEvents[i]?.initial_data || {}} newData={timelineEvents[i]?.final_data || {}} /> : null }
        <div className={classes.tabsCardsContainer}>
          {eventDetail?.map((card) => {
            return (
              <Row gutter={40} key={Math.random()}>
                {card.columns?.map((x) => {
                  return (
                    <Col span={x?.size ? 6 * x.size : 6} key={Math.random()}>
                      <div className={classes.column}>
                        <div className={classes.hintHeader}>
                          {x.pretty_name}
                        </div>
                        <div className={classes.content}>
                          {getContentToDisplay(data,timelineEvents[i],x)}
                        </div>
                      </div>
                    </Col>
                  );
                })}
              </Row>
            );
          })}
        </div>
      </Panel>
    );
  }
  const eventsList = timelineEvents?.map(function (event) {
    return (
      <Timeline.Item color="#5CDBD3" dot="&#9679;" key={Math.random()}>
        <Row key={Math.random()}>
          <Col span={6}>
            <Row className={classes.eventTime} key={Math.random()}>
              {moment(event.event_time).format('DD MMM, YYYY, HH:mm A')}
            </Row>
            <Row className={classes.eventName} key={Math.random()}>
              {event.event_title}
            </Row>
          </Col>
          <Col span={16} className={classes.eventDetailsColumn}>
            <Collapse defaultActiveKey={expandAllDetails}>
              {event.eventDetails}
            </Collapse>
          </Col>
        </Row>
      </Timeline.Item>
    );
  });
  const {t} = useTranslation();
  return (
    <div className={classes.tableContainer}>
      {timelineEvents?.length > 0 ? (
        <div className={classes.expandBtn}>
          <Button
            className={classes.toggleDetails}
            size="small"
            onClick={() => toggleAllDetails(true)}
          >
            {t('Expand All')}
          </Button>
          <Button
            className={classes.toggleDetails}
            size="small"
            onClick={() => toggleAllDetails(false)}
          >
            {t('Collapse All')}
          </Button>
        </div>
      ) : (
        ''
      )}
      {isGalleryModalVisible && (
        <GalleryModal
          isVisible={isGalleryModalVisible}
          imageVideoGalleryData={galleryModalData}
          handleModalClose={() => { setIsGalleryModalVisible(false); }}
        />
      )}
      { showJSONDiff ? <JSONDiffModal classes={classes} onClose={() => setShowJSONDiff(false)} oldValue={selectedEvent?.initial_data || {}} newValue={selectedEvent?.final_data || {}} /> : null }
      <div className={classes.timeline}>
        <Timeline>{eventsList}</Timeline>
      </div>
    </div>
  );
};

const mapStateToProps = ({ masterData }) => {
  return {
    viewType: masterData.viewType,
  };
};

const ConsignmentLogTabStyled = withStyles(styles, { injectTheme: true })(
  ConsignmentLogTab,
);
export default connect(mapStateToProps, null)(ConsignmentLogTabStyled);
