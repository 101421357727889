import * as React from 'react';
import i18n from '../../i18n';
import withStyles, { WithStylesProps } from 'react-jss';
import { CheckOutlined } from '@ant-design/icons';
import MenuIcon from '../common/icons/MenuIcon';
import Media from 'react-media';
import MenuModal from '../pages/FoodDashboard/MenuModal';
import TrackConsignmentModal from '../common/navbarModals/trackConsignment';
import PicodeAvailablityIcon from '@assets/images/destinationServiceablity';
import classNames from 'classnames';
import HelpCenter from './HelpCenter';
import QuickSearchModal from '../common/QuickSearchModal';
import DstServiceableCheck from '../common/DestinationServiceabilityModal';
import { MOBILE_BREAK_POINT, applicationType } from '../../globals';
import { bindActionCreators } from 'redux';
import { trackGaEvents } from 'src/utils/googleAnalyticsHelper';
import { getViewControl } from '../../utils/genericConfig/utils';
import { TrackingIcon } from '../common/icons/TrackingIcon';
import { BUTTON_CLICK } from 'src/utils/gaConstants';
import { toggleSideMenu } from '../../actions/navigationActions';
import { navbarStyles } from './Navbar.styles';
import { connect } from 'react-redux';
import { RouteChildrenProps, withRouter } from 'react-router';
import { ArrowLeftOutlined, QuestionCircleOutlined, ReloadOutlined, SwapOutlined, ThunderboltFilled } from '@ant-design/icons';
import { Avatar, Button, Divider, Dropdown, Menu, Space, message,Select  } from 'antd';
import { logoutUserAndClearCache } from 'src/api/utils';
import ChangePasswordModal from '../common/navbarModals/changePassword/changePasswordModal';
import SideMenu from '../sidemenu/SideMenu';
import { LogoutIcon } from '../common/icons/LogoutIcon';
import SwitchViewType from '../common/SwitchViewType';
import { getActivityLogRoute } from 'src/routing/utils';
import { Link } from 'react-router-dom';
import { ActivityLogIcon, LanguageIcon } from '../common/icons/NavbarMenuIcons';
import { WithTranslation, withTranslation } from 'react-i18next';
import { isMobile } from 'react-device-detect';
import NotificationIcon from './Notifications';
import { isInAppNotificationAllowed } from 'src/utils/utils';

const { useState } = React;

interface IProps extends RouteChildrenProps, WithStylesProps<ReturnType<typeof navbarStyles>>, WithTranslation {
    hideMenuBar?: boolean;
    pageTitle: string;
    showPageTitleForMobile: boolean;
    shouldDisplayBack?: boolean;
    toggleSideMenu: () => any;
    leftComponents: any;
    rightComponents: any;
    showOndemandOptions?: boolean;
    showHybridView?: boolean;
    viewType?: string;
    showTrackOption?: boolean;
    showServiceabilityIcon?: boolean;
    isCompact?: boolean;
    viewTypeForSwitchView?: string;
    showProductBoardHelpIcon?: boolean;
    hideProductUpdates?: boolean;
    masterData: any;
    username: string;
    email: string;
    showLogisticsExpressOptions: boolean;
    showGenericSideMenu: boolean;
    showLanguageChangeOption: boolean;
    name: string;
}

const Navbar = (props: IProps) => {
    const [menuModalVisible, setMenuModalVisible] = useState<boolean>(false);
    const [trackVisible, setTrackVisible] = useState<boolean>(false);
    const [helpVisible, setHelpVisible] = useState<boolean>(false);
    const [quickSearchVisible, setQuickSearchVisible] = useState<boolean>(false);
    const [showDeliveryServiceability, setShowDeliveryServiceability] = useState<boolean>(false);
    const [isChangePassowrdModalVisible, setChangePassowrdModalVisible] = useState<boolean>(false);

    const {
        history,
        classes,
        hideMenuBar,
        shouldDisplayBack,
        toggleSideMenu,
        showPageTitleForMobile,
        pageTitle,
        leftComponents,
        rightComponents,
        showOndemandOptions,
        showHybridView,
        viewType,
        showTrackOption,
        showServiceabilityIcon,
        isCompact,
        viewTypeForSwitchView,
        showProductBoardHelpIcon,
        hideProductUpdates,
        masterData,
        username,
        name,
        email,
        showLogisticsExpressOptions,
        showGenericSideMenu,
        showLanguageChangeOption,
        t,
        hideNotificationIcon,
    } = props;

    const handleBackClick = () => {
        history.goBack();
    };

    const renderMenu = () => {
        if (hideMenuBar) {
            return null;
        }
        const ondemandViewTypes = ['ondemand', 'on_demand_cod'];
        if (showOndemandOptions && (!viewTypeForSwitchView || ondemandViewTypes.includes(viewTypeForSwitchView))) {
            return (
                <MenuIcon
                    className={classes.hamburger}
                    onClick={toggleSideMenu}
                />
            );
        }
        return (
            <BackOrMenu />
        );
    };

    const BackOrMenu = () => {
        if (shouldDisplayBack) {
            return (
                <Media query={`(max-width: ${MOBILE_BREAK_POINT}px)`}>
                    {matches =>
                        matches ? (
                            <ArrowLeftOutlined
                                className={classes.hamburger}
                                onClick={handleBackClick}
                            />
                        ) : (<div id="sidemenu_hamburger_icon">
                            <MenuIcon
                                className={classes.hamburger}
                                onClick={toggleSideMenu}
                            />
                        </div>)
                    }
                </Media>
            );
        }

        return (
            <div id="sidemenu_hamburger_icon">
                <MenuIcon
                    className={classes.hamburger}
                    onClick={toggleSideMenu}
                />
            </div>
        );
    };

    const renderMenuModal = () => {
        if (!menuModalVisible) {
            return null
        }
        return (
            <MenuModal
                isVisible={menuModalVisible}
                toggleVisible={res => setMenuModalVisible(res)}
            />
        );
    };

    const renderTrack = () => {
        if (!trackVisible) {
            return null;
        }
        return (
            <TrackConsignmentModal
                isVisible={trackVisible}
                onModalClose={() => setTrackVisible(false)}
            />
        );
    };

    const renderHelp = () => {
        if (!helpVisible) {
            return null;
        }
        return (
            <HelpCenter
                isVisible={helpVisible}
                onModalClose={() => setHelpVisible(false)}
            />
        );
    };

    const renderQuickSearch = () => {
        if (!masterData.show_logistics_express_options && !masterData.show_ondemand_options) {
            return null;
        }
        return (
            <QuickSearchModal
                masterData={masterData}
                isModalVisible={quickSearchVisible}
                setModalVisibility={(val) => setQuickSearchVisible(val)}
            />
        )
    };

    const showHelpModal = () => {
        trackGaEvents({ actionType: BUTTON_CLICK }, { label: t("Updates & Help Center") });
        setHelpVisible(true);
    };

    const renderRightChilds = () => {
        if (!rightComponents?.length) {
            return null;
        }
        return rightComponents.map((item, i) => <div className={classes.toolContainer} key={i}> {item} </div>)
    };

    const renderServiceabilityIcon = () => {
        if (!showServiceabilityIcon || !masterData?.show_destination_serviceability_icon) {
            return null;
        }

        return (
            <div>
                <div className={classes.trackIcon}
                    onClick={e => setShowDeliveryServiceability(true)}>
                    <PicodeAvailablityIcon style={{ fontSize: 24 }} />
                </div>
                <div>
                    {
                        showDeliveryServiceability &&
                        <DstServiceableCheck hideContent={e => setShowDeliveryServiceability(false)} />
                    }
                </div>
            </div>
        );
    };

    const renderHybridView = () => {
        let rightComponentKeys: any = [];
        if (viewType) {
            const currentViewControl = getViewControl(viewType);
            rightComponentKeys = currentViewControl?.navBar?.right;
        }

        if (showHybridView && (rightComponentKeys?.includes('consignment_track') || showTrackOption)) {
            return (
                <div className={classes.trackIcon}
                    onClick={e => setTrackVisible(true)}>
                    <TrackingIcon />
                </div>
            );
        }
        return null;
    };

    // const renderThunderbolt = () => {
    //     if (!masterData?.show_logistics_express_options && !masterData?.show_ondemand_options) {
    //         return null;
    //     }

    //     return (
    //         <div className={classes.trackIcon}
    //             onClick={e => setQuickSearchVisible(true)}>
    //             <ThunderboltFilled className={classes.helpIcon} />
    //         </div>
    //     );
    // };

    const renderQuestionMark = () => {
        if (!showProductBoardHelpIcon || hideProductUpdates || hideMenuBar) {
            return null;
        }

        return (
            <div className={classes.trackIcon}
                onClick={() => showHelpModal()}>
                <QuestionCircleOutlined className={classes.helpIcon} />
            </div>
        );
    };

    const renderProfile = () => {
        return (
            <Avatar>
                {username && username[0]}
            </Avatar>
        );
    };
    const languageList =  [
      { key:'en', val: 'English' },
      { key:'ba', val:  'Bahasa'  },
      { key: 'ar', val: 'Arabic' },
      { key: 'fr', val: 'French' },
      { key: 'pt', val: 'Portuguese' },
      { key: 'en_MY', val: 'English (Malaysia)' },
      { key: 'en_SC', val: 'English (SC)' },
    ];
    const handleLanguageChange = (val: string) => {
      i18n.changeLanguage(val, (err,t) => {
        if(err) {
          message.error(err);
          return;
        }
        message.success('Language changed successfully');
        window.location.reload();
      });
    };

    const generateOptionsFromKeyValue = (optionsList: any) => {
      const x:any = [];
      if (optionsList) {
      optionsList.forEach((ele:any) => {
        x.push(<Select.Option key={ele.key} value = {ele.key} >{ele.val}{i18n.language === ele.key ?
             <CheckOutlined className={classes.tickStyle}  /> : null}</Select.Option>);
      });
    }
    return x;
    };

    const renderProfileDropdown = () => {
        if (hideMenuBar) {
            return null;
        }
        const dropdownMenu = (
            <div className={classes.profileDropDown}>
                <div key="logo" style={{ justifyContent: 'center', textAlign: 'center' }}>
                    <img style={{ maxHeight: '80px', maxWidth: '100%' }} src={masterData.logoUrl} />
                </div>
                <Divider key="logo-divider" style={{ margin: '16px 0 0 0' }} />
                {/* {username} */}

                <div style={{ display: 'flex', flexDirection: 'row', padding: '0 16px 0 8px', height: 38, margin: '8px 0 4px 0', alignItems: 'center'}}>
                    {renderProfile()}
                    <div style={{ display: 'flex', flexDirection: 'column', margin: '0 5px'}}>
                        <div className={classes.customDivForName}>
                            {name || username}
                        </div>
                        {email &&
                            <div className={classes.customDivForEmail}>
                                {email}
                            </div>
                        }
                    </div>
                </div>
                <Menu mode="inline" style={{ width: 256, fontSize: 12, fontWeight: 600, borderRight: 'none' }}>
                    {masterData.show_view_type_switch ?
                        <Menu.Item
                            key="switchViewType">
                            <SwitchViewType>
                                <SwapOutlined style={{ marginRight: 10 }}/>
                                Switch View
                            </SwitchViewType>
                        </Menu.Item>
                    : null }
                    { (showLogisticsExpressOptions
                        || showHybridView
                        || showGenericSideMenu
                    ) && applicationType === 'ops'
                    && masterData.show_password_policy ?
                        <Menu.Item
                            key="activityLog"
                            style={{ height: 38 }}
                            icon={<ActivityLogIcon />}>
                            <Link className={classes.linkForActivityLog} to={getActivityLogRoute()}>
                                {t('activity_log')}
                            </Link>
                        </Menu.Item>
                    : null }
                     <Menu.Item
                        key="changePassword"
                        style={{ height: 38 }}
                        onClick={() => setChangePassowrdModalVisible(true)}
                        icon={<ReloadOutlined />}>
                            {t('reset_password')}
                    </Menu.Item>
                    {
          showLanguageChangeOption && (
            <Menu.Item style = {{marginLeft: '0px', marginBottom: '20px', marginTop: '10px'}}
             onClick={(e) => e.domEvent.stopPropagation()}
             icon={<LanguageIcon />}
            >
              <Select
                onChange={handleLanguageChange}
                value={t('change_language')}
                defaultValue={i18n.language}
                style={{width: '150px'}}
                dropdownClassName={classes.dropdownStyles}
                className={classes.changeLanguagedropdown}
                >
                  {generateOptionsFromKeyValue(languageList)}
              </Select>
              </Menu.Item>
        )}
                    <Menu.Item
                        key="logout" onClick={() => logoutUserAndClearCache()}
                        className={classes.logoutIconStyles} icon={<LogoutIcon />}>
                        {t('logout')}
                    </Menu.Item>
                </Menu>
            </div>
        );
        return (
            <Dropdown overlay={dropdownMenu} trigger={['click']}>
                <Button type="link" style={{ padding: 0, marginLeft: 8, width: '32px' }}>
                    {renderProfile()}
                </Button>
            </Dropdown>
        );
    };

    const renderNotificationIcon = () => {
        if (hideNotificationIcon || !isInAppNotificationAllowed()) {
            return null;
        }
        return <NotificationIcon />;
    };

    const renderRightComponents = () => {
        return (
            <div className={classNames(classes.filterGroupToolsRight, {
                [classes.noMargin]: isCompact,
            })}>
                {renderRightChilds()}
                {/* {renderThunderbolt()} */}
                {leftComponents?.map(
                    (item, i) => <div className={classes.toolContainer} key={i}> {item} </div>,
                )}
                {renderServiceabilityIcon()}
                {renderHybridView()}
                {renderQuestionMark()}
                {renderNotificationIcon()}
                {renderProfileDropdown()}
            </div>
        );
    };

    // const renderLeftComponents = () => {
    //     return (
    //         <div className={classNames(classes.filterGroupToolsLeft, {
    //             [classes.noMargin]:
    //                 isCompact
    //         })}>
    //             {leftComponents?.map(
    //                 (item, i) => <div className={classes.toolContainer} key={i}> {item} </div>,
    //             )}
    //         </div>
    //     );
    // };

    const renderMenuAndPageTitle = () => {
        return (
            <div className={classes.leftDiv} >
                <span>
                    {renderMenu()}
                </span>
                <span className={classNames(classes.pageTitle, { [classes.noMargin]: isCompact })}
                    style={{
                        zIndex: isMobile && !showPageTitleForMobile ? '499' : '501'
                    }}>
                    {pageTitle || 'SHIPSY'}
                </span>
            </div>
        );
    };

    const renderChangePassword = () => {
        if (!isChangePassowrdModalVisible) return;
        return (
            <ChangePasswordModal
                isModalActive={isChangePassowrdModalVisible}
                onModalClose={() => setChangePassowrdModalVisible(false)}
            />
        );
    };

    return (
        <div className={classes.navbar}>
            {renderMenuAndPageTitle()}
            {/* {renderLeftComponents()} */}
            {renderRightComponents()}
            {renderMenuModal()}
            {renderTrack()}
            {renderHelp()}
            {renderQuickSearch()}
            {renderChangePassword()}
        </div>
    );

};

const mapStateToProps = ({ masterData }, ownProps) => {
    return {
        masterData,
        showOndemandOptions: masterData.show_ondemand_options,
        showHybridView: masterData.show_logistics_hybrid_express_view,
        viewTypeForSwitchView: ownProps.viewType || masterData.viewType,
        showProductBoardHelpIcon: !masterData.hide_product_board,
        username: masterData.user_data?.username,
        name: masterData.user_data?.name,
        email: masterData.user_data?.email,
        showGenericSideMenu: masterData?.ops_dashboard_config?.parts_to_show?.ops_sidemenu_list_enable,
        showLogisticsExpressOptions: masterData?.show_logistics_express_options,
        showLanguageChangeOption : masterData.show_language_change_option || false,
    };
};
const mapDispatchToProps = (dispatch) => {
    return bindActionCreators({
        toggleSideMenu,
    }, dispatch);
};

const NavbarWithTranslation = withTranslation()(Navbar);
const NavbarStyled = withStyles(navbarStyles, { injectTheme: true })(NavbarWithTranslation);
const NavbarConnected = connect(mapStateToProps, mapDispatchToProps)(NavbarStyled);
export default withRouter(NavbarConnected) as React.ComponentType<any>;
