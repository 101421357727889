import * as React from 'react';
import withStyles from 'react-jss';
import { CssType, ThemeType } from '@theming/jssTypes';
import { connect } from 'react-redux';
import { useHistory } from 'react-router';
import { useDispatch, useSelector } from 'react-redux';
import {
  CloseOutlined,
  ScanOutlined,
  LoadingOutlined,
} from '@ant-design/icons';
import { Modal, message, Input, Table, Select, Radio } from 'antd';
import { getReverseNprReasons } from '@api/genericConsignmentView';
import FailureArrayHandler from '@components/common/FailureArrayHandler';
import {
  getCancellationReasons,
  validateInScannedConsignments,
  getRTOReasons,
  getEligibleHubsForDestinationUpdate,
  updateConsignmentDestinationHub,
  getReasonsForAction,
  getImageURL,
} from '@api/dashboard';
import MoveConsignmentsModal from '@components/pages/retail/dashboard/MoveConsignmentsModal';
import BulkDeleteConsignment from '@components/pages/retail/trip/BulkDeleteConsignment';
import { downloadFileData, getCodeFreeLabelType } from '@src/utils/utils';
import { getAllExceptionDataBags, getAppliedExceptions } from '@api/TMSDashboard/Setup';
import ScanErrorModal from '@components/common/ScanErrorModal';
import * as lodash from 'lodash';
import { fetchPublishedLabelList } from '@api/labelSetup';
import PodUploadModal from '../../dashboard/PodUploadModal';
import { PrettyOption } from '@src/components/pages/CarrierPartner/CourierFieldMaster/Commons';
import HubSearch from '@components/common/HubSearch';
import { debounce } from 'lodash';
import { GenericBulkUploadService } from 'src/components/common/GenericBulkUploadService';
import { fetchActionReason } from './CustomActionBarActions';
import uploadIcon from '../../../../assets/images/upload.svg';
import Dragger from 'antd/lib/upload/Dragger';
import { putImageS3 } from 'src/api/genericTransactions';

const RadioGroup = Radio.Group;

const Option = Select.Option;
const styles = (theme: ThemeType): CssType => ({
  scanCN: {
    margin: '10px 0',
  },
  labelType: {
    width: '100%',
  },
  tableContainer: {
    flex: '1 1 0',
    overflow: 'auto',
    height: 300,
    marginTop: 15,
    boxShadow: '0 1px 4px 0 rgba(0, 0, 0, 0.25)',
    '& .ant-table-tbody > tr > td, .ant-table-thead > tr > th': {
      padding: 9,
    },
    '& button': {
      float: 'right',
    },
  },
  refNo: {
    display: 'flex',
    justifyContent: 'space-between',
  },
  crossIcon: {
    color: 'red',
  },
  reasonSelect: {
    width: '100%',
  },
  reason: {
    fontSize: '12px',
  },
  piecesDetailContainer: {
    display: 'flex',
    gap: '1rem',
    marginTop: '1.5rem',
  },
  customPrimaryButton: {
    backgroundColor: theme.colors.primaryColor,
    color: theme.colors.textOnDarkBg,
  },
});
const ScanSelectedModal = (props) => {
  const [confirmLoading, setConfirmLoading] = React.useState(false);
  const [inputValue, setInputValue] = React.useState('');
  const [notes, setNotes] = React.useState('');
  const [reasonName, setReasonName] = React.useState('');
  const [reasonCode, setReasonCode] = React.useState('');
  const [reason, setReason] = React.useState<any>(undefined);
  const [reasonList, setReasonList] = React.useState<any>([]);
  const [cnList, setCNList] = React.useState<any>([]);
  const [isErrorModalVisible, setErrorModalVisible] = React.useState(false);
  const [failureArray, setFailureArray] = React.useState([]);
  const [isSmallLabel, setSmallLabel] = React.useState(false);
  const [selectedLabel, setSelectedLabel] = React.useState(null);
  const [exception, setExceptionCode] = React.useState<any>(undefined);
  const [exceptionsList, setExceptionsList] = React.useState<any>([]);
  const [appliedExceptionsList, setAppliedExceptionsList] = React.useState<any>([]);
  const [appliedExceptionCode, setAppliedExceptionCode] = React.useState<any>(undefined);
  const [applyExceptionReasonsList, setApplyExceptionReasonsList] = React.useState<any>([]);
  const [exceptionsListwithReasons, setExceptionsListwithReasons] = React.useState<any>([]);
  const [maxPhotos, setMaxPhotos] = React.useState<number>(0);
  const [fileList, setFileList] = React.useState([] as any[]);

  const [piecesList, setPiecesList] = React.useState<any>({
    completePieces: [],
    missingPieces: [],
    partialPieces: [],
    partialCN: [],
  });
  const [showErrorModal, setShowErrorModal] = React.useState(false);
  const [validationLoader, setValidationLoader] = React.useState(false);
  const [labelList, setLabelList] = React.useState<any>([]);
  const [cnPodInfo, setCnPodInfo] = React.useState<any>({});
  const [podUploadModalVisible, setPodUploadModalVisible] = React.useState(false);
  const [cnForPodUpdate, setCnForPodUpdate] = React.useState(null);
  const [selectedHubCode, setSelectedHubCode] = React.useState(null);
  const [hubList, setHubList] = React.useState(null);

  const dispatch = useDispatch();
  const onHoldData = useSelector(state => state.reasonsDataReducer['ON_HOLD']);
  const releaseOnHoldData = useSelector(state => state.reasonsDataReducer['RELEASE_ON_HOLD']);
  const markLostData = useSelector(state => state.reasonsDataReducer['MARK_LOST']);
  const applyExceptionData = useSelector(state => state.reasonsDataReducer['APPLY_EXCEPTION']);
  const releaseExceptionData = useSelector(state => state.reasonsDataReducer['RELEASE_EXCEPTION']);

  const {
    consignmentList,
    onModalClose,
    currHub,
    isVisible,
    classes,
    viewType,
    bulkLabelType,
    currentActionConfig,
    hideNotes,
    actionStatus,
    isMoveCNModalVisible,
    isBulkDeleteCNModalVisible,
    onOkClick,
    showA6Option,
    showCustomLabel,
    customLabelOptions,
    useCustomLabelOnly,
    enablePieceScanning,
    enableCodeFreeLabel,
    mandatoryPaymentDetails,
    allowPartialCod,
    collectionModeOptions,
    isPODUploadOptional,
    showInputTimestampForDelivery,
  } = props;
  const history = useHistory();
  const pieceScanningActions = [
    'cn_label_selected',
    'mark_delivered_selected',
    'mark_onhold_selected',
    'release_onhold_selected',
    'cancel_selected',
  ];

  const enablePieceScanningForAction =
    enablePieceScanning &&
    pieceScanningActions.includes(currentActionConfig.id);

  React.useEffect(() => {
    if(['update_consignment_destination_hub_selected'].includes(currentActionConfig.id)){
      handleHubSearchChange('', true);
    }
  }, []);

  React.useEffect(() => {
    setCNList(consignmentList);
    if (currentActionConfig.requireReason ||
      [
        'set_rto_selected', 'mark_onhold_selected', 'release_onhold_selected', 'mark_lost_selected', 'apply_exception_selected', 'release_exception_selected'
      ].includes(currentActionConfig.id)
    ) {
      fetchReasons();
    }

    if(currentActionConfig.id === 'release_exception_selected'){
      fetchAppliedExceptionsData();
    }

    if (currentActionConfig.showExceptionCodeWithNotes) {
      fetchExceptionsData();
    }

    if (
      ['cn_label_selected', 'cn_label_bulk_upload'].includes(
        currentActionConfig.id,
      ) &&
      enableCodeFreeLabel
    ) {
      fetchCodeFreeLabels();
    }
  }, [onHoldData, releaseOnHoldData, markLostData, applyExceptionData, releaseExceptionData]);

  const fetchCodeFreeLabels = async () => {
    const response = await fetchPublishedLabelList();
    if (response.isSuccess) {
      const updatedLabels = response.data?.metadata_list?.filter(
        (label) => label.is_active,
      );
      setLabelList(updatedLabels);
    } else {
      message.error(response.errorMessage);
    }
  };

  const renderNotesInDropdown = () => {
    const { showCustomOnHoldReasons } = props;
    switch(currentActionConfig.id) {
      case 'mark_onhold_selected':
      case 'release_onhold_selected':
      case 'mark_lost_selected':
      case 'apply_exception_selected':
      case 'release_exception_selected':
        return showCustomOnHoldReasons ? true : false;
      case 'set_rto_selected':
        return reasonList.length > 0;
      default:
        return false;
    }
  };

  const fetchReasons = async () => {
    setConfirmLoading(true);
    let response;
    if (currentActionConfig.id === 'mark_npr_selected') {
      response = await getReverseNprReasons({ hub_id: currHub?.id });
    } else if (currentActionConfig.id === 'cancel_selected') {
      response = await getCancellationReasons();
    } else if (currentActionConfig.id === 'set_rto_selected') {
      response = await getRTOReasons();
    } else if (currentActionConfig.id === 'mark_onhold_selected') {
      await dispatch(fetchActionReason('ON_HOLD'));
      response = onHoldData;
    } else if (currentActionConfig.id === 'release_onhold_selected') {
      await dispatch(fetchActionReason('RELEASE_ON_HOLD'));
      response = releaseOnHoldData;
    } else if (currentActionConfig.id === 'mark_lost_selected') {
      await dispatch(fetchActionReason('MARK_LOST'));
      response = markLostData;
    } else if (currentActionConfig.id === 'release_exception_selected') {
      await dispatch(fetchActionReason('RELEASE_EXCEPTION'));
      response = releaseExceptionData;
    } else {
      response = {
        isSuccess: true,
        data: {},
      };
    }
    if(response && response.isSuccess){
      response && setReasonList(response.data);
    } else if(response){
      message.error(response.errorMessage);
    }
    setConfirmLoading(false);
  };

  const fetchExceptionsData = async () => {
    const response = await getAllExceptionDataBags({ active: true, result_per_page: 50 });
    if (!response.isSuccess) {
      message.error(response.errorMessage);
      return;
    }
    let exceptionsList: any = response.data?.page_data;
    exceptionsList = exceptionsList
      ? exceptionsList.map((exception: any) => ({
          code: exception?.bag_exception_code,
          name: exception?.bag_exception_name,
        }))
      : [];
    let exceptionListWithReasons = response.data?.page_data;
    setExceptionsListwithReasons(exceptionListWithReasons);
    setExceptionsList(exceptionsList);
  };

  const fetchAppliedExceptionsData = async () => {
    if(Array.isArray(consignmentList) && consignmentList.length > 1){
      message.error('Please select only one consignment to release exception');
      return;
    }
    const selectedConsignment = Array.isArray(consignmentList) && consignmentList.length ? consignmentList[0] : null;
    if(!selectedConsignment) {
      message.error('No consignment selected');
      return;
    }
    setConfirmLoading(true);
    const response = await getAppliedExceptions(selectedConsignment);
    if (!response.isSuccess) {
      message.error(response.errorMessage);
      return;
    }
    const exceptionsList = response.data;
    setAppliedExceptionsList(exceptionsList);
    setConfirmLoading(false);
  };

  const getLabelOptions = () => {
    const arr = [];
    if (showA6Option && !useCustomLabelOnly) {
      arr.push('a4', 'a6');
    }
    if (showCustomLabel) {
      customLabelOptions.forEach((elem) => {
        arr.push(elem);
      });
    }
    return arr;
  };

  const columns = [
    {
      title: 'S.No.',
      key: 'index',
      render: (text, record, index) => index + 1,
    },
    {
      title: 'Reference Number',
      key: 'reference_number',
      render: (text, record) => {
        return (
          <div className={classes.refNo}>
            {record}
          </div>
        );
      },
    },
  ];

  const setReferenceNumberForPod = async (referenceNumber) => {
    setCnForPodUpdate(referenceNumber);
    setPodUploadModalVisible(true);
  };

  const getColumsForTable = () => {
    const {showPodModal} = currentActionConfig;
    if(showPodModal){
      const columnToRender = {
        title: 'Delivery Details',
        key: 'delivery_details',
        render: (text, record) => {
          return (
            <div
              onClick={() => setReferenceNumberForPod(record)}
              className={classes.refNo}
            >
              <a>{cnPodInfo[record] ? 'Edit' : 'Add'}</a>
            </div>
          );
        },
      };
      columns.push(columnToRender);
    }

    const actionToRender = {
      title: 'Action',
      key: 'action',
      render: (text, record) => {
        return (
          <div className={classes.refNo}>
            <CloseOutlined
              className={classes.crossIcon}
              onClick={() => handleDeleteCNClick(record)}
            />
          </div>
        );
      },
    };
    columns.push(actionToRender);
    return columns;
  };

  const getPrintLabelParams = () => {
    return {
      reference_number_array: Array.from(cnList),
      hub_id: currHub?.id,
      label_type: bulkLabelType,
      is_small: isSmallLabel,
      showCustomLabel:
        customLabelOptions && customLabelOptions.includes(selectedLabel),
      customLabelSize: selectedLabel,
    };
  };

  const getDefaultParams = () => {
    return {
      consignments: Array.from(cnList),
      hub_id: currHub?.id,
    };
  };

  const getV1ActionsParams = () => {
    const defaultParams = getDefaultParams();
    return {
      ...defaultParams,
      notes,
      reasonName,
      reasonCode,
      status: actionStatus,
      request_type: 'bulk_scan',
    };
  };

  const getActionBodyWithNote = (actionId) => {
    let notesToSet = notes;
    if(actionId !== 'set_rto_selected'){
      notesToSet = reason?.label || reason;
    }

    const defaultParams = getDefaultParams();
    const cnListWithNotes = cnList.map((ele, index) => {
      return {
        reference_number: ele,
        notes: notesToSet,
        reasonCode,
        reasonName,
      };
    });
    return {
      ...defaultParams,
      status: actionStatus,
      consignments: cnListWithNotes,
      hub_id: currHub?.id,
      request_type: 'bulk_upload_via_excel',
    };
  };

  const getExceptionActionsParams = (imageUrlList) => {
    const cnListWithParams = cnList.map((ele, index) => {
      return {
        reference_number: ele,
        notes,
        reason_name: reasonName,
        reason_code: reasonCode,
        image_list: imageUrlList,
        ...(currentActionConfig.showExceptionCodeWithNotes && { exception_code: exception?.key }),
      };
    });
    return {
      consignments: cnListWithParams,
      hub_id: currHub?.id,
      source: 'dashboard',
      request_type: 'bulk_scan',
    };
  };

  const getReleaseExceptionActionsParams = () => {
    const requestParams = {
      consignments: cnList.map((consignment) => ({
        reference_number: consignment,
        notes,
        reason_name: reasonName,
        reason_code: reasonCode,
        exception_code: appliedExceptionCode?.value,
      })),
      hub_id: currHub?.id,
      source: 'dashboard',
      request_type: 'bulk_scan',
    };
    return requestParams;
  };

  const getParamsWithReason = () => {
    const defaultParams = getDefaultParams();
    return {
      ...defaultParams,
      reason_code: reason?.key,
      reason: reason?.label,
    };
  };

  const getCancelParams = () => {
    return {
      consignments: cnList.map((consignment) => {
        return { reference_number: consignment };
      }),
      reason_code: reason?.key,
      reason: reason?.label,
      hub_id: currHub?.id,
    };
  };

  const getMarkDeliveryParams = () => {
    return {
      consignments: cnList.map(el => {
        return {
          reference_number: el,
          payment_details: lodash.get(cnPodInfo[el],'payment_details', []),
          poc_image_url: lodash.get(cnPodInfo[el], 'poc_image_url', ''),
        };
      }),
      hub_id: currHub?.id,
    };
  };

  const getDestinationHubUpdateParams = () => {
    return {
      consignments: cnList.map(el => {
        return {
          reference_number: el,
          hub_code: selectedHubCode,
        };
      }),
      notes,
      hub_id: currHub?.id,
    };
  };

  const getBody = (imageUrlList: any[] = []) => {
    switch (currentActionConfig.id) {
      case 'cn_label_selected':
      case 'cn_copy_label':
      case 'credit_note_selected':
        return getPrintLabelParams();
      case 'mark_rto_selected':
      case 'pickup_and_delivery_selected':
      case 'revoke_pickup_and_delivery_selected':
      case 'mark_onhold_selected':
      case 'release_onhold_selected':
      case 'consignment_sieze_selected':
      case 'shipment_clear_successfully_selected':
      case 'pending_custom_selected':
      case 'mark_clearance_origin_selected':
      case 'mark_clearance_destination_selected':
      case 'inscan_at_gateway_selected':
      case 'pickup_completed_selected':
        return getV1ActionsParams();
      case 'mark_npr_selected':
        return getParamsWithReason();
      case 'cancel_selected':
        return getCancelParams();
      case 'release_exception_selected':
        return getReleaseExceptionActionsParams();
      case 'apply_exception_selected':
        return getExceptionActionsParams(imageUrlList);
      case 'mark_delivered_selected':
        return getMarkDeliveryParams();
      case 'set_rto_selected':
      case 'revoke_delivery_selected':
      case 'mark_lost_selected':
        return getActionBodyWithNote(currentActionConfig.id);
      case 'update_consignment_destination_hub_selected':
        return getDestinationHubUpdateParams();
      default:
        return getDefaultParams();
    }
  };

  const getValidConsignmentList = async () => {
    const completePieces = piecesList.completePieces.map((item) => {
      if (item) return item.reference_number;
    });

    const partialPieces = piecesList.partialPieces.map((item) => {
      if (item) return item.reference_number;
    });

    const res = await validateInScannedConsignments({
      hub_id: currHub?.id,
      consignments: [...completePieces, ...partialPieces],
    });

    const missingPieces = res.data?.missing_consignments;

    if (missingPieces?.length) {
      setPiecesList({
        ...piecesList,
        missingPieces,
      });
      return false;
    }
    return true;
  };

  const handleBulkUploadService = (body) => {
    const getBulkUploadParams = () => {
      switch(currentActionConfig.id){
        case 'update_consignment_destination_hub_selected':
          return {
            heading: 'Change Destination Hub',
            data: body.consignments,
            apiCallFn: updateConsignmentDestinationHub,
            getRequestBody: (arrData) => {
              return {
                consignments: arrData,
                hub_id: body?.hub_id,
                notes: notes,
              };
            },
            failureArrayKeysToShow: ['reference_number', 'message', 'reason'],
          };
        default:
          return {};
      }
    };

    const bulkUploadParams = getBulkUploadParams();
    GenericBulkUploadService.uploadData(bulkUploadParams);
  };

  const handleSubmit = async () => {
    if (!enablePieceScanningForAction && cnList.length === 0) {
      message.warning('Please enter a valid consignment number');
      return;
    }

    if (currentActionConfig.requireReason && !reason) {
      message.error('Please select a reason');
      return;
    }
    if (currentActionConfig.showExceptionCodeWithNotes && !exception) {
      message.error('Please select an exception');
      return;
    }
    if (currentActionConfig.onOkClick) {
      onOkClick();
      return;
    }

    const body = getBody();
    const imageUrlList: string[] = [];
    if(currentActionConfig.useGenericBulkUploaderService){
      handleBulkUploadService(body);
      onModalClose(false);
      return;
    }

    setConfirmLoading(true);
    if (enablePieceScanningForAction) {
      const isValid = await getValidConsignmentList();
      if (!isValid) {
        setShowErrorModal(true);
        setConfirmLoading(false);
        return;
      }
    }
    if (currentActionConfig.id === 'apply_exception_selected') {
      if (maxPhotos > 0 && fileList.length > maxPhotos) {
        message.error(`You can upload a maximum of ${maxPhotos} photos`);
        setConfirmLoading(false);
        return;
      }
      if (maxPhotos > 0 && fileList.length < 1) {
        message.error(`Please upload at least 1 photo for this reason`);
        setConfirmLoading(false);
        return;
      }
    
      for (const fileItem of fileList) {
        const bodyGetUrl = {
          type: 'exception',
          referenceNumber: cnList[0],
        };
    
        try {
          const resGetUrl = await getImageURL(bodyGetUrl);
          const signedImageUrl = resGetUrl?.data?.pocSignedUrl || '';
          const imageUrl = resGetUrl?.data?.pocImageUrl || '';
    
          if (!resGetUrl.isSuccess) {
            message.error(resGetUrl.errorMessage);
            setConfirmLoading(false);
            return;
          }
    
          if (signedImageUrl) {
            const file = new Blob([fileItem.originFileObj as BlobPart], {
              type: fileItem.type,
            });
            const res: any = await putImageS3(signedImageUrl, file, fileItem.type);
    
            if (!res.isSuccess) {
              message.error('Could not upload file');
              setConfirmLoading(false);
              return;
            }
            // Store the URL in the list if upload succeeds
            imageUrlList.push(imageUrl);
          }
        } catch (error) {
          message.error('An error occurred while processing the file');
          setConfirmLoading(false);
          return;
        }
      }
    }
    
    const response = await currentActionConfig.actionApiCall(getBody(imageUrlList));
    if (response.isSuccess) {
      if (response?.data?.failures?.length) {
        setFailureArray(response.data.failures);
        setErrorModalVisible(true);
      } else {
        message.success(currentActionConfig.successMessage);
        if (currentActionConfig.downloadData) {
          const fileName =
            actionStatus === 'print_pod' ? 'proof_of_delivery' : 'manifestDRS';
          downloadFileData(response?.data, fileName, '.pdf');
        } else {
          currentActionConfig.routeOnSuccess &&
            history.push(currentActionConfig.routeOnSuccess);
        }
        onModalClose(true);
      }
    } else {
      onModalClose(false);
      message.error(response.errorMessage);
    }
    setConfirmLoading(false);
  };
  const handleAddCNClick = async (e) => {
    if (inputValue === '') {
      message.warning('Please enter a valid consignment number');
      return;
    }
    if (enablePieceScanningForAction) {
      setValidationLoader(true);
      if (piecesList.length) {
        const obj = piecesList.find((element) => {
          return element.toUpperCase() === inputValue.toUpperCase();
        });
        if (obj) {
          message.error('Piece has already been scanned once');
          setInputValue('');
          setValidationLoader(false);
          return;
        }
      }

      const completePieces = piecesList.completePieces.map((item) => {
        if (item) return item.reference_number;
      });

      const partialPieces = piecesList.partialPieces.map((item) => {
        if (item) return item.reference_number;
      });

      const res = await validateInScannedConsignments({
        hub_id: currHub?.id,
        consignments: [inputValue, ...completePieces, ...partialPieces],
      });

      if (!res.isSuccess) {
        setValidationLoader(false);
        message.error(res.errorMessage);
        return;
      }

      const validConsignments = res.data?.valid_consignments || [];
      const partialConsignments = res.data?.partial_scanned_consignments || [];

      const uniqueValidConsignments = lodash
        .uniqBy(validConsignments, 'parent_reference_number')
        .map((item: any) => item.parent_reference_number);
      const uniqueCompletePieces = lodash.uniqBy(
        validConsignments,
        'reference_number',
      );
      const uniquePartialPieces = lodash.uniqBy(
        partialConsignments,
        'reference_number',
      );
      const uniquePartialCN = lodash.uniqBy(
        partialConsignments,
        'parent_reference_number',
      );

      setInputValue('');
      setPiecesList({
        ...piecesList,
        completePieces: uniqueCompletePieces,
        partialPieces: uniquePartialPieces,
        partialCN: uniquePartialCN,
      });
      setCNList(uniqueValidConsignments);
      setValidationLoader(false);
      return;
    }
    if (cnList.length) {
      const obj = cnList.find((element) => {
        return element.toUpperCase() === inputValue.toUpperCase();
      });
      if (obj) {
        message.error('Consignment has already been scanned once');
        setInputValue('');
        return;
      }
    }
    const cnlist = [inputValue, ...cnList];
    setInputValue('');
    setCNList(cnlist);
  };

  const handleDeleteCNClick = async (cnNumber) => {
    if (enablePieceScanningForAction) {
      const updatedCompletePieces = piecesList.completePieces.filter((item) => {
        if (item.parent_reference_number !== cnNumber) return item;
      });
      const updatedPartialPieces = piecesList.partialPieces.filter((item) => {
        if (item.parent_reference_number !== cnNumber) return item;
      });

      setPiecesList({
        ...piecesList,
        completePieces: updatedCompletePieces,
        partialPieces: updatedPartialPieces,
      });
    }

    if(cnPodInfo[cnNumber]){
      const updatedPodInfo = lodash.cloneDeep(cnPodInfo);
      delete updatedPodInfo[cnNumber];
      setCnPodInfo({...updatedPodInfo});
    }

    const cnlist = cnList.filter((cn) => cn !== cnNumber);
    setCNList(cnlist);
  };
  const onInputChange = (e) => {
    setInputValue(e.target.value);
  };

  const getReasons = () => {
    const reasonlist = Object.keys(reasonList).length
      ? createOptionsFromObject(reasonList)
      : [];
    if(currentActionConfig.id === 'mark_lost_selected'){
      return generateNotesDropdownOptions();
    }
    return generateOptionsFromList(reasonlist);
  };
  const generateOptionsFromList = (list) => {
    return (
      list?.map((ele) => (
        <Option key={ele.key} value={ele.key}>
          {ele.label}
        </Option>
      )) || []
    );
  };
  const createOptionsFromObject = (obj) => {
    return Object.keys(obj).map((d) => {
      return { key: d, label: obj[d] };
    });
  };

  const generateExceptionOptionsFromList = (list) => {
    const optionsList: any = [];
    if (list) {
      list.forEach((ele) => {
        optionsList.push(
          <Option
            key={ele.code}
            value={ele.code}
          >{`${ele.name}, ${ele.code}`}</Option>,
        );
      });
    }
    return optionsList;
  };

  const generateReleaseExceptionOptionsFromList = (list) => {
    const optionsList: any = [];
    if (list) {
      list.forEach((ele) => {
        optionsList.push(
          <Option
            key={ele.exception_code}
            value={ele.exception_code}
          >{`${ele.exception_name}, ${ele.exception_code}`}</Option>,
        );
      });
    }
    return optionsList;
  };

  const handleFailureModalClose = () => {
    setErrorModalVisible(false);
    onModalClose(false);
  };
  const onLabelTypeChange = (e) => {
    e.target.value === 'a6' ? setSmallLabel(true) : setSmallLabel(false);
    setSelectedLabel(e.target.value);
  };

  const onLabelChange = (e) => {
    const labelValue = e.value;
    setSmallLabel(getCodeFreeLabelType(labelValue));
    setSelectedLabel(labelValue);
  };

  const getCodeFreeLabels = () => {
    return (
      labelList?.map((ele) => (
        <Option key={ele.id} value={ele.label_category}>
          {ele.label_category}
        </Option>
      )) || []
    );
  };

  if (showErrorModal) {
    return (
      <ScanErrorModal
        isVisible={showErrorModal}
        data={piecesList.missingPieces}
        onModalClose={() => setShowErrorModal(false)}
      />
    );
  }

const closeModals = () => {
  setPodUploadModalVisible(false);
};

const handleOptionChange = (value) => {
    setNotes(value?.label);
    setReasonName(value?.label);
    setReasonCode(value?.key);
    const selectedReason = applyExceptionReasonsList?.find((reason) => reason.code === value.key);
    setMaxPhotos(selectedReason?.max_photos);
}

const handleReasonChange = (value) => {
    setReason(value);
    setReasonName(value?.label);
    setReasonCode(value?.key);
}

const handleMarkDeliverySubmit = (data) => {
  const { consignments } = data || [];
  const cnData = consignments[0] || {};
  const {poc_image_url, payment_details} = cnData;

  const updatedCnData = {
    poc_image_url,
    payment_details,
  };

  setCnPodInfo({ ...cnPodInfo, [cnForPodUpdate]: updatedCnData });
  return { isSuccess: true };
};

const handleHubSearchChange = debounce(async(queryString, skipQueryStringCheck = false ) => {

  if (!queryString && !skipQueryStringCheck) {
    return;
  }

  const payload = {
    hub_code: currHub?.code,
    query: queryString,
  };

  const response = await getEligibleHubsForDestinationUpdate(payload);
  if (response.isSuccess) {
    const data = response.data;
    const hubArray = data?.map((entry) => {
      return ({id: entry.id, label: entry.name, value: entry.code});
    });
    setHubList(hubArray);
  }
}, 250);

const generateHubOptionsFromList = () => {
  return (
    hubList?.map((ele) => (
      <Option key={ele.id} value={ele.value}>
        {ele.value} - {ele.label}
      </Option>
    )) || []
  );
};

const getHubSearchInput = () => {
  if(currentActionConfig.id === 'update_consignment_destination_hub_selected'){
    return (
      <div>
        <div className={classes.reason}>Hub</div>
        <div>
          <Select
            showSearch
            placeholder="Select Destination Hub"
            onChange={(val) => setSelectedHubCode(val)}
            className={classes.reasonSelect}
            onSearch={handleHubSearchChange}
          >
            {generateHubOptionsFromList()}
          </Select>
        </div>
      </div>
    );
  }
  return (
    <div>
      <div className={classes.reason}>Hub</div>
      <HubSearch
        dontUpdateHub
        onHubChange={(hub) => setSelectedHubCode(hub?.code)}
        propsDropdownClassName="dark"
        className={classes.reasonSelect}
      />
    </div>
  );
};

const generateNotesDropdownOptions = () => {
  const { customOnHoldReasons } = props;
  switch(currentActionConfig.id) {
    case 'mark_onhold_selected':
    case 'release_onhold_selected':
    case 'mark_lost_selected':
    case 'release_exception_selected':
      const optionsList = reasonList?.map((element) => (
        <Option key={element.name} value={element.code}>
          {element.name}
        </Option>
      )) || [];
      return optionsList;
    case 'apply_exception_selected':
      return applyExceptionReasonsList?.map((element) => (
        <Option key={element.code} value={element.code}>
          {element.name}
        </Option>
      )) || [];
    case 'set_rto_selected':
      return reasonList?.map(
        (element) => (
          <Option value={element.reason_id}>
            <PrettyOption
                label={element.reason_id}
                description={element.name}
            />
          </Option>
        )) || [];
    default:
      return [];
  }
};

const handleExceptionSelect = (val) => {
  setExceptionCode(val);
  const selectedException = exceptionsListwithReasons?.find((exception) => exception.bag_exception_code === val.key);
  const selectedExceptionReasons = selectedException?.reasons || [];
  setApplyExceptionReasonsList(selectedExceptionReasons);
  if(selectedExceptionReasons?.length === 0){
    setMaxPhotos(0);
  }
}

const handleUploadChange = async (info: any) => {
  const files = info.fileList;
  setFileList(files);
};

const customRequest = (args: any) => {
  const { onSuccess, file } = args;
  setTimeout(() => {
    onSuccess(null, file);
  }, 100);
};

const uploadProps = {
  accept: '.png, .jpeg, .pdf',
  name: 'file',
  multiple: true,
  customRequest,
  showUploadList: {
    showPreviewIcon: false,
    removeIcon: <CloseOutlined />,
  },
};

  return (
    <>
      <Modal
        width={360}
        confirmLoading={confirmLoading}
        title={currentActionConfig.title || 'Scan/Selected'}
        visible={isVisible}
        onOk={handleSubmit}
        onCancel={() => onModalClose(false)}
        okButtonProps={{ className: classes.customPrimaryButton }}
      >
        <div>
          {
            <div>
              {['cn_label_selected', 'cn_label_bulk_upload'].includes(
                currentActionConfig.id,
              ) &&
              (showA6Option ||
                (showCustomLabel && customLabelOptions.length)) &&
              !enableCodeFreeLabel ? (
                <div>
                  <span style={{ marginRight: '15px' }}>Label Type:</span>
                  <RadioGroup onChange={onLabelTypeChange} defaultValue="a4">
                    {getLabelOptions().map((item) => {
                      return <Radio value={item}>{item}</Radio>;
                    })}
                  </RadioGroup>
                </div>
              ) : null}
              {['cn_label_selected', 'cn_label_bulk_upload'].includes(
                currentActionConfig.id,
              ) && enableCodeFreeLabel ? (
                <div>
                  <div>Label Type:</div>
                  <Select
                    labelInValue
                    defaultValue={labelList?.[0]?.label_category}
                    placeholder="Select Label Type"
                    onChange={onLabelChange}
                    className={classes.labelType}
                  >
                    {getCodeFreeLabels()}
                  </Select>
                </div>
              ) : null}
              {currentActionConfig.showExceptionCodeWithNotes && (
                <div>
                  <div className={classes.reason}>
                    Select Exception <span>*</span>
                  </div>
                  <Select
                    style={{ marginBottom: '12px' }}
                    labelInValue
                    placeholder="Select Exception"
                    onChange={(val) => handleExceptionSelect(val)}
                    className={classes.reasonSelect}
                  >
                    {generateExceptionOptionsFromList(exceptionsList)}
                  </Select>
                </div>
              )}
              {currentActionConfig.id === 'release_exception_selected' && (
                <div>
                  <div className={classes.reason}>
                    Select Exception <span>*</span>
                  </div>
                  <Select
                    style={{ marginBottom: '12px' }}
                    labelInValue
                    placeholder="Select Exception"
                    onChange={(val) => setAppliedExceptionCode(val)}
                    className={classes.reasonSelect}
                  >
                    {generateReleaseExceptionOptionsFromList(appliedExceptionsList)}
                  </Select>
                </div>
              )}
              {currentActionConfig.showHubSearch && getHubSearchInput()}
              {currentActionConfig.showNotes && !hideNotes ?
              <div>
                {(renderNotesInDropdown() && ((Array.isArray(reasonList) && reasonList.length) || (Array.isArray(applyExceptionReasonsList) && applyExceptionReasonsList.length))) ? (
                  <div>
                    <div className={classes.reason}>Reason</div>
                    <Select
                      showSearch
                      labelInValue
                      style={{ marginBottom: '12px' }}
                      placeholder="Reason"
                      onChange={(val) => handleOptionChange(val)}
                      className={classes.reasonSelect}
                      filterOption={(input, option) => {
                        return (option?.key ?? '').toLowerCase().includes(input.toLowerCase());
                      }}
                    >
                      {generateNotesDropdownOptions()}
                    </Select>
                  </div>
                ) :
                (
                  <div>
                    <div className={classes.reason}>Notes</div>
                    <Input
                      placeholder={'Notes'}
                      onChange={(e) => setNotes(e.target.value)}
                      value={notes}
                    />
                  </div>
                )}
              </div> : <></>
              }
              {maxPhotos > 0 && (
                <div>
                  <div className={classes.reason}>Upload Image(s) *</div>
                  <Dragger 
                    fileList={fileList}
                    style={{ padding: '16px' }}
                    onChange={handleUploadChange}
                    {...uploadProps}
                  >
                    <p
                      style={{ marginBottom: 20, fontSize: '14px' }}
                      className="ant-upload-hint"
                    ></p>
                    <p className="ant-upload-drag-icon">
                      <img style={{ height: '3em' }} src={uploadIcon} />
                    </p>
                    <p
                      style={{
                        color: '#525252',
                        marginBottom: 30,
                        fontSize: '14px',
                        fontWeight: 400,
                      }}
                    >
                      <b>
                        Drag and drop to upload <br /> or
                        <span style={{ color: '#457CA9' }}> browse</span> to choose file
                      </b>
                    </p>
                  </Dragger>
                </div>
                )
              }
              {currentActionConfig.requireReason && !currentActionConfig.reasonFreeText && (
                <div>
                  <div className={classes.reason}>
                    Select Reason <span>*</span>
                  </div>
                  <Select
                    labelInValue
                    placeholder="Select Reason"
                    onChange={(val) => setReason(val)}
                    className={classes.reasonSelect}
                  >
                    {getReasons()}
                  </Select>
                </div>
              )}
              {currentActionConfig.requireReason && currentActionConfig.reasonFreeText && (
                <div>
                {
                  (Array.isArray(reasonList) && reasonList.length) ? (
                    <div>
                      <div className={classes.reason}>
                        Select Reason <span>*</span>
                      </div>
                      <Select
                        showSearch
                        style={{ marginBottom: '12px' }}
                        labelInValue
                        placeholder="Select Reason"
                        onChange={(val) => handleReasonChange(val)}
                        className={classes.reasonSelect}
                        filterOption={(input, option) => {
                          return (option?.key ?? '').toLowerCase().includes(input.toLowerCase());
                        }}
                      >
                        {getReasons()}
                      </Select>
                    </div>
                  ) : (
                    <div>
                      <div className={classes.reason}>
                        Reason <span>*</span>
                      </div>
                      <Input
                        placeholder={'Reason'}
                        onChange={(e) => setReason(e.target.value)}
                        value={reason}
                      />
                    </div>
                  )
                }
              </div>
              )}
              <div className={classes.scanCN}>
                <Input
                  addonAfter={
                    validationLoader ? <LoadingOutlined /> : <ScanOutlined />
                  }
                  placeholder={'Add consignments'}
                  onChange={onInputChange}
                  onPressEnter={handleAddCNClick}
                  value={inputValue}
                />
              </div>
              {enablePieceScanningForAction && (
                <div className={classes.piecesDetailContainer}>
                  <div>
                    Parent CN Selected:{' '}
                    <b>
                      {cnList.length} /{' '}
                      {cnList.length + piecesList.partialCN.length}
                    </b>
                  </div>
                  <div>
                    Pieces Scanned:{' '}
                    <b>
                      {piecesList.partialPieces.length +
                        piecesList.completePieces.length}
                    </b>
                  </div>
                </div>
              )}
              <div className={classes.tableContainer}>
                <Table
                  locale={{ emptyText: 'No data' }}
                  pagination={false}
                  rowKey={(record: any) => record['reference_number']}
                  dataSource={cnList}
                  columns={getColumsForTable()}
                />
              </div>
            </div>
          }
          {isErrorModalVisible && (
            <FailureArrayHandler
              failureArray={failureArray}
              isVisible={isErrorModalVisible}
              onModalClose={handleFailureModalClose}
            ></FailureArrayHandler>
          )}
          {isMoveCNModalVisible && (
            <MoveConsignmentsModal
              viewType={viewType}
              isVisible={isMoveCNModalVisible}
              onModalClose={(res: any) => onModalClose(res)}
              consignmentList={cnList}
            />
          )}
          {isBulkDeleteCNModalVisible && (
            <BulkDeleteConsignment
              consignments={cnList}
              handleModalClose={(res: any) => onModalClose(res)}
              isVisible={isBulkDeleteCNModalVisible}
              closeAllModals
            />
          )}
        </div>
      </Modal>
      {podUploadModalVisible && (
        <PodUploadModal
          isVisible={podUploadModalVisible}
          onModalClose={closeModals}
          handleMarkDeliverySubmit={handleMarkDeliverySubmit}
          referenceNumber={cnForPodUpdate}
          cnData={cnPodInfo[cnForPodUpdate]}
          isEditMode={cnPodInfo[cnForPodUpdate] ? true : false}
          doNotShowSuccessMsg
          mandatoryPaymentDetails={mandatoryPaymentDetails}
          allowPartialCod={allowPartialCod}
          collectionModeOptions={collectionModeOptions}
          showInputTimestampForDelivery={showInputTimestampForDelivery}
          isPODUploadOptional={isPODUploadOptional}
        />
      )}
    </>
  );
};
const mapStateToProps = ({ masterData, cachedData }) => {
  return {
    hideNotes:
      masterData.ops_dashboard_config?.parts_to_show?.hide_event_level_notes,
    showA6Option:
      masterData.ops_dashboard_config?.config
        ?.allow_consignment_a6_print_label_request,
    showCustomLabel:
      masterData.ops_dashboard_config.parts_to_show?.custom_label_config
        ?.show_custom_label,
    customLabelOptions:
      masterData.ops_dashboard_config.parts_to_show?.custom_label_config
        ?.custom_label_sizes,
    useCustomLabelOnly:
      masterData.ops_dashboard_config.parts_to_show?.custom_label_config
        ?.use_custom_label_config_only,
    enablePieceScanning:
      masterData.ops_dashboard_config?.parts_to_show?.flm_trip_config
        ?.enable_piece_level_scanning,
    enableCodeFreeLabel: masterData.ops_dashboard_config?.parts_to_show
        ?.enable_code_free_label_generation,
    mandatoryPaymentDetails: masterData?.crm_config_master?.crm_mandatory_payment_details,
    allowPartialCod: masterData?.allow_partial_cod,
    collectionModeOptions: lodash.get(masterData, 'ops_dashboard_config.config.paymentModeConfigs', []).map(el => ({label: el.payment_type, value: el.payment_type})),
    showInputTimestampForDelivery: masterData?.crm_config_master
      ?.mark_delivered?.show_input_timestamp || false,
    isPODUploadOptional: masterData?.crm_config_master
      ?.mark_delivered?.is_pod_upload_optional || false,
    currHub: cachedData.currHub,
    showCustomOnHoldReasons: masterData?.consignment_on_hold_config?.custom_on_hold_reason || false,
    customOnHoldReasons: masterData.consignment_on_hold_config?.on_hold_reasons,
  };
};

const ScanSelectedModalStyled = withStyles(styles, { injectTheme: true })(
  ScanSelectedModal,
);
export default connect(mapStateToProps)(ScanSelectedModalStyled);
