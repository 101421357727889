export const SET_UI_THEME = 'SET_UI_THEME';

// masterdata
export const SET_MASTER_DATA = 'SET_MASTER_DATA';
export const INITIALIZE_COMPLETE = 'INITIALIZE_COMPLETE';
export const INITIALIZE_RESET = 'INITIALIZE_RESET';
export const SET_DISPATCH_SCREEN_FOR_HUB = 'SET_DISPATCH_SCREEN_FOR_HUB';

// Generic Page Actions
export const SET_PAGE_FOR_EMPLOYEE = 'SET_PAGE_FOR_EMPLOYEE';
export const FETCH_VIEW_DATA = 'FETCH_VIEW_DATA';
export const SET_VIEW_DATA = 'SET_VIEW_DATA';
export const FETCH_VIEW_DATA_OBJECTS = 'FETCH_VIEW_DATA_OBJECTS';
export const SET_VIEW_DATA_OBJECTS = 'SET_VIEW_DATA_OBJECTS';
export const VIEW_TAB_CHANGE = 'VIEW_TAB_CHANGE';
export const GLOBAL_FILTER_CHANGE = 'GLOBAL_FILTER_CHANGE';
export const VIEW_SET_SELECTED_ROW_KEYS = 'VIEW_SET_SELECTED_ROW_KEYS';

// cache data
export const SET_CURRENT_HUB = 'SET_CURRENT_HUB';
export const SET_VIEW_TYPE = 'SET_VIEW_TYPE';
export const SET_SEARCHED_HUBS_CRM = 'SET_SEARCHED_HUBS_CRM';
export const SET_CURRENT_HUBS_LIST = 'SET_CURRENT_HUBS_LIST';
export const SET_ACTION_REASON = 'SET_ACTION_REASON';
export const GET_ACTION_REASON = 'GET_ACTION_REASON';
// main dashboard
export const DASHBOARD_UPDATE_DATA = 'DASHBOARD_UPDATE_DATA';
export const DASHBOARD_ENABLE_LOADING = 'DASHBOARD_ENABLE_LOADING';
export const DASHBOARD_DISABLE_LOADING = 'DASHBOARD_DISABLE_LOADING';
export const DASHBOARD_UPDATE_FILTER = 'DASHBOARD_UPDATE_FILTER';
export const DASHBOARD_RESET_FILTER = 'DASHBOARD_RESET_FILTER';
export const DASHBOARD_PAGINATION = 'DASHBOARD_PAGINATION';
export const DASHBOARD_RESET_PAGINATION = 'DASHBOARD_RESET_PAGINATION';

// crm summary metrics
export const CRM_SUMMARY_METRICS_DATA = 'CRM_SUMMARY_METRICS_DATA';

// Consignment Details
export const CONSIGNMENT_DETAILS_UPDATE_DATA = 'CONSIGNMENT_DETAILS_UPDATE_DATA';
export const CONSIGNMENT_DETAILS_ENABLE_LOADING = 'CONSIGNMENT_DETAILS_ENABLE_LOADING';
export const CONSIGNMENT_DETAILS_DISABLE_LOADING = 'CONSIGNMENT_DETAILS_DISABLE_LOADING';

// Change Password
export const CHANGE_PASSWORD_INITIATED = 'CHANGE_PASSWORD_INITIATED';
export const CHANGE_PASSWORD_SUCCEEDED = 'CHANGE_PASSWORD_SUCCEEDED';
export const CHANGE_PASSWORD_FAILED = 'CHANGE_PASSWORD_FAILED';

export const DASHBOARD_SET_MORE_FILTER = 'DASHBOARD_SET_MORE_FILTER';
export const DASHBOARD_APPLY_MORE_FILTER = 'DASHBOARD_APPLY_MORE_FILTER';
export const DASHBOARD_CANCEL_MORE_FILTER = 'DASHBOARD_CANCEL_MORE_FILTER';
export const DASHBOARD_SET_COLUMNS = 'DASHBOARD_SET_COLUMNS';

// For navigation
export const TOGGLE_SIDE_MENU = 'TOGGLE_SIDE_MENU';
export const OPEN_SIDE_MENU = 'OPEN_SIDE_MENU';
export const CLOSE_SIDE_MENU = 'CLOSE_SIDE_MENU';
export const SET_OPEN_KEYS = 'SET_OPEN_KEYS';

// Bagging
export const BAG_CREATE_TABLE_ENABLE_LOADING = 'BAG_CREATE_TABLE_ENABLE_LOADING';
export const BAG_CREATE_TABLE_DISABLE_LOADING = 'BAG_CREATE_TABLE_DISABLE_LOADING';
export const BAG_CREATE_AVAILABLE_TABLE_ENABLE_LOADING =
    'BAG_CREATE_AVAILABLE_TABLE_ENABLE_LOADING';
export const BAG_CREATE_AVAILABLE_TABLE_DISABLE_LOADING =
    'BAG_CREATE_AVAILABLE_TABLE_DISABLE_LOADING';
export const BAG_AVAILABLE_CONSIGNMENTS = 'BAG_AVAILABLE_CONSIGNMENTS';
export const DEBAG_AVAILABLE_CONSIGNMENTS = 'DEBAG_AVAILABLE_CONSIGNMENTS';
export const BAG_SCAN_CONSIGNMENTS = 'BAG_SCAN_CONSIGNMENTS';
export const BAG_REMOVE_SCANNED_CONSIGNMENTS = 'BAG_REMOVE_SCANNED_CONSIGNMENTS';
export const BAG_UPDATE_FILTER = 'BAG_UPDATE_FILTER';
export const BAG_UPDATE_DATA = 'BAG_UPDATE_DATA';
export const BAG_PAGINATION = 'BAG_PAGINATION';
export const BAG_RESET_PAGINATION = 'BAG_RESET_PAGINATION';
export const BAG_CREATE_RESET_LIST = 'BAG_CREATE_RESET_LIST';
export const BAG_ALL_CONSIGNMENTS = 'BAG_ALL_CONSIGNMENTS';
export const HIDE_NEXT_SHOW_DONE = 'HIDE_NEXT_SHOW_DONE';
export const HIDE_SEAL_SHOW_DONE = 'HIDE_SEAL_SHOW_DONE';
export const DEBAG_AVAILABLE_TABLE_ENABLE_LOADING = 'DEBAG_AVAILABLE_TABLE_ENABLE_LOADING';
export const DEBAG_AVAILABLE_TABLE_DISABLE_LOADING = 'DEBAG_AVAILABLE_TABLE_DISABLE_LOADING';
export const DEBAG_REMOVE_SCANNED_CONSIGNMENTS = 'DEBAG_REMOVE_SCANNED_CONSIGNMENTS';
export const DEBAG_SCAN_CONSIGNMENTS = 'DEBAG_SCAN_CONSIGNMENTS';
export const OUTSCAN_AVAILABLE_TABLE_ENABLE_LOADING = 'OUTSCAN_AVAILABLE_TABLE_ENABLE_LOADING';
export const OUTSCAN_AVAILABLE_TABLE_DISABLE_LOADING = 'OUTSCAN_AVAILABLE_TABLE_DISABLE_LOADING';
export const OUTSCAN_AVAILABLE_CONSIGNMENTS = 'OUTSCAN_AVAILABLE_CONSIGNMENTS';
export const OUTSCAN_SCAN_CONSIGNMENTS = 'OUTSCAN_SCAN_CONSIGNMENTS';
export const OUTSCAN_REMOVE_SCANNED_CONSIGNMENTS = 'OUTSCAN_REMOVE_SCANNED_CONSIGNMENTS';
export const OUTSCAN_RESET_LIST = 'OUTSCAN_RESET_LIST';
export const DEBAG_RESET_LIST = 'DEBAG_RESET_LIST';

// Rider Transaction History
export const RIDER_TRANSACTION_HISTORY_FETCH_TRANSACTIONS_INITIATED =
    'RIDER_TRANSACTION_HISTORY_FETCH_TRANSACTIONS_INITIATED';
export const RIDER_TRANSACTION_HISTORY_FETCH_TRANSACTIONS_FAILED =
    'RIDER_TRANSACTION_HISTORY_FETCH_TRANSACTIONS_FAILED';
export const RIDER_TRANSACTION_HISTORY_FETCH_TRANSACTIONS_SUCCEEDED =
    'RIDER_TRANSACTION_HISTORY_FETCH_TRANSACTIONS_SUCCEEDED';
export const RIDER_TRANSACTION_HISTORY_FETCH_HUB_DATA_INITIATED =
    'RIDER_TRANSACTION_HISTORY_FETCH_HUB_DATA_INITIATED';
export const RIDER_TRANSACTION_HISTORY_FETCH_HUB_DATA_SUCCEEDED =
    'RIDER_TRANSACTION_HISTORY_FETCH_HUB_DATA_SUCCEEDED';
export const RIDER_TRANSACTION_HISTORY_FETCH_HUB_DATA_FAILED =
    'RIDER_TRANSACTION_HISTORY_FETCH_HUB_DATA_FAILED';
export const RIDER_TRANSACTION_HISTORY_FETCH_RIDER_DATA_INITIATED =
    'RIDER_TRANSACTION_HISTORY_FETCH_RIDER_DATA_INITIATED';
export const RIDER_TRANSACTION_HISTORY_FETCH_RIDER_DATA_SUCCEEDED =
    'RIDER_TRANSACTION_HISTORY_FETCH_RIDER_DATA_SUCCEEDED';
export const RIDER_TRANSACTION_HISTORY_FETCH_RIDER_DATA_FAILED =
    'RIDER_TRANSACTION_HISTORY_FETCH_RIDER_DATA_FAILED';
export const RIDER_TRANSACTION_HISTORY_DOWNLOAD_RIDER_DATA_INITIATED =
    'RIDER_TRANSACTION_HISTORY_DOWNLOAD_RIDER_DATA_INITIATED';
export const RIDER_TRANSACTION_HISTORY_DOWNLOAD_RIDER_DATA_SUCCEEDED =
    'RIDER_TRANSACTION_HISTORY_DOWNLOAD_RIDER_DATA_SUCCEEDED';
export const RIDER_TRANSACTION_HISTORY_DOWNLOAD_RIDER_DATA_FAILED =
    'RIDER_TRANSACTION_HISTORY_DOWNLOAD_RIDER_DATA_FAILED';
export const RIDER_TRANSACTION_HISTORY_INCREMENT_DOWNLOAD_COUNTER =
    'RIDER_TRANSACTION_HISTORY_INCREMENT_DOWNLOAD_COUNTER';
export const RIDER_TRANSACTION_HISTORY_RESET_DOWNLOAD_COUNTER =
    'RIDER_TRANSACTION_HISTORY_RESET_DOWNLOAD_COUNTER';

// IST
export const IST_CREATE_TABLE_ENABLE_LOADING = 'IST_CREATE_TABLE_ENABLE_LOADING';
export const IST_CREATE_TABLE_DISABLE_LOADING = 'IST_CREATE_TABLE_DISABLE_LOADING';
export const IST_CREATE_AVAILABLE_TABLE_ENABLE_LOADING =
    'IST_CREATE_AVAILABLE_TABLE_ENABLE_LOADING';
export const IST_CREATE_AVAILABLE_TABLE_DISABLE_LOADING =
    'IST_CREATE_AVAILABLE_TABLE_DISABLE_LOADING';
export const IST_AVAILABLE_CONSIGNMENTS = 'IST_AVAILABLE_CONSIGNMENTS';
export const IST_SCAN_CONSIGNMENTS = 'IST_SCAN_CONSIGNMENTS';
export const IST_REMOVE_SCANNED_CONSIGNMENTS = 'IST_REMOVE_SCANNED_CONSIGNMENTS';
export const IST_UPDATE_FILTER = 'IST_UPDATE_FILTER';
export const IST_UPDATE_DATA = 'IST_UPDATE_DATA';
export const IST_PAGINATION = 'IST_PAGINATION';
export const IST_RESET_PAGINATION = 'IST_RESET_PAGINATION';
export const IST_ALL_CONSIGNMENTS = 'IST_ALL_CONSIGNMENTS';
export const IST_INSCAN_AVAILABLE_TABLE_ENABLE_LOADING =
    'IST_INSCAN_AVAILABLE_TABLE_ENABLE_LOADING';
export const IST_INSCAN_AVAILABLE_TABLE_DISABLE_LOADING =
    'IST_INSCAN_AVAILABLE_TABLE_DISABLE_LOADING';
export const IST_AVAILABLE_CONSIGNMENTS_FOR_INSCAN = 'IST_AVAILABLE_CONSIGNMENTS_FOR_INSCAN';
export const IST_SCAN_CONSIGNMENTS_FOR_INSCAN = 'IST_SCAN_CONSIGNMENTS_FOR_INSCAN';
export const IST_REMOVE_SCANNED_CONSIGNMENTS_FOR_INSCAN =
    'IST_REMOVE_SCANNED_CONSIGNMENTS_FOR_INSCAN';
export const IST_SET_INSCAN_REF_NUMBER = 'IST_SET_INSCAN_REF_NUMBER';
export const IST_HIDE_DONE_SHOW_NEXT = 'IST_HIDE_DONE_SHOW_NEXT';
export const IST_HIDE_NEXT_SHOW_DONE = 'IST_HIDE_NEXT_SHOW_DONE';
export const IST_CREATE_RESET_LIST = 'IST_CREATE_RESET_LIST';
export const IST_INSCAN_RESET_LIST = 'IST_INSCAN_RESET_LIST';

// main dashboard
export const RETAIL_DASHBOARD_UPDATE_DATA = 'RETAIL_DASHBOARD_UPDATE_DATA';
export const RETAIL_DASHBOARD_ENABLE_LOADING = 'RETAIL_DASHBOARD_ENABLE_LOADING';
export const RETAIL_DASHBOARD_DISABLE_LOADING = 'RETAIL_DASHBOARD_DISABLE_LOADING';
export const RETAIL_DASHBOARD_UPDATE_FILTER = 'RETAIL_DASHBOARD_UPDATE_FILTER';
export const RETAIL_DASHBOARD_UPDATE_FILTERED_DATA = 'RETAIL_DASHBOARD_UPDATE_FILTERED_DATA';
export const RETAIL_DASHBOARD_RESET_FILTER = 'RETAIL_DASHBOARD_RESET_FILTER';

// TRIP
export const TRIP_UPDATE_DATA = 'TRIP_UPDATE_DATA';
export const TRIP_ENABLE_LOADING = 'TRIP_ENABLE_LOADING';
export const TRIP_DISABLE_LOADING = 'TRIP_DISABLE_LOADING';
export const TRIP_UPDATE_FILTER = 'TRIP_UPDATE_FILTER';
export const TRIP_RESET_FILTER = 'TRIP_RESET_FILTER';
export const TRIP_PAGINATION = 'TRIP_PAGINATION';
export const TRIP_RESET_PAGINATION = 'TRIP_RESET_PAGINATION';

//Virtual Wallet Transactions
export const VALLET_TRANSACTIONS_UPDATE_DATA = 'VALLET_TRANSACTIONS_UPDATE_DATA';
export const VALLET_TRANSACTION_UPDATE_FILTER = 'VALLET_TRANSACTION_UPDATE_FILTER';
export const VALLET_RESET_PAGINATION = 'VALLET_RESET_PAGINATION';
export const VALLET_ENABLE_LOADING = 'VALLET_ENABLE_LOADING';
export const VALLET_DISABLE_LOADING = 'VALLET_DISABLE_LOADING';
export const VALLET_TRANSACTIONS_PAGINATION = 'VALLET_TRANSACTIONS_PAGINATION';

// Rider Roster Management
export const RIDER_ROSTER_UPDATE_DATA = 'RIDER_ROSTER_UPDATE_DATA';
export const RIDER_ROSTER_ENABLE_LOADING = 'RIDER_ROSTER_ENABLE_LOADING';
export const RIDER_ROSTER_DISABLE_LOADING = 'RIDER_ROSTER_DISABLE_LOADING';
export const RIDER_ROSTER_UPDATE_FILTER = 'RIDER_ROSTER_UPDATE_FILTER';
export const RIDER_ROSTER_PAGINATION = 'RIDER_ROSTER_PAGINATION';
export const RIDER_ROSTER_RESET_PAGINATION = 'RIDER_ROSTER_RESET_PAGINATION';
export const RIDER_ROSTER_UPDATE_SORT = 'RIDER_ROSTER_UPDATE_SORT';
export const RIDER_ROSTER_UPDATE_VIEW = 'RIDER_ROSTER_UPDATE_VIEW';
export const RIDER_ROSTER_UPDATE_DATE = 'RIDER_ROSTER_UPDATE_DATE';

// TRACKING
export const TRIP_TRACKING_UPDATE_TRIP_LIST_DATA = 'TRIP_TRACKING_UPDATE_TRIP_LIST_DATA';
export const TRIP_TRACKING_UPDATE_DATA = 'TRIP_TRACKING_UPDATE_DATA';
export const TRIP_TRACKING_ENABLE_LOADING = 'TRIP_TRACKING_ENABLE_LOADING';
export const TRIP_TRACKING_DISABLE_LOADING = 'TRIP_TRACKING_DISABLE_LOADING';
export const TRIP_TRACKING_UPDATE_FILTER = 'TRIP_TRACKING_UPDATE_FILTER';
export const TRIP_TRACKING_RESET_FILTER = 'TRIP_TRACKING_RESET_FILTER';
export const TRIP_TRACKING_HISTORY_RESET_FILTER = 'TRIP_TRACKING_HISTORY_RESET_FILTER';
export const TRIP_TRACKING_RESET_HISTORY_DATA = 'TRIP_TRACKING_RESET_HISTORY_DATA';
export const TRIP_TRACKING_UPDATE_CACHED_MAP = 'TRIP_TRACKING_UPDATE_CACHED_MAP';
// ltl
export const LTL_TRACKING_ENABLE_LOADING = 'LTL_TRACKING_ENABLE_LOADING';
export const LTL_TRACKING_LOADED = 'LTL_TRACKING_LOADED';
export const LTL_TRACKING_ERROR = 'LTL_TRACKING_ERROR';
export const LTL_TRACKING_SET_PAGINATION = 'LTL_TRACKING_SET_PAGINATION';
export const LTL_FILTERS_APPLIED = 'LTL_FILTERS_APPLIED';
export const LTL_RESET_PAGINATION = 'LTL_RESET_PAGINATION';
export const LTL_RESET_FILTER = 'LTL_RESET_FILTER';
export const LTL_DELETE_SAVED_ADDRESS_ENABLE_LOADING = 'LTL_DELETE_SAVED_ADDRESS_ENABLE_LOADING';
export const LTL_DELETE_SAVED_ADDRESS_DISABLE_LOADING = 'LTL_DELETE_SAVED_ADDRESS_DISABLE_LOADING';
export const LTL_DELETE_SAVED_ADDRESS_LOADED = 'LTL_DELETE_SAVED_ADDRESS_LOADED';
export const LTL_DELETE_SAVED_ADDRESS_ERROR = 'LTL_DELETE_SAVED_ADDRESS_ERROR';
export const LTL_FETCH_SAVED_RECEIVER_ADDRESSES = 'LTL_FETCH_SAVED_RECEIVER_ADDRESSES';
export const LTL_FETCH_SAVED_SENDER_ADDRESSES = 'LTL_FETCH_SAVED_SENDER_ADDRESSES';
export const LTL_EDIT_SAVED_ADDRESS_ENABLE_LOADING = 'LTL_EDIT_SAVED_ADDRESS_ENABLE_LOADING';
export const LTL_EDIT_SAVED_ADDRESS_DISABLE_LOADING = 'LTL_EDIT_SAVED_ADDRESS_DISABLE_LOADING';
export const LTL_EDIT_SAVED_ADDRESS_ERROR = 'LTL_EDIT_SAVED_ADDRESS_ERROR';
export const LTL_EDIT_SAVED_ADDRESS_LOADED = 'LTL_EDIT_SAVED_ADDRESS_LOADED';
export const LTL_CREATE_SINGLE_SHIPMENT_ENABLE_LOADING =
    'LTL_CREATE_SINGLE_SHIPMENT_ENABLE_LOADING';
export const LTL_CREATE_SINGLE_SHIPMENT_DISABLE_LOADING =
    'LTL_CREATE_SINGLE_SHIPMENT_DISABLE_LOADING';
export const LTL_CREATE_SINGLE_SHIPMENT_ERROR = 'LTL_CREATE_SINGLE_SHIPMENT_ERROR';
export const LTL_CREATE_SINGLE_SHIPMENT_LOADED = 'LTL_CREATE_SINGLE_SHIPMENT_LOADED';
export const LTL_FETCH_DETAILED_ENABLE_LOADING = 'LTL_FETCH_DETAILED_ENABLE_LOADING';
export const LTL_FETCH_DETAILED_DISABLE_LOADING = 'LTL_FETCH_DETAILED_DISABLE_LOADING';
export const LTL_FETCH_DETAILED_LOADED = 'LTL_FETCH_DETAILED_LOADED';
export const LTL_FETCH_DETAILED_ERROR = 'LTL_FETCH_DETAILED_ERROR';
export const LTL_FETCH_BULK_UPLOAD_ENABLE_LOADING = 'LTL_FETCH_BULK_UPLOAD_ENABLE_LOADING';
export const LTL_FETCH_BULK_UPLOAD_DISABLE_LOADING = 'LTL_FETCH_BULK_UPLOAD_DISABLE_LOADING';
export const LTL_FETCH_BULK_UPLOAD_ERROR = 'LTL_FETCH_BULK_UPLOAD_ERROR';
export const LTL_FETCH_BULK_UPLOAD_RESET = 'LTL_FETCH_BULK_UPLOAD_RESET';
export const LTL_FETCH_BULK_UPLOADED = 'LTL_FETCH_BULK_UPLOADED';
export const LTL_DOWNLOAD_REQUEST_ENABLE_LOADING = 'LTL_DOWNLOAD_REQUEST_ENABLE_LOADING';
export const LTL_DOWNLOAD_REQUEST_DISABLE_LOADING = 'LTL_DOWNLOAD_REQUEST_DISABLE_LOADING';
export const LTL_DOWNLOAD_REQUEST_LOADED = 'LTL_DOWNLOAD_REQUEST_LOADED';
export const LTL_CREATE_DOWNLOAD_REQUEST = 'LTL_CREATE_DOWNLOAD_REQUEST';
export const LTL_DOWNLOAD_REQUEST_ERROR = 'LTL_DOWNLOAD_REQUEST_ERROR';
export const LTL_FETCH_SUMMARY_METRICS_VIEW = 'LTL_FETCH_SUMMARY_METRICS_VIEW';
export const LTL_FETCH_SUMMARY_METRICS_DATA = 'LTL_FETCH_SUMMARY_METRICS_DATA';
export const LTL_TOGGLE_SELECTED_SUMMARY_METRIC = 'LTL_TOGGLE_SELECTED_SUMMARY_METRIC';
export const CUSTOMER_ACTIVITY_SUMMARY_METRICS_VIEW = 'CUSTOMER_ACTIVITY_SUMMARY_METRICS_VIEW';
export const CUSTOMER_ACTIVITY_SUMMARY_METRICS_DATA = 'CUSTOMER_ACTIVITY_SUMMARY_METRICS_DATA';
export const CUSTOMER_ACTIVITY_TOGGLE_SELECTED_SUMMARY_METRIC =
    'CUSTOMER_ACTIVITY_TOGGLE_SELECTED_SUMMARY_METRIC';

export const LTL_SET_COLUMNS = 'LTL_SET_COLUMNS';
export const LTL_SUMMARY_METRICS_DISABLE_LOADING = 'LTL_SUMMARY_METRICS_DISABLE_LOADING';
export const CUSTOMER_ACTIVITY_SUMMARY_METRICS_DISABLE_LOADING =
    'CUSTOMER_ACTIVITY_SUMMARY_METRICS_DISABLE_LOADING';
export const LTL_UPDATE_TRACKING = 'LTL_UPDATE_TRACKING';
export const LTL_REMOVE_SEARCH_FILTERS = 'LTL_REMOVE_SEARCH_FILTERS';

// Carrier Management
export const CARRIER_MANAGEMENT_FETCH_DATA = 'CARRIER_MANAGEMENT_FETCH_DATA';
export const CARRIER_MANAGEMENT_FETCH_ERROR = 'CARRIER_MANAGEMENT_FETCH_ERROR';
export const CARRIER_MANAGEMENT_FETCH_ENABLE_LOADING = 'CARRIER_MANAGEMENT_FETCH_ENABLE_LOADING';
export const CARRIER_MANAGEMENT_FETCH_DISABLE_LOADING = 'CARRIER_MANAGEMENT_FETCH_DISABLE_LOADING';
export const CARRIER_MANAGEMENT_FETCH_SERVICE_TYPES = 'CARRIER_MANAGEMENT_FETCH_SERVICE_TYPES';
export const CARRIER_MANAGEMENT_FETCH_VIRTUAL_SERIES = 'CARRIER_MANAGEMENT_FETCH_VIRTUAL_SERIES';
export const CUSTOMER_FEEDBACK_LOADING_ENABLE = 'CUSTOMER_FEEDBACK_LOADING_ENABLE';
export const CUSTOMER_FEEDBACK_LOADING_DISABLE = 'CUSTOMER_FEEDBACK_LOADING_DISABLE';
export const CUSTOMER_FEEDBACK_DATA_FETCHED = 'CUSTOMER_FEEDBACK_DATA_FETCHED';
export const CUSTOMER_FEEDBACK_ERROR = 'CUSTOMER_FEEDBACK_ERROR';
export const CUSTOMER_FEEDBACK_FILTERS_APPLIED = 'CUSTOMER_FEEDBACK_FILTERS_APPLIED';
export const CUSTOMER_FEEDBACK_SET_PAGINATION = 'CUSTOMER_FEEDBACK_SET_PAGINATION';
export const CUSTOMER_FEEDBACK_RESET_PAGINATION = 'CUSTOMER_FEEDBACK_RESET_PAGINATION';
export const CUSTOMER_FEEDBACK_RESET_FILTER = 'CUSTOMER_FEEDBACK_RESET_FILTER';
export const CUSTOMER_FEEDBACK_REMOVE_SEARCH_FILTERS = 'CUSTOMER_FEEDBACK_REMOVE_SEARCH_FILTERS';
export const CUSTOMER_ACTIVITY_SHIPMENT_SUMMARY_METRICS_DATA =
    'CUSTOMER_ACTIVITY_SHIPMENT_SUMMARY_METRICS_DATA';
export const CUSTOMER_ACTIVITY_TOGGLE_SHIPMENT_SELECTED_SUMMARY_METRIC =
    'CUSTOMER_ACTIVITY_TOGGLE_SHIPMENT_SELECTED_SUMMARY_METRIC';
export const CUSTOMER_ACTIVITY_FEEDBACK_SUMMARY_METRICS_DATA =
    'CUSTOMER_ACTIVITY_FEEDBACK_SUMMARY_METRICS_DATA';
export const CUSTOMER_ACTIVITY_TOGGLE_FEEDBACK_SELECTED_SUMMARY_METRIC =
    'CUSTOMER_ACTIVITY_TOGGLE_FEEDBACK_SELECTED_SUMMARY_METRIC';
export const CUSTOMER_ACTIVITY_SHIPMENT_VIEW_LOADING_ENABLE =
    'CUSTOMER_ACTIVITY_SHIPMENT_VIEW_LOADING_ENABLE';
export const CUSTOMER_ACTIVITY_SHIPMENT_VIEW_DATA_FETCHED =
    'CUSTOMER_ACTIVITY_SHIPMENT_VIEW_DATA_FETCHED';
export const CUSTOMER_ACTIVITY_SHIPMENT_VIEW_LOADING_DISABLE =
    'CUSTOMER_ACTIVITY_SHIPMENT_VIEW_LOADING_DISABLE';
export const CUSTOMER_ACTIVITY_SHIPMENT_VIEW_ERROR = 'CUSTOMER_ACTIVITY_SHIPMENT_VIEW_ERROR';
export const CUSTOMER_ACTIVITY_SHIPMENT_VIEW_FILTERS_APPLIED =
    'CUSTOMER_ACTIVITY_SHIPMENT_VIEW_FILTERS_APPLIED';
export const CUSTOMER_ACTIVITY_SHIPMENT_VIEW_SET_PAGINATION =
    'CUSTOMER_ACTIVITY_SHIPMENT_VIEW_SET_PAGINATION';
export const CUSTOMER_ACTIVITY_SHIPMENT_VIEW_RESET_PAGINATION =
    'CUSTOMER_ACTIVITY_SHIPMENT_VIEW_RESET_PAGINATION';
export const CUSTOMER_ACTIVITY_SHIPMENT_VIEW_RESET_FILTER =
    'CUSTOMER_ACTIVITY_SHIPMENT_VIEW_RESET_FILTER';
export const CUSTOMER_ACTIVITY_SHIPMENT_VIEW_REMOVE_SEARCH_FILTERS =
    'CUSTOMER_ACTIVITY_SHIPMENT_VIEW_REMOVE_SEARCH_FILTERS';
export const CUSTOMER_ACTIVITY_SHIPMENT_VIEW_SET_COLUMNS =
    'CUSTOMER_ACTIVITY_SHIPMENT_VIEW_SET_COLUMNS';
export const INVOICE_ENABLE_LOADING = 'INVOICE_ENABLE_LOADING';
export const INVOICE_DISABLE_LOADING = 'INVOICE_DISABLE_LOADING';
export const INVOICE_UPDATE_FILTER = 'INVOICE_UPDATE_FILTER';
export const INVOICE_RESET_FILTER = 'INVOICE_RESET_FILTER';
export const INVOICE_PAGINATION = 'INVOICE_PAGINATION';
export const INVOICE_RESET_PAGINATION = 'INVOICE_RESET_PAGINATION';
export const INVOICE_UPDATE_DATA = 'INVOICE_UPDATE_DATA';
export const INVOICE_SET_SELECTED_DATA = 'INVOICE_SET_SELECTED_DATA';

export const RETAIL_CONSIGNMENTS_UPDATE_DATA = 'RETAIL_CONSIGNMENTS_UPDATE_DATA';
export const RETAIL_CONSIGNMENTS_ENABLE_LOADING = 'RETAIL_CONSIGNMENTS_ENABLE_LOADING';
export const RETAIL_CONSIGNMENTS_DISABLE_LOADING = 'RETAIL_CONSIGNMENTS_DISABLE_LOADING';
export const RETAIL_CONSIGNMENTS_UPDATE_FILTER = 'RETAIL_CONSIGNMENTS_UPDATE_FILTER';
export const RETAIL_CONSIGNMENTS_RESET_FILTER = 'RETAIL_CONSIGNMENTS_RESET_FILTER';
export const RETAIL_CONSIGNMENTS_UPDATE_PAGINATION = 'RETAIL_CONSIGNMENTS_UPDATE_PAGINATION';
export const RETAIL_CONSIGNMENTS_RESET_PAGINATION = 'RETAIL_CONSIGNMENTS_RESET_PAGINATION';
export const RETAIL_CONSIGNMENTS_UPDATE_SUMMARY_METRICS =
    'RETAIL_CONSIGNMENTS_UPDATE_SUMMARY_METRICS';
export const RETAIL_CONSIGNMENTS_ERROR = 'RETAIL_CONSIGNMENTS_ERROR';

export const RETAIL_TRIPS_UPDATE_BUCKET_DATA = 'RETAIL_TRIPS_UPDATE_BUCKET_DATA';
export const RETAIL_TRIPS_SET_ACTIVE_BUCKET = 'RETAIL_TRIPS_SET_ACTIVE_BUCKET';
export const RETAIL_TRIPS_ENABLE_BUCKET_LOADING = 'RETAIL_TRIPS_ENABLE_LOADING';
export const RETAIL_TRIPS_DISABLE_BUCKET_LOADING = 'RETAIL_TRIPS_DISABLE_LOADING';
export const RETAIL_TRIPS_UPDATE_GLOBAL_FILTER = 'RETAIL_TRIPS_UPDATE_GLOBAL_FILTER';
export const RETAIL_TRIPS_UPDATE_BUCKET_FILTER = 'RETAIL_TRIPS_UPDATE_BUCKET_FILTER';
export const RETAIL_TRIPS_RESET_BUCKET_FILTER = 'RETAIL_TRIPS_RESET_BUCKET_FILTER';
export const RETAIL_TRIPS_UPDATE_BUCKET_PAGINATION = 'RETAIL_TRIPS_UPDATE_BUCKET_PAGINATION';
export const RETAIL_TRIPS_RESET_BUCKET_PAGINATION = 'RETAIL_TRIPS_RESET_BUCKET_PAGINATION';
export const RETAIL_TRIPS_RESET_ALL_EXCEPT_FILTERS = 'RETAIL_TRIPS_RESET_ALL_EXCEPT_FILTERS';
export const RETAIL_TRIPS_RESET_SHOULD_UPDATE = 'RETAIL_TRIPS_RESET_SHOULD_UPDATE';
export const RETAIL_TRIPS_UPDATE_SUMMARY_METRICS = 'RETAIL_TRIPS_UPDATE_SUMMARY_METRICS';
export const RETAIL_TRIPS_ERROR = 'RETAIL_TRIPS_ERROR';
export const RETAIL_TRIPS_BUCKET_RELOAD = 'RETAIL_TRIPS_BUCKET_RELOAD';
export const RETAIL_TRIPS_SET_BUCKET_SELECTED_ROW_KEYS =
    'RETAIL_TRIPS_SET_BUCKET_SELECTED_ROW_KEYS';

export const TOTES_UPDATE_DATA = 'TOTES_UPDATE_DATA';
export const TOTES_ENABLE_LOADING = 'TOTES_ENABLE_LOADING';
export const TOTES_DISABLE_LOADING = 'TOTES_DISABLE_LOADING';
export const TOTES_UPDATE_FILTER = 'TOTES_UPDATE_FILTER';
export const TOTES_RESET_FILTER = 'TOTES_RESET_FILTER';
export const TOTES_UPDATE_PAGINATION = 'TOTES_UPDATE_PAGINATION';
export const TOTES_RESET_PAGINATION = 'TOTES_RESET_PAGINATION';
export const TOTES_UPDATE_SUMMARY_METRICS = 'TOTES_UPDATE_SUMMARY_METRICS';
export const TOTES_FETCH_ERROR = 'TOTES_FETCH_ERROR';
export const GENERIC_DASHBOARD_UPDATE_BUCKET_DATA = 'GENERIC_DASHBOARD_UPDATE_BUCKET_DATA';
export const GENERIC_DASHBOARD_ENABLE_BUCKET_LOADING = 'GENERIC_DASHBOARD_ENABLE_BUCKET_LOADING';
export const GENERIC_DASHBOARD_DISABLE_BUCKET_LOADING = 'GENERIC_DASHBOARD_DISABLE_BUCKET_LOADING';
export const GENERIC_DASHBOARD_UPDATE_GLOBAL_FILTER = 'GENERIC_DASHBOARD_UPDATE_GLOBAL_FILTER';
export const GENERIC_DASHBOARD_UPDATE_BUCKET_PAGINATION =
    'GENERIC_DASHBOARD_UPDATE_BUCKET_PAGINATION';
export const GENERIC_DASHBOARD_RESET_BUCKET_PAGINATION =
    'GENERIC_DASHBOARD_RESET_BUCKET_PAGINATION';
export const GENERIC_DASHBOARD_SET_BUCKET_FILTER = 'GENERIC_DASHBOARD_SET_BUCKET_FILTER';
export const GENERIC_DASHBOARD_RESET_BUCKET_FILTER = 'GENERIC_DASHBOARD_RESET_BUCKET_FILTER';
export const GENERIC_DASHBOARD_APPLY_BUCKET_FILTER = 'GENERIC_DASHBOARD_APPLY_BUCKET_FILTER';
export const GENERIC_DASHBOARD_APPLY_OTHER_BUCKET_FILTER =
    'GENERIC_DASHBOARD_APPLY_OTHER_BUCKET_FILTER';
export const GENERIC_DASHBOARD_CANCEL_BUCKET_FILTER = 'GENERIC_DASHBOARD_CANCEL_BUCKET_FILTER';
export const GENERIC_DASHBOARD_SET_ACTIVE_BUCKET = 'GENERIC_DASHBOARD_SET_ACTIVE_BUCKET';
export const GENERIC_DASHBOARD_RESET_SHOULD_UPDATE = 'GENERIC_DASHBOARD_RESET_SHOULD_UPDATE';
export const GENERIC_DASHBOARD_SET_COLUMNS = 'GENERIC_DASHBOARD_SET_COLUMNS';
export const GENERIC_DASHBOARD_PIECE_DETAILS_SET_COLUMNS = 'GENERIC_DASHBOARD_PIECE_DETAILS_SET_COLUMNS';
export const GENERIC_DASHBOARD_SET_TRIP_COLUMNS = 'GENERIC_DASHBOARD_SET_TRIP_COLUMNS';
export const GENERIC_DASHBOARD_UPDATE_FILTER_IN_ALL_BUCKETS =
    'GENERIC_DASHBOARD_UPDATE_FILTER_IN_ALL_BUCKETS';
export const GENERIC_DASHBOARD_UPDATE_SUMMARY_METRICS_DATA =
    'GENERIC_DASHBOARD_UPDATE_SUMMARY_METRICS_DATA';
export const GENERIC_DASHBOARD_UPDATE_SUMMARY_METRICS_DATA_BUCKET_SPECIFIC =
    'GENERIC_DASHBOARD_UPDATE_SUMMARY_METRICS_DATA_BUCKET_SPECIFIC';
export const GENERIC_DASHBOARD_RESET_ALL_BUCKETS_EXCEPT_FILTERS =
    'GENERIC_DASHBOARD_RESET_ALL_BUCKETS_EXCEPT_FILTERS';
export const GENERIC_DASHBOARD_UPDATE_SUB_BUCKET_DATA =
    'GENERIC_DASHBOARD_UPDATE_SUB_BUCKET_DATA';
export const GENERIC_DASHBOARD_BUCKET_RELOAD = 'GENERIC_DASHBOARD_BUCKET_RELOAD';
export const GENERIC_DASHBOARD_UPDATE_BUCKET_TABLE_DATA =
    'GENERIC_DASHBOARD_UPDATE_BUCKET_TABLE_DATA';
export const GENERIC_DASHBOARD_SET_BUCKET_SELECTED_ROW_KEYS =
    'GENERIC_DASHBOARD_SET_BUCKET_SELECTED_ROW_KEYS';
export const GENERIC_DASHBOARD_APPLY_BUCKET_SORT = 'GENERIC_DASHBOARD_APPLY_BUCKET_SORT';
export const GENERIC_DASHBOARD_APPLY_VIEW_SORT = 'GENERIC_DASHBOARD_APPLY_VIEW_SORT';
export const GENERIC_DASHBOARD_SET_TABLE_DATA_EMPTY = 'GENERIC_DASHBOARD_SET_TABLE_DATA_EMPTY';
export const GENERIC_DASHBOARD_SET_CUSTOM_FILTERS = 'GENERIC_DASHBOARD_SET_CUSTOM_FILTERS';
export const GENERIC_DASHBOARD_SET_CURRENT_CUSTOM_FILTERS =
    'GENERIC_DASHBOARD_SET_CURRENT_CUSTOM_FILTERS';
export const GENERIC_DASHBOARD_SET_CUSTOM_ACTIONS = 'GENERIC_DASHBOARD_SET_CUSTOM_ACTIONS';
export const GENERIC_DASHBOARD_SET_CUSTOM_UPDATES = 'GENERIC_DASHBOARD_SET_CUSTOM_UPDATES';
export const GENERIC_DASHBOARD_SET_TASK_COLUMNS = 'GENERIC_DASHBOARD_SET_TASK_COLUMNS';
// GENERIC ANALYTICS
export const GENERIC_ANALYTICS_UPDATE_GLOBAL_FILTER =
    'GENERIC_ANALYTICS_UPDATE_GLOBAL_FILTER';
export const GENERIC_ANALYTICS_UPDATE_VISIBLE_CHARTS = 'GENERIC_ANALYTICS_UPDATE_VISIBLE_CHARTS';
// FoodDashboard

export const DISPATCH_SCREEN_ENABLE_LOADING = 'DISPATCH_SCREEN_ENABLE_LOADING';
export const DISPATCH_SCREEN_DISABLE_LOADING = 'DISPATCH_SCREEN_DISABLE_LOADING';
export const DISPATCH_SCREEN_UPDATE_DATA = 'DISPATCH_SCREEN_UPDATE_DATA';
export const DISPATCH_SCREEN_UPDATE_FILTERED_ORDER_DATA =
    'DISPATCH_SCREEN_UPDATE_FILTERED_ORDER_DATA';
export const DISPATCH_SCREEN_APPLY_GLOBAL_FILTERS = 'DISPATCH_SCREEN_APPLY_GLOBAL_FILTERS';
export const DISPATCH_SCREEN_RESET_GLOBAL_FILTERS = 'DISPATCH_SCREEN_RESET_GLOBAL_FILTERS';
export const DISPATCH_SCREEN_SET_GLOBAL_FILTERS = 'DISPATCH_SCREEN_SET_GLOBAL_FILTERS';
export const DISPATCH_SCREEN_SET_ACTIVE_BUCKET = 'DISPATCH_SCREEN_SET_ACTIVE_BUCKET';
export const DISPATCH_SCREEN_SET_ACTIVE_BUCKET_FOR_RIDER = 'DISPATCH_SCREEN_SET_ACTIVE_BUCKET_FOR_RIDER';
export const GENERIC_ANALYTICS_APPLY_BUCKET_FILTER = 'GENERIC_ANALYTICS_APPLY_BUCKET_FILTER';
export const GENERIC_ANALYTICS_RESET_BUCKET_FILTER = 'GENERIC_ANALYTICS_RESET_BUCKET_FILTER';
export const GENERIC_ANALYTICS_BUCKET_ENABLE_LOADING = 'GENERIC_ANALYTICS_BUCKET_ENABLE_LOADING';
export const GENERIC_ANALYTICS_BUCKET_DISABLE_LOADING = 'GENERIC_ANALYTICS_BUCKET_DISABLE_LOADING';
export const GENERIC_ANALYTICS_UPDATE_CHART_DATA = 'GENERIC_ANALYTICS_UPDATE_CHART_DATA';
export const GENERIC_ANALYTICS_SET_ACTIVE_BUCKET = 'GENERIC_ANALYTICS_SET_ACTIVE_BUCKET';
export const GENERIC_ANALYTICS_RESET_ALL_BUCKETS_EXCEPT_FILTERS =
    'GENERIC_ANALYTICS_RESET_ALL_BUCKETS_EXCEPT_FILTERS';
export const GENERIC_ANALYTICS_BUCKET_RELOAD = 'GENERIC_ANALYTICS_BUCKET_RELOAD';
export const GENERIC_ANALYTICS_CHART_ENABLE_LOADING = 'GENERIC_ANALYTICS_CHART_ENABLE_LOADING';
export const GENERIC_ANALYTICS_CHART_DISABLE_LOADING = 'GENERIC_ANALYTICS_CHART_DISABLE_LOADING';
export const GENERIC_ANALYTICS_UPDATE_TABLE_DATA = 'GENERIC_ANALYTICS_UPDATE_TABLE_DATA';
export const GENERIC_ANALYTICS_UPDATE_TABLE_PAGINATION =
    'GENERIC_ANALYTICS_UPDATE_TABLE_PAGINATION';
export const GENERIC_ANALYTICS_RESET_TABLE_PAGINATION = 'GENERIC_DASHBOARD_RESET_TABLE_PAGINATION';
export const SET_CURRENT_HUB_ANALYTICS = 'SET_CURRENT_HUB_ANALYTICS';
export const DISPATCH_SCREEN_ANALYTICS_UPDATE_DATA = 'DISPATCH_SCREEN_ANALYTICS_UPDATE_DATA';
// generate reports in TMS overview
export const SET_CURRENT_HUB_REPORTS = 'SET_CURRENT_HUB_REPORTS';
export const APPLY_FILTERS_RETAIL_REPORTS = 'APPLY_FILTERS_RETAIL_REPORTS';
export const RETAIL_REPORTS_UPDATE_TABLE_DATA = 'RETAIL_REPORTS_UPDATE_TABLE_DATA';
export const RETAIL_REPORTS_ENABLE_LOADING = 'RETAIL_REPORTS_ENABLE_LOADING';
export const RETAIL_REPORTS_DISABLE_LOADING = 'RETAIL_REPORTS_DISABLE_LOADING';

// crm summary metrics
export const SUMMARY_METRICS_UPDATE_DATA = 'SUMMARY_METRICS_UPDATE_DATA';
export const SUMMARY_METRICS_UPDATE_FILTER = 'SUMMARY_METRICS_UPDATE_FILTER';
export const SUMMARY_METRICS_RESET_PAGINATION = 'SUMMARY_METRICS_RESET_PAGINATION';
export const SUMMARY_METRICS_UPDATE_AGGREGATED_DATA = 'SUMMARY_METRICS_UPDATE_AGGREGATED_DATA';

export const GENERIC_ANALYTICS_UPDATE_FEEDBACK_SUMMARY_METRICS_DATA = 'GENERIC_ANALYTICS_UPDATE_FEEDBACK_SUMMARY_METRICS_DATA';
export const GENERIC_ANALYTICS_TOGGLE_SELECTED_SUMMARY_METRICS = 'GENERIC_ANALYTICS_TOGGLE_SELECTED_SUMMARY_METRICS';
// Persistent Iframe
export const IFRAME_SET_VISIBILITY = 'IFRAME_SET_VISIBILITY';
export const IFRAME_SET_DIMENSIONS = 'IFRAME_SET_DIMENSIONS';
export const IFRAME_SET_LOADED = 'IFRAME_SET_LOADED';
export const IFRAME_SET_RENDERED = 'IFRAME_SET_RENDERED';

// Ops Dashboard
export const OPS_DASHBOARD_MANAGE_SET_MAP_VIEW = 'OPS_DASHBOARD_MANAGE_SET_MAP_VIEW';
export const OPS_DASHBOARD_MANAGE_SET_SHOW_VIEW_TYPE = 'OPS_DASHBOARD_MANAGE_SET_SHOW_VIEW_TYPE';

// DTDC TMS Dashboard
export const TMS_DASHBOARD_UPDATE_MASTERS = 'TMS_DASHBOARD_UPDATE_MASTERS';
export const TMS_DASHBOARD_UPDATE_TRANSPORTERS = 'TMS_DASHBOARD_UPDATE_TRANSPORTERS';
export const TMS_DASHBOARD_UPDATE_VEHICLE_MAKE = 'TMS_DASHBOARD_UPDATE_VEHICLE_MAKE';

// DTDC TMS Overview Dashboard
export const TMS_OVERVIEW_DASHBOARD_UPDATE_GLOBAL_FILTER = 'TMS_OVERVIEW_DASHBOARD_UPDATE_GLOBAL_FILTER';
export const TMS_OVERVIEW_DASHBOARD_UPDATE_CHART_DATA = 'TMS_OVERVIEW_DASHBOARD_UPDATE_CHART_DATA';
export const TMS_OVERVIEW_DASHBOARD_CHART_ENABLE_LOADING = 'TMS_OVERVIEW_DASHBOARD_CHART_ENABLE_LOADING';
export const TMS_OVERVIEW_DASHBOARD_CHART_DISABLE_LOADING = 'TMS_OVERVIEW_DASHBOARD_CHART_DISABLE_LOADING';

// Integration Marketplace
export const SET_INTEGRATION_MARKETPLACE_MASTERDATA = 'SET_INTEGRATION_MARKETPLACE_MASTERDATA';
export const UPDATE_INTEGRATION_METADATA = 'UPDATE_INTEGRATION_METADATA';
export const SET_INTEGRATION_MARKETPLACE_INTEGRATION_DATA = 'SET_INTEGRATION_MARKETPLACE_INTEGRATION_DATA';

// landing page dashboard group
export const INITIALISE_DASHBOARD_GROUP = 'INITIALISE_DASHBOARD_GROUP';
export const REMOVE_REPORT = 'REMOVE_REPORT';
export const SET_LAST_UPDATED_TIME = 'SET_LAST_UPDATED_TIME';
export const RESET_DASHBOARD_GROUP = 'RESET_DASHBOARD_GROUP';
export const SET_REFRESH_REPORTS_DATA = 'SET_REFRESH_REPORTS_DATA';

// Notification Actions
export const SET_NOTIFICATION_AUTH_TOKEN = 'SET_NOTIFICATION_AUTH_TOKEN';

// chart visulaization
export const filterTimeFormats = {
  hour: 'Do MMM YYYY H:mm',
  day: 'D MMM YYYY',
  week: 'D MMM YYYY',
  month: 'MMM YYYY',
  quarter: 'MMM YYYY',
  year: 'YYYY',
};

// Helpdesk Notification
export const SIGNED_URL_IMAGE_UPLOAD = 'SIGNED_URL_IMAGE_UPLOAD';

export const WORKFLOW_METADATA_SET_LOADING = 'WORKFLOW_METADATA_SET_LOADING';
export const WORKFLOW_METADATA_SET_DATA = 'WORKFLOW_METADATA_SET_DATA';
export const WORKFLOW_METADATA_SET_ERROR = 'WORKFLOW_METADATA_SET_ERROR';
