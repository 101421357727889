export function getSearchAPIObject(searchType, searchValue) {

  const searchTypeFieldArr = [
    {
      type: 'referenceNumber',
      searchField: 'referenceNumberList',
    },
    {
      type: 'awbNumber',
      searchField: 'courierPartnerReferenceNumberList',
    },
    {
      type: 'orderNumber',
      searchField: 'orderNumberList',
    },
    {
      type: 'senderPhone',
      searchField: 'senderPhoneNumberList',
    },
    {
      type: 'destinationPhone',
      searchField: 'destinationPhoneSearchList',
    },
  ];
  const searchVal = (searchValue && searchValue.length > 0) ? [searchValue] : [];
  const searchFields = {};
  searchTypeFieldArr.forEach((searchTypeObj) => {
    if (searchTypeObj.type === searchType) {
      searchFields[searchTypeObj.searchField] = searchVal;
    } else {
      searchFields[searchTypeObj.searchField] = [];
    }
  });
  return searchFields;
}
export function isValid(val) {
  return val != null ? true : false;
}
export function getTypeValue(value) {
  const type: any = typeof value;
  if (value instanceof Array) {
    return value.join(', ');
  }
  if (type === 'boolean') {
    return value ? 'Yes' : 'No';
  }
  return value;
}
export const bucketsPrettyNameMapping = {
  retail_store_all: 'All',
  retail_store_outbound: 'Outbound',
  retail_store_inbound: 'Inbound',
  retail_store_completed: 'Success',
  retail_store_failed: 'Failed',
  retail_all: 'All',
  retail_unplanned: 'Unplanned',
  retail_planned: 'Planned',
  retail_in_progress: 'In Transit',
  retail_completed: 'Success',
  retail_failed: 'Failed',
  retail_inbound: 'Inbound',
  retail_dc_all: 'All',
  retail_dc_unplanned: 'Unplanned',
  retail_dc_planned: 'Planned',
  retail_dc_in_progress: 'In Transit',
  retail_dc_completed: 'Success',
  retail_dc_failed: 'Failed',
  ondemand_all_orders: 'All',
  rider_order_details: 'Rider Order Details',
  rider_exceptions: 'Rider Exceptions',
  rider_score: 'Rider Score',
  rider_score_details: 'Rider Score Details',
  rider_dph: 'Rider Dph',
  order_delivered_reachedgate_metrics: 'Order Delivered Reached Gate Metrics',
  region_summary: 'Region Summary',
  circle_summary: 'Circle Summary',
  city_summary: 'City Summary',
  store_summary: 'Store Summary',
  store_dph: 'Store Dph',
  allocation_details: 'Allocation Details',
  reassign_details: 'Reassign Details',
  unresolved_orders: 'Unresolved Orders',
  store_delivered_reachedgate_metrics: 'Store Delivered Reached Gate Metrics',
  crm_default: 'CRM All',
  ltl_track: 'All Shipments',
  ndr: 'NDR/ Undelivered',
  ltl_delivered: 'Delivered',
  rto: 'RTO',
  tms_ist_created: 'Created',
  tms_ist_arrived: 'Arrived',
  tms_ist_unloading: 'Unloading',
  tms_ist_loading: 'Loading',
  tms_ist_loaded: 'Loaded',
  tms_ist_departed: 'Departed',
  tms_ist_completed: 'Completed',
  tms_vehicle_expected: 'Expected',
  tms_vehicle_arrived: 'Arrived',
  tms_vehicle_unloading: 'Unloading',
  tms_vehicle_loading: 'Loading',
  tms_vehicle_outbound: 'Outbound',
  locality_summary: 'Locality Summary',
  logistics_express_all: 'All',
  logistics_express_planned: 'Planned',
  logistics_express_unplanned: 'Unplanned',
  logistics_express_in_progress: 'In Transit',
  logistics_express_completed: 'Success',
  logistics_express_failed: 'Failed',
  logistics_express_inscan_at_hub: 'Inscan at Hub',
  logistics_express_bagged: 'Bagged',
  logistics_express_loaded: 'Loaded',
  logistics_express_inbound: 'Inbound',
  logistics_express_rto: 'RTO',
  third_party_self: 'Self',
  third_party_assigned: 'Third Party Assigned',
  third_party_all: 'Third Party All',
  rider_score_summary: 'Rider Score Analysis',
  rider_gps_km: 'Rider GPS Km',
  rider_distance_metrics: 'Distance Aggregate Table - Rider Level',
  rider_trip_order_count: 'Trip Order Count - Rider Level',
  store_gps_km: 'Store GPS Km',
  store_trip_order_count: 'Trip Order Count - Store Level',
  order_summary: 'Order Wise Service Time',
  store_distance_metrics: 'Distance Aggregate Table',
  vehicle_distance_metrics: 'Bike Wise Aggregated Distance',
  store_distance_exceptions: 'Store Exception Metrics',
  rider_distance_exceptions: 'Rider Exception Metrics',
  store_incentive_table: 'Incentive Table',
  store_partially_completed: 'Partially Completed Orders',
  rider_missing_checkout: 'Missing Checkout',
  rider_checkin_checkout: 'Rider Check In Check Out',
  rider_km_reading: 'Rider Km Reading',
};
export const settlementTypeList = [
  { id: 'all', name: 'All' },
  { id: 'yes', name: 'Yes' },
  { id: 'no', name: 'No' },
];
export const cnvStatusList = [
  { id: 'verified', name: 'Verified' },
  { id: 'not_verified', name: 'Not Verified' },
];
export const paymentStatusList = [
  { id: 'yes', name: 'Yes' },
  { id: 'no', name: 'No' },
];
export const viewTypesToFetchDownloads = ['retail', 'tms', 'logistics_express'];
export const deliveryTypeList = [
  { id: 'ECOM', name: 'ECOM' },
  { id: 'STORE', name: 'STORE' },
];
export const columnsToShowInCustomerReferenceList = (params) => {
  const { columns = [] } = params || {};
  if (Array.isArray(columns) && columns.length !== 0) {
    return columns;
  }
  const defaultColumns = [
    {
      column_id: 'reference_number',
      pretty_name: 'Consignment Number',
    },
    {
      column_id: 'customer_reference_number',
      pretty_name: 'Customer Reference Number',
    },
    {
      column_id: 'customer_code',
      pretty_name: 'Customer Code',
    },
    {
      column_id: 'hub_code',
      pretty_name: 'Hub Code',
    },
    {
      column_id: 'status',
      pretty_name: 'Status',
    },
  ];
  return defaultColumns;
};
