import apiProvider from '../provider';
import {
  getAuthenticationHeaders,
  getErrorMessage,
} from '../utils';
import { API_BASE_URL } from '../../globals';
import {
  FETCH_TMS_SUMMARY_METRICS_DATA_IST, GET_ALL_TMS_DATA_IST,
  FETCH_TMS_IST_DETAILS, DOWNLOAD_TMS_IST_DATA, SCAN_ITEMS_TMS,
  CREATE_IST_TMS, GET_LOADING_STATUS_TMS_IST, START_IST_LOADING_TMS, GET_HUBS_IN_IST_ROUTE,
  SAVE_DRAFT_CREATE_IST, FREEZE_IST, START_IST_UNLOADING_TMS, GET_UNLOADING_STATUS_TMS_IST,
  SAVE_DRAFT_IST_UNLOADING, TMS_IST_FINISH_UNLOADING, TMS_SCAN_ITEM_OFFLOAD, TMS_OFFLOAD,
  GET_ALL_AVAILABLE_CONSIGNMENTS_FOR_LOADING, GET_MISSING_CONSIGNMENTS_WHILE_LOADING,
  DELETE_MULTIPLE_TRIPS,
  REMOVE_FROM_LOADING_DRAFT, UPDATE_LOADING_DRAFT,
  VALIDATE_CONSIGNMENTS_MM, REMOVE_FROM_UNLOADING_DRAFT, UPDATE_UNLOADING_DRAFT,
  CREATE_PTL_TRIP, DOWNLOAD_IST_CONSIGNMENTS_DATA, MM_TRIP_CLOSURE_DOWNLOAD, UPDATE_KM_READING,
  MM_TRIP_CN_WITH_EWB_STATUS, MM_TRIP_CN_WITH_MISSING_EWB,
  GENERATE_EWB_MM_TRIP_CN, DOWNLOAD_IST_VERIFICATION_DETAILS,GET_ALL_HUBS_LOCATION,GET_CURRENT_VEHICLE_LOCATION,IST_RESEND_RIDER_CONSENT, 
} from '../routes';

import {
  requestModelTMS, responseModelTMS, requestModelSummaryMetricsTMS,
} from '../../model/tmsView';
import { morphism } from 'morphism';
import { POST, GET } from '../apiHandler';

export const getAllTmsDataIST = async (body) => {
  try {
    const response = await apiProvider.post(
      `${API_BASE_URL}${GET_ALL_TMS_DATA_IST}`, { ...morphism(requestModelTMS, body) },
      {
        headers: await getAuthenticationHeaders(),
      },
    );

    return {
      data: morphism(responseModelTMS, response.data.data),
      isSuccess: true,
    };
  } catch (err) {
    const errorMessage = getErrorMessage(err);
    return {
      errorMessage,
      isSuccess: false,
    };
  }
};

export const getTmsSummaryMetricsDataIST = async (body) => {
  try {
    const response = await apiProvider.post(
      `${API_BASE_URL}${FETCH_TMS_SUMMARY_METRICS_DATA_IST}`,
      morphism(requestModelSummaryMetricsTMS, body),
      { headers: await getAuthenticationHeaders() });
    return {
      isSuccess: true,
      data: response.data.data,
    };
  } catch (error) {
    const errorMessage = getErrorMessage(error);
    return {
      errorMessage,
      isSuccess: false,
    };
  }
};

export const fetchISTDetails = async (paramBody) => {
  try {
    const response = await apiProvider.get(
      `${API_BASE_URL}${FETCH_TMS_IST_DETAILS}`,
      {
        params: {
          ...paramBody,
        },
        headers: await getAuthenticationHeaders(),
      },
    );
    return {
      data: response.data.data,
      isSuccess: true,
    };
  } catch (err) {
    const errorMessage = getErrorMessage(err);
    return {
      errorMessage,
      isSuccess: false,
    };
  }
};

export const downloadTMSIstData = async(body) => {
  try {
    const response = await apiProvider.post(
      `${API_BASE_URL}${DOWNLOAD_TMS_IST_DATA}`,
      body,
      {
        headers: await getAuthenticationHeaders(),
      },
    );
    return {
      isSuccess: true,
    };
  } catch (err) {
    const errorMessage = getErrorMessage(err);
    return {
      errorMessage,
      isSuccess: false,
    };
  }
};

export const scanItemTMS = async(body) => {
  try {
    const response = await apiProvider.post(
      `${API_BASE_URL}${SCAN_ITEMS_TMS}`,
      body,
      {
        headers: await getAuthenticationHeaders(),
      },
    );
    return {
      data: response.data,
      isSuccess: true,
    };
  } catch (err) {
    const errorMessage = getErrorMessage(err);
    return {
      errorMessage,
      isSuccess: false,
    };
  }
};

export const createISTTms = async(body) => {
  try {
    const response = await apiProvider.post(
      `${API_BASE_URL}${CREATE_IST_TMS}`,
      body,
      {
        headers: await getAuthenticationHeaders(),
      },
    );
    return {
      data: response.data.data,
      isSuccess: true,
    };
  } catch (err) {
    const errorMessage = getErrorMessage(err);
    return {
      errorMessage,
      isSuccess: false,
    };
  }
};

export const getISTLoadingStatus = async (istId) => {
  try {
    const response = await apiProvider.get(
      `${API_BASE_URL}${GET_LOADING_STATUS_TMS_IST}`,
      {
        params: {
          ist_id: istId,
        },
        headers: await getAuthenticationHeaders(),
      },
    );
    return {
      data: response.data.data,
      isSuccess: true,
    };
  } catch (err) {
    const errorMessage = getErrorMessage(err);
    return {
      errorMessage,
      isSuccess: false,
    };
  }
};

export const startISTLoading = async(body) => {
  try {
    const response = await apiProvider.post(
      `${API_BASE_URL}${START_IST_LOADING_TMS}`,
      body,
      {
        headers: await getAuthenticationHeaders(),
      },
    );
    return {
      data: response.data,
      isSuccess: true,
    };
  } catch (err) {
    const errorMessage = getErrorMessage(err);
    return {
      errorMessage,
      isSuccess: false,
    };
  }
};
export const getHubsInISTRoute = async (hubId, routeId) => {
  try {
    const response = await apiProvider.get(
      `${API_BASE_URL}${GET_HUBS_IN_IST_ROUTE}`,
      {
        params: {
          hub_id: hubId,
          route_id: routeId,
        },
        headers: await getAuthenticationHeaders(),
      },
    );
    return {
      data: response.data.data,
      isSuccess: true,
    };
  } catch (err) {
    const errorMessage = getErrorMessage(err);
    return {
      errorMessage,
      isSuccess: false,
    };
  }
};

export const saveIstDraft = async(body) => {
  try {
    const response = await apiProvider.post(
      `${API_BASE_URL}${SAVE_DRAFT_CREATE_IST}`,
      body,
      {
        headers: await getAuthenticationHeaders(),
      },
    );
    return {
      data: response.data,
      isSuccess: true,
    };
  } catch (err) {
    const errorMessage = getErrorMessage(err);
    return {
      errorMessage,
      isSuccess: false,
    };
  }
};
export const getISTUnloadingStatus = async (istId) => {
  try {
    const response = await apiProvider.get(
      `${API_BASE_URL}${GET_UNLOADING_STATUS_TMS_IST}`,
      {
        params: {
          ist_id: istId,
        },
        headers: await getAuthenticationHeaders(),
      },
    );
    return {
      data: response.data.data,
      isSuccess: true,
    };
  } catch (err) {
    const errorMessage = getErrorMessage(err);
    return {
      errorMessage,
      isSuccess: false,
    };
  }
};
export const startISTUnloading = async(body) => {
  try {
    const response = await apiProvider.post(
      `${API_BASE_URL}${START_IST_UNLOADING_TMS}`,
      body,
      {
        headers: await getAuthenticationHeaders(),
      },
    );
    return {
      data: response.data,
      isSuccess: true,
    };
  } catch (err) {
    const errorMessage = getErrorMessage(err);
    return {
      errorMessage,
      isSuccess: false,
    };
  }
};
export const saveISTUnloadingDraft = async(body) => {
  try {
    const response = await apiProvider.post(
      `${API_BASE_URL}${SAVE_DRAFT_IST_UNLOADING}`,
      body,
      {
        headers: await getAuthenticationHeaders(),
      },
    );
    return {
      data: response.data,
      isSuccess: true,
    };
  } catch (err) {
    const errorMessage = getErrorMessage(err);
    return {
      errorMessage,
      isSuccess: false,
    };
  }
};
export const freezeISTAction = async(body) => {
  try {
    const response = await apiProvider.post(
      `${API_BASE_URL}${FREEZE_IST}`,
      body,
      {
        headers: await getAuthenticationHeaders(),
      },
    );
    return {
      data: response.data,
      isSuccess: true,
    };
  } catch (err) {
    const errorMessage = getErrorMessage(err);
    return {
      errorMessage,
      isSuccess: false,
    };
  }
};
export const finishIstUnloading = async(body) => {
  try {
    const response = await apiProvider.post(
      `${API_BASE_URL}${TMS_IST_FINISH_UNLOADING}`,
      body,
      {
        headers: await getAuthenticationHeaders(),
      },
    );
    return {
      data: response.data,
      isSuccess: true,
    };
  } catch (err) {
    const errorMessage = getErrorMessage(err);
    return {
      errorMessage,
      isSuccess: false,
    };
  }
};
export const scanItemOffloadTMS = async(body) => {
  try {
    const response = await apiProvider.post(
      `${API_BASE_URL}${TMS_SCAN_ITEM_OFFLOAD}`,
      body,
      {
        headers: await getAuthenticationHeaders(),
      },
    );
    return {
      data: response.data,
      isSuccess: true,
    };
  } catch (err) {
    const errorMessage = getErrorMessage(err);
    return {
      errorMessage,
      isSuccess: false,
    };
  }
};
export const onOffload = async(body) => {
  try {
    const response = await apiProvider.post(
      `${API_BASE_URL}${TMS_OFFLOAD}`,
      body,
      {
        headers: await getAuthenticationHeaders(),
      },
    );
    return {
      data: response.data.data,
      isSuccess: true,
    };
  } catch (err) {
    const errorMessage = getErrorMessage(err);
    return {
      errorMessage,
      isSuccess: false,
    };
  }
};
export const getAllAvailableConsignmentsForLoading =  async (body) => {
  return POST(`${API_BASE_URL}${GET_ALL_AVAILABLE_CONSIGNMENTS_FOR_LOADING}`, body);
};
export const getMissingConsignmentsISTLoading = async (body) => {
  return POST(`${API_BASE_URL}${GET_MISSING_CONSIGNMENTS_WHILE_LOADING}`, body);
};
export const deleteMultipleTrips = async (body) => {
  return POST(`${API_BASE_URL}${DELETE_MULTIPLE_TRIPS}`, body);
};

export const validateConsignmentsMM = async (body) => {
  return POST(`${API_BASE_URL}${VALIDATE_CONSIGNMENTS_MM}`, body);
};
export const removeFromLoadingDraft = async (body) => {
  return POST(`${API_BASE_URL}${REMOVE_FROM_LOADING_DRAFT}`, body);
};
export const updateLoadingDraft = async (body) => {
  return POST(`${API_BASE_URL}${UPDATE_LOADING_DRAFT}`, body);
};
export const removeFromUnloadingDraft = async (body) => {
  return POST(`${API_BASE_URL}${REMOVE_FROM_UNLOADING_DRAFT}`, body);
};
export const updateUnloadingDraft = async (body) => {
  return POST(`${API_BASE_URL}${UPDATE_UNLOADING_DRAFT}`, body);
};
export const createPTLTripTms = async (body) => {
  return POST(`${API_BASE_URL}${CREATE_PTL_TRIP}`, body);
};
export const downloadISTConsignmentsData = async (body) => {
  return POST(`${API_BASE_URL}${DOWNLOAD_IST_CONSIGNMENTS_DATA}`, body, undefined, true);
};
export const updateKmReading = async (body) => {
  return POST(`${API_BASE_URL}${UPDATE_KM_READING}`, body);
};
export const downloadMMTripClosureSheet = async ( ist_id ) => {
  return GET( `${API_BASE_URL}${MM_TRIP_CLOSURE_DOWNLOAD}`, { ist_id }, 'blob');
};
export const getConsignmentEWBList = async (params) => {
  return GET(`${API_BASE_URL}${MM_TRIP_CN_WITH_EWB_STATUS}`, params);
};
export const getConsignmentsWithMissingEWB = async (params) => {
  return GET(`${API_BASE_URL}${MM_TRIP_CN_WITH_MISSING_EWB}`, params);
};
export const generateEWBForMmTrip = async (params) => {
  return POST(`${API_BASE_URL}${GENERATE_EWB_MM_TRIP_CN}`, params);
};

export const downloadIstVerificationDetails = async (body) => {
  const headers = await getAuthenticationHeaders();
  return POST(`${API_BASE_URL}${DOWNLOAD_IST_VERIFICATION_DETAILS}`, body, headers, true);
};

export const fetchAllHubLocation = async (params) => {
  return GET(`${API_BASE_URL}${GET_ALL_HUBS_LOCATION}`, params);
};

export const fetchISTCurrentLocationVehicle = async (params) => {
  return GET(`${API_BASE_URL}${GET_CURRENT_VEHICLE_LOCATION}`, params);
};

export const resendRiderConsentIST = async (body) => {
  return POST(`${API_BASE_URL}${IST_RESEND_RIDER_CONSENT}`, body);
}