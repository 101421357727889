import {
  WORKFLOW_METADATA_SET_LOADING,
  WORKFLOW_METADATA_SET_DATA,
  WORKFLOW_METADATA_SET_ERROR,
} from './constants';
import { getWorkFlowBuilderMetaData } from 'src/api/workFlowBuilder';

export const fetchObjectFieldListMetadata = (params) => async (dispatch) => {
  dispatch({
    type: WORKFLOW_METADATA_SET_LOADING,
    data: true,
  });

  const response = await getWorkFlowBuilderMetaData(params);
  if (response.isSuccess) {
    dispatch({
      type: WORKFLOW_METADATA_SET_DATA,
      data: {
        [params.objectType]: response?.data?.field_list || [],
      },
    });
  } else {
    dispatch({
      type: WORKFLOW_METADATA_SET_ERROR,
      data: response.errorMessage,
    });
  }

  dispatch({
    type: WORKFLOW_METADATA_SET_LOADING,
    data: false,
  });
};
